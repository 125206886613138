import React from 'react';
import { Hero } from '../../UI/Hero';
import { About } from '../sections/About';
import { Page } from '../../UI/Page';
import { Button, CircularProgress, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Events } from '../sections/Events';
import { Apod } from '../sections/Apod';

export const Home = ({ about, alert, baseUrl, clubMeeting, home }) => {
  return home ? (
    <Page
      id="home"
      background={`linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)),
      url('./bg.jpg')`}
      backgroundAttachment="fixed"
      backgroundSize="cover"
      backgroundPosition="center right fixed"
      backgroundRepeat="no-repeat"
    >
      <Hero heading={home.heading} text={home.description} alert={alert}>
        <Link to="join" margin="0 auto">
          <Button
            size="lg"
            fontWeight="bold"
            fontSize="sm"
            colorScheme={'blue'}
            textTransform="uppercase"
            isDisabled
          >
            Join the Club Online (Currently Unavailable)
          </Button>
        </Link>
  <p>The membership year begins on April 1, 2023</p>
      </Hero>
      <About about={about} />
      <Events clubMeeting={clubMeeting} />
      <Apod />
    </Page>
  ) : (
    <Flex w="100%" h="100vh" backgroundColor="black">
      <CircularProgress
        isIndeterminate
        color="blue.700"
        size="5rem"
        margin="5rem auto"
      />
    </Flex>
  );
};
