import React, { useState } from 'react';
import { getMonth } from '../../utils/getDate';
import { ObservingPage } from './page/ObservingPage';

export const Observing = () => {
  const [month, setMonth] = useState(getMonth());

  const handleMonth = e => {
    setMonth({
      value: parseInt(e.target.value),
      month: e.target.childNodes[e.target.value].id,
    });
  };

  return (
    <ObservingPage
      month={month}
      setMonth={setMonth}
      handleMonth={handleMonth}
    />
  );
};
