import React from 'react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Link, Text, VStack } from '@chakra-ui/react';

export const monthOptions = [
  { month: 'January', value: 1 },
  { month: 'February', value: 2 },
  { month: 'March', value: 3 },
  { month: 'April', value: 4 },
  { month: 'May', value: 5 },
  { month: 'June', value: 6 },
  { month: 'July', value: 7 },
  { month: 'August', value: 8 },
  { month: 'September', value: 9 },
  { month: 'October', value: 10 },
  { month: 'November', value: 11 },
  { month: 'December', value: 12 },
];

export const tables = [
  {
    id: 'messier',
    title: 'Messier Observing Program',
    description: (
      <VStack spacing={4}>
        <Text>
          For those who are just starting out, observing the Messier objects is
          a great place to start. There are actually two separate Messier
          Programs.
        </Text>

        <Text>
          The Messier Observing Program has two levels. By observing 70 Messier
          objects you can earn a certificate. By observing all 110 objects you
          will earn the “Honorary” level and an observing pin. There is actually
          a great reference document on the Oklahoma City Astronomy Club website
          under the Novice Program area at:{' '}
          <Link
            href="http://www.okcastroclub.com/Docs/DeepSkyMPDF/DeepSkyIntro.pdf"
            fontWeight="bold"
            isExternal
          >
            www.okcastroclub.com/Docs/DeepSkyMPDF/DeepSkyIntro.pdf
            <ExternalLinkIcon mx="2px" />
          </Link>
        </Text>
        <Text>
          The Binocular Messier Observing Program is for observing 50 or more
          Messier objects with just binoculars. Most objects are visible at a
          dark site with 10x50 binoculars. A general level of difficulty is
          provided in the Binocular column within the list to indicate Easy,
          Tougher and Challenge objects.
        </Text>
      </VStack>
    ),

    targets: [
      {
        id: 'M1',
        name: [
          'Crab Nebula',
          'Taurus A',
          'NGC1952',
          'CM Tauri',
          'Crab Nebula',
          'Crab Pulsar',
          'Taurus A',
        ],
        objecttype: 'SNR',
        ra: '05h34m32s',
        dec: '+22d00m52s',
        magnitude: 8.4,
        size: '6x4',
        user_Binocular: 'T',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/M1.htm',
      },

      {
        id: 'M2',
        name: ['NGC7089'],
        objecttype: 'Globular',
        ra: '21h33m27s',
        dec: '-00d49m23s',
        magnitude: 7.5,
        size: 12.9,
        user_Binocular: 'E',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/M2.htm',
      },

      {
        id: 'M3',
        name: ['NGC5272'],
        objecttype: 'Globular',
        ra: '13h42m11s',
        dec: '+28d22m35s',
        magnitude: 7,
        size: 16.2,
        user_Binocular: 'E',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M3.htm',
      },

      {
        id: 'M4',
        name: ["Cat's Eye", 'NGC6121'],
        objecttype: 'Globular',
        ra: '16h23m35s',
        dec: '-26d31m35s',
        magnitude: 7.5,
        size: 26.3,
        user_Binocular: 'E',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/M4.htm',
      },

      {
        id: 'M5',
        name: ['NGC5904'],
        objecttype: 'Globular',
        ra: '15h18m33s',
        dec: '+02d04m57s',
        magnitude: 7,
        size: 17.4,
        user_Binocular: 'E',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/M5.htm',
      },

      {
        id: 'M6',
        name: ['Butterfly Cluster', 'NGC6405'],
        objecttype: 'Open Cluster',
        ra: '17h40m20s',
        dec: '-32d15m12s',
        magnitude: 4.5,
        size: 15,
        user_Binocular: 'E',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/M6.htm',
      },

      {
        id: 'M7',
        name: ["Scorpion's Tail", "Ptolemy's Cluster", 'NGC6475'],
        objecttype: 'Open Cluster',
        ra: '17h53m51s',
        dec: '-34d47m36s',
        magnitude: 3.5,
        size: 80,
        user_Binocular: 'E',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/M7.htm',
      },

      {
        id: 'M8',
        name: ['Lagoon Nebula', 'Dragon Nebula', 'NGC6523'],
        objecttype: 'Open Cluster+D Neb',
        ra: '18h03m41s',
        dec: '-24d22m48s',
        magnitude: 5,
        size: 90,
        user_Binocular: 'E',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M8.htm',
      },

      {
        id: 'M9',
        name: ['NGC6333'],
        objecttype: 'Globular',
        ra: '17h19m12s',
        dec: '-18d30m58s',
        magnitude: 9,
        size: 9.3,
        user_Binocular: 'T',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/M9.htm',
      },

      {
        id: 'M10',
        name: ['NGC6254'],
        objecttype: 'Globular',
        ra: '16h57m09s',
        dec: '-04d05m56s',
        magnitude: 7.5,
        size: 15.1,
        user_Binocular: 'E',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/M10.htm',
      },

      {
        id: 'M11',
        name: [
          'Wild Duck Cluster',
          'Scutum Salt-and-Pepper Cluster',
          'NGC6705',
          'July Salt-and-Pepper',
        ],
        objecttype: 'Open Cluster',
        ra: '18h51m05s',
        dec: '-06d16m12s',
        magnitude: 7,
        size: 14,
        user_Binocular: 'E',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M11.htm',
      },

      {
        id: 'M12',
        name: ['Gumball Globular', 'NGC6218'],
        objecttype: 'Globular',
        ra: '16h47m14s',
        dec: '-01d56m52s',
        magnitude: 8,
        size: 14.5,
        user_Binocular: 'E',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/M12.htm',
      },

      {
        id: 'M13',
        name: [
          'Hercules Globular Cluster',
          'Great Hercules Cluster',
          'NGC6205',
        ],
        objecttype: 'Globular',
        ra: '16h41m41s',
        dec: '+36d27m35s',
        magnitude: 7,
        size: 16.6,
        user_Binocular: 'E',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/M13.htm',
      },

      {
        id: 'M14',
        name: ['NGC6402'],
        objecttype: 'Globular',
        ra: '17h37m36s',
        dec: '-03d14m43s',
        magnitude: 9.5,
        size: 11.7,
        user_Binocular: 'E',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/M14.htm',
      },

      {
        id: 'M15',
        name: ['Great Pegasus Cluster', 'NGC7078'],
        objecttype: 'Globular',
        ra: '21h29m58s',
        dec: '+12d10m02s',
        magnitude: 7.5,
        size: 12.3,
        user_Binocular: 'E',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/M15.htm',
      },

      {
        id: 'M16',
        name: ['Eagle Nebula', 'Star Queen Nebula', 'The Ghost', 'NGC6611'],
        objecttype: 'Open Cluster+D Neb',
        ra: '18h18m48s',
        dec: '-13d48m24s',
        magnitude: 6.5,
        size: 35,
        user_Binocular: 'E',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M16.htm',
      },

      {
        id: 'M17',
        name: [
          'Omega Nebula',
          'Swan Nebula',
          'Horseshoe Nebula',
          'NGC6618',
          'Checkmark Nebula',
        ],
        objecttype: 'Open Cluster+D Neb',
        ra: '18h20m47s',
        dec: '-16d10m18s',
        magnitude: 7,
        size: 46,
        user_Binocular: 'E',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M17.htm',
      },

      {
        id: 'M18',
        name: ['Black Swan, NGC6613'],
        objecttype: 'Open Cluster',
        ra: '18h19m58s',
        dec: '-17d06m07s',
        magnitude: 8,
        size: 9,
        user_Binocular: 'E',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M18.htm',
      },

      {
        id: 'M19',
        name: ['NGC6273'],
        objecttype: 'Globular',
        ra: '17h02m38s',
        dec: '-26d16m04s',
        magnitude: 8.5,
        size: 13.5,
        user_Binocular: 'E',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/M19.htm',
      },

      {
        id: 'M20',
        name: ['Trifid Nebula, The Clover, NGC6514'],
        objecttype: 'Open Cluster+D Neb',
        ra: '18h02m42s',
        dec: '-22d58m18s',
        magnitude: 5,
        size: 29,
        user_Binocular: 'C',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M20.htm',
      },

      {
        id: 'M21',
        name: ['NGC6531'],
        objecttype: 'Open Cluster',
        ra: '18h04m13s',
        dec: '-22d29m24s',
        magnitude: 7,
        size: 13,
        user_Binocular: 'I',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M21.htm',
      },

      {
        id: 'M22',
        name: ['Great Sagittarius Cluster, Crackerjack Cluster, NGC6656'],
        objecttype: 'Globular',
        ra: '18h36m24s',
        dec: '-23d54m17s',
        magnitude: 6.5,
        size: 24,
        user_Binocular: 'E',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M22.htm',
      },

      {
        id: 'M23',
        name: ['NGC6494'],
        objecttype: 'Open Cluster',
        ra: '17h57m04s',
        dec: '-18d59m06s',
        magnitude: 6,
        size: 27,
        user_Binocular: 'E',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/M23.htm',
      },

      {
        id: 'M24',
        name: ['Small Sagittarius Star Cloud, NGC6603,Delle Caustiche'],
        objecttype: 'Open Cluster',
        ra: '18h18m26s',
        dec: '-18d24m24s',
        magnitude: 4.5,
        size: '80x35',
        user_Binocular: 'E',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M24.htm',
      },

      {
        id: 'M25',
        name: [''],
        objecttype: 'Open Cluster',
        ra: '18h31m42s',
        dec: '-19d07m00s',
        magnitude: 6.5,
        size: 40,
        user_Binocular: 'E',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M25.htm',
      },

      {
        id: 'M26',
        name: ['NGC6694'],
        objecttype: 'Open Cluster',
        ra: '18h45m18s',
        dec: '-09d23m00s',
        magnitude: 9.5,
        size: 15,
        user_Binocular: 'E',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M26.htm',
      },

      {
        id: 'M27',
        name: [
          'Dumbbell Nebula, Apple Core, Diablo, Double-headed Shot Nebula, NGC6853,Apple Core Nebula,Diablo Nebula,Double-Headed Shot,Dumbbell Nebula',
        ],
        objecttype: 'P Neb',
        ra: '19h59m36s',
        dec: '+22d43m15s',
        magnitude: 7.5,
        size: 15.2,
        user_Binocular: 'E',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M27.htm',
      },

      {
        id: 'M28',
        name: ['NGC6626'],
        objecttype: 'Globular',
        ra: '18h24m33s',
        dec: '-24d52m07s',
        magnitude: 8.5,
        size: 11.2,
        user_Binocular: 'E',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M28.htm',
      },

      {
        id: 'M29',
        name: ['Cooling Tower, NGC6913'],
        objecttype: 'Open Cluster',
        ra: '20h23m57s',
        dec: '+38d30m30s',
        magnitude: 9,
        size: 7,
        user_Binocular: 'E',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/M29.htm',
      },

      {
        id: 'M30',
        name: ['NGC7099'],
        objecttype: 'Globular',
        ra: '21h40m22s',
        dec: '-23d10m45s',
        magnitude: 8.5,
        size: 11,
        user_Binocular: 'E',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/M30.htm',
      },

      {
        id: 'M31',
        name: ['Andromeda Galaxy, NGC224, UGC454,Andromeda A,Andromeda Galaxy'],
        objecttype: 'Galaxy',
        ra: '00h42m44s',
        dec: '+41d16m08s',
        magnitude: 4.3,
        size: '189.1x61.7',
        user_Binocular: 'E',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/M31.htm',
      },

      {
        id: 'M32',
        name: [
          'Satellite Of Andromeda Galaxy, NGC221, UGC452,Satellite of Andromeda 2',
        ],
        objecttype: 'Galaxy',
        ra: '00h42m42s',
        dec: '+40d51m54s',
        magnitude: 9.1,
        size: '8.5x6.5',
        user_Binocular: 'E',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/M32.htm',
      },

      {
        id: 'M33',
        name: [
          'Triangulum Galaxy, Pinwheel Galaxy, NGC598, UGC1117,Pinwheel Galaxy,Triangulum Galaxy,Triangulum Pinwheel Galaxy',
        ],
        objecttype: 'Galaxy',
        ra: '01h33m51s',
        dec: '+30d39m37s',
        magnitude: 6.2,
        size: '68.7x41.6',
        user_Binocular: 'T',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/M33.htm',
      },

      {
        id: 'M34',
        name: ['Spiral Cluster, NGC1039'],
        objecttype: 'Open Cluster',
        ra: '02h42m05s',
        dec: '+42d45m42s',
        magnitude: 6,
        size: 35,
        user_Binocular: 'E',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/M34.htm',
      },

      {
        id: 'M35',
        name: ['NGC2168'],
        objecttype: 'Open Cluster',
        ra: '06h09m00s',
        dec: '+24d21m00s',
        magnitude: 5.5,
        size: 28,
        user_Binocular: 'E',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/M35.htm',
      },

      {
        id: 'M36',
        name: ['Pinwheel Cluster, NGC1960'],
        objecttype: 'Open Cluster',
        ra: '05h36m18s',
        dec: '+34d08m24s',
        magnitude: 6.5,
        size: 12,
        user_Binocular: 'E',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/M36.htm',
      },

      {
        id: 'M37',
        name: [
          'Auriga Salt-and-pepper Cluster, January Salt-and-pepper Cluster, NGC2099,Auriga Salt-and-Pepper,January Salt-and-Pepper',
        ],
        objecttype: 'Open Cluster',
        ra: '05h52m18s',
        dec: '+32d33m12s',
        magnitude: 6,
        size: 24,
        user_Binocular: 'E',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/M37.htm',
      },

      {
        id: 'M38',
        name: ['Starfish Cluster, NGC1912'],
        objecttype: 'Open Cluster',
        ra: '05h28m40s',
        dec: '+35d50m54s',
        magnitude: 7,
        size: 21,
        user_Binocular: 'E',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/M38.htm',
      },

      {
        id: 'M39',
        name: ['NGC7092'],
        objecttype: 'Open Cluster',
        ra: '21h31m42s',
        dec: '+48d25m00s',
        magnitude: 5.5,
        size: 32,
        user_Binocular: 'E',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/M39.htm',
      },

      {
        id: 'M40',
        name: ['Winnecke 4,Winnecke 4 (WNC4),WNC 4 (Winnecke 4)'],
        objecttype: 'Dbl+Asterism',
        ra: '12h22m12s',
        dec: '+58d05m00s',
        magnitude: 8.7,
        size: 1,
        user_Binocular: 'E',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M40.htm',
      },

      {
        id: 'M41',
        name: ['Little Beehive, NGC2287'],
        objecttype: 'Open Cluster',
        ra: '06h46m01s',
        dec: '-20d45m24s',
        magnitude: 5,
        size: 38,
        user_Binocular: 'E',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/M41.htm',
      },

      {
        id: 'M42',
        name: ['Great Orion Nebula, Orion A, NGC1976,Trapezium'],
        objecttype: 'Open Cluster+D Neb',
        ra: '05h35m16s',
        dec: '-05d23m25s',
        magnitude: 4,
        size: 66,
        user_Binocular: 'E',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/M42.htm',
      },

      {
        id: 'M43',
        name: ["De Mairan's Nebula (Orion Nebula Extension), NGC1982"],
        objecttype: 'D Neb',
        ra: '05h35m31s',
        dec: '-05d16m03s',
        magnitude: 9,
        size: 20,
        user_Binocular: 'I',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/M43.htm',
      },

      {
        id: 'M44',
        name: [
          'Praesepe, Beehive Cluster, NGC2632,Beehive Cluster,Manger (Praesepe),Praesepe (Manger)',
        ],
        objecttype: 'Open Cluster',
        ra: '08h40m24s',
        dec: '+19d40m00s',
        magnitude: 4,
        size: 95,
        user_Binocular: 'E',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/M44.htm',
      },

      {
        id: 'M45',
        name: ['Pleiades, Seven Sisters, Subaru,Pleiades,Seven Sisters,Subaru'],
        objecttype: 'Open Cluster',
        ra: '03h47m30s',
        dec: '+24d07m00s',
        magnitude: 1.6,
        size: '120',
        user_Binocular: 'E',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/M45.htm',
      },

      {
        id: 'M46',
        name: ['NGC2437'],
        objecttype: 'Open Cluster',
        ra: '07h41m46s',
        dec: '-14d48m36s',
        magnitude: 6.5,
        size: 27,
        user_Binocular: 'E',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/M46.htm',
      },

      {
        id: 'M47',
        name: ['NGC2422'],
        objecttype: 'Open Cluster',
        ra: '07h36m35s',
        dec: '-14d29m00s',
        magnitude: 4.5,
        size: 30,
        user_Binocular: 'E',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/M47.htm',
      },

      {
        id: 'M48',
        name: ['NGC2548'],
        objecttype: 'Open Cluster',
        ra: '08h13m43s',
        dec: '-05d45m00s',
        magnitude: 5.5,
        size: 54,
        user_Binocular: 'E',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/M48.htm',
      },

      {
        id: 'M49',
        name: ['NGC4472, UGC7629'],
        objecttype: 'Galaxy',
        ra: '12h29m47s',
        dec: '+08d00m01s',
        magnitude: 9.3,
        size: '9.8x8.2',
        user_Binocular: 'T',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M49.htm',
      },

      {
        id: 'M50',
        name: ['Heart-shaped Cluster, NGC2323'],
        objecttype: 'Open Cluster',
        ra: '07h02m42s',
        dec: '-08d23m00s',
        magnitude: 7,
        size: 16,
        user_Binocular: 'E',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/M50.htm',
      },

      {
        id: 'M51',
        name: [
          "Whirlpool Galaxy, Question Mark Galaxy, NGC5194, UGC8493,Lord Rosse's Nebula,Question Mark,Rosse's Galaxy,Whirlpool Galaxy",
        ],
        objecttype: 'Galaxy',
        ra: '13h29m52s',
        dec: '+47d11m45s',
        magnitude: 8.9,
        size: '10.8x6.6',
        user_Binocular: 'T',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M51.htm',
      },

      {
        id: 'M52',
        name: [
          'The Scorpion, NGC7654,Cassiopeia Salt-and-Pepper,October Salt-and-Pepper',
        ],
        objecttype: 'Open Cluster',
        ra: '23h24m48s',
        dec: '+61d35m36s',
        magnitude: 8,
        size: 13,
        user_Binocular: 'E',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/M52.htm',
      },

      {
        id: 'M53',
        name: ['NGC5024'],
        objecttype: 'Globular',
        ra: '13h12m55s',
        dec: '+18d10m07s',
        magnitude: 8.5,
        size: 12.6,
        user_Binocular: 'E',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M53.htm',
      },

      {
        id: 'M54',
        name: ['NGC6715'],
        objecttype: 'Globular',
        ra: '18h55m03s',
        dec: '-30d28m47s',
        magnitude: 8.5,
        size: 9.1,
        user_Binocular: 'T',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M54.htm',
      },

      {
        id: 'M55',
        name: ['NGC6809'],
        objecttype: 'Globular',
        ra: '19h40m00s',
        dec: '-30d57m44s',
        magnitude: 7,
        size: 19,
        user_Binocular: 'E',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M55.htm',
      },

      {
        id: 'M56',
        name: ['NGC6779'],
        objecttype: 'Globular',
        ra: '19h16m36s',
        dec: '+30d11m02s',
        magnitude: 9.5,
        size: 7.1,
        user_Binocular: 'T',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M56.htm',
      },

      {
        id: 'M57',
        name: ['Ring Nebula, NGC6720'],
        objecttype: 'P Neb',
        ra: '18h53m35s',
        dec: '+33d01m44s',
        magnitude: 9.5,
        size: 2.5,
        user_Binocular: 'I',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M57.htm',
      },

      {
        id: 'M58',
        name: ['NGC4579, UGC7796'],
        objecttype: 'Galaxy',
        ra: '12h37m44s',
        dec: '+11d49m06s',
        magnitude: 10.4,
        size: '6x4.8',
        user_Binocular: 'C',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M58.htm',
      },

      {
        id: 'M59',
        name: ['NGC4621, UGC7858'],
        objecttype: 'Galaxy',
        ra: '12h42m02s',
        dec: '+11d38m48s',
        magnitude: 10.7,
        size: '5.3x4',
        user_Binocular: 'C',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M59.htm',
      },

      {
        id: 'M60',
        name: ['NGC4649, UGC7898'],
        objecttype: 'Galaxy',
        ra: '12h43m40s',
        dec: '+11d33m08s',
        magnitude: 9.8,
        size: '7.6x6.2',
        user_Binocular: 'T',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M60.htm',
      },

      {
        id: 'M61',
        name: ['Swelling Spiral, NGC4303, UGC7420'],
        objecttype: 'Galaxy',
        ra: '12h21m55s',
        dec: '+04d28m23s',
        magnitude: 10.1,
        size: '6.5x5.9',
        user_Binocular: 'T',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M61.htm',
      },

      {
        id: 'M62',
        name: ['Flickering Globular, NGC6266'],
        objecttype: 'Globular',
        ra: '17h01m13s',
        dec: '-30d06m45s',
        magnitude: 8,
        size: 14.1,
        user_Binocular: 'E',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/M62.htm',
      },

      {
        id: 'M63',
        name: ['Sunflower Galaxy, NGC5055, UGC8334'],
        objecttype: 'Galaxy',
        ra: '13h15m49s',
        dec: '+42d01m46s',
        magnitude: 9.3,
        size: '12.6x7.5',
        user_Binocular: 'T',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M63.htm',
      },

      {
        id: 'M64',
        name: [
          'Black Eye Galaxy, Sleeping Beauty Galaxy, NGC4826, UGC8062,Black Eye Galaxy,Sleeping Beauty Galaxy',
        ],
        objecttype: 'Galaxy',
        ra: '12h56m44s',
        dec: '+21d40m58s',
        magnitude: 9.3,
        size: '10.3x5',
        user_Binocular: 'T',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M64.htm',
      },

      {
        id: 'M65',
        name: ['Leo Triplet, NGC3623, UGC6328,Leo Triplet [1]'],
        objecttype: 'Galaxy',
        ra: '11h18m56s',
        dec: '+13d05m31s',
        magnitude: 10.1,
        size: '9x2.3',
        user_Binocular: 'T',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/M65.htm',
      },

      {
        id: 'M66',
        name: ['Leo Triplet, NGC3627, UGC6346,Leo Triplet [2]'],
        objecttype: 'Galaxy',
        ra: '11h20m15s',
        dec: '+12d59m26s',
        magnitude: 9.7,
        size: '9.1x4.1',
        user_Binocular: 'T',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/M66.htm',
      },

      {
        id: 'M67',
        name: ['King Cobra, NGC2682'],
        objecttype: 'Open Cluster',
        ra: '08h51m18s',
        dec: '+11d48m00s',
        magnitude: 7.5,
        size: 30,
        user_Binocular: 'E',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/M67.htm',
      },

      {
        id: 'M68',
        name: ['NGC4590'],
        objecttype: 'Globular',
        ra: '12h39m28s',
        dec: '-26d44m32s',
        magnitude: 9,
        size: 12,
        user_Binocular: 'T',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M68.htm',
      },

      {
        id: 'M69',
        name: ['NGC6637'],
        objecttype: 'Globular',
        ra: '18h31m23s',
        dec: '-32d20m51s',
        magnitude: 9,
        size: 7.1,
        user_Binocular: 'C',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M69.htm',
      },

      {
        id: 'M70',
        name: ['NGC6681'],
        objecttype: 'Globular',
        ra: '18h43m12s',
        dec: '-32d17m27s',
        magnitude: 9,
        size: 7.8,
        user_Binocular: 'C',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M70.htm',
      },

      {
        id: 'M71',
        name: ['NGC6838'],
        objecttype: 'Globular',
        ra: '19h53m46s',
        dec: '+18d46m42s',
        magnitude: 8.5,
        size: 7.2,
        user_Binocular: 'E',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M71.htm',
      },

      {
        id: 'M72',
        name: ['NGC6981'],
        objecttype: 'Globular',
        ra: '20h53m28s',
        dec: '-12d32m14s',
        magnitude: 10,
        size: 5.9,
        user_Binocular: 'C',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/M72.htm',
      },

      {
        id: 'M73',
        name: ['NGC6994'],
        objecttype: 'Open Cluster+Asterism',
        ra: '20h58m56s',
        dec: '-12d38m07s',
        magnitude: 9,
        size: 3,
        user_Binocular: 'I',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/M73.htm',
      },

      {
        id: 'M74',
        name: ['The Phantom, NGC628, UGC1149,Phantom, The'],
        objecttype: 'Galaxy',
        ra: '01h36m42s',
        dec: '+15d47m01s',
        magnitude: 9.8,
        size: '10x9.4',
        user_Binocular: 'I',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/M74.htm',
      },

      {
        id: 'M75',
        name: ['NGC6864'],
        objecttype: 'Globular',
        ra: '20h06m05s',
        dec: '-21d55m19s',
        magnitude: 9.5,
        size: 6,
        user_Binocular: 'T',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/M75.htm',
      },

      {
        id: 'M76',
        name: ['Little Dumbbell Nebula, NGC650,Apple Core Nebula [1]'],
        objecttype: 'P Neb',
        ra: '01h42m18s',
        dec: '+51d34m15s',
        magnitude: 12,
        size: 4.8,
        user_Binocular: 'I',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/M76.htm',
      },

      {
        id: 'M77',
        name: ['Cetus A, NGC1068, UGC2188'],
        objecttype: 'Galaxy',
        ra: '02h42m41s',
        dec: '-00d00m48s',
        magnitude: 9.7,
        size: '7.3x6.3',
        user_Binocular: 'T',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/M77.htm',
      },

      {
        id: 'M78',
        name: ['NGC2068'],
        objecttype: 'D Neb',
        ra: '05h46m45s',
        dec: '+00d04m48s',
        magnitude: 8,
        size: 8,
        user_Binocular: 'E',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/M78.htm',
      },

      {
        id: 'M79',
        name: ['NGC1904'],
        objecttype: 'Globular',
        ra: '05h24m11s',
        dec: '-24d31m29s',
        magnitude: 8.5,
        size: 8.7,
        user_Binocular: 'E',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/M79.htm',
      },

      {
        id: 'M80',
        name: ['NGC6093'],
        objecttype: 'Globular',
        ra: '16h17m03s',
        dec: '-22d58m32s',
        magnitude: 8.5,
        size: 8.9,
        user_Binocular: 'E',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/M80.htm',
      },

      {
        id: 'M81',
        name: ["Bode's Galaxy, NGC3031, UGC5318,Bode's Galaxies (Nebulae) [1]"],
        objecttype: 'Galaxy',
        ra: '09h55m33s',
        dec: '+69d03m56s',
        magnitude: 7.8,
        size: '24.9x11.5',
        user_Binocular: 'E',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/M81.htm',
      },

      {
        id: 'M82',
        name: [
          'Cigar Galaxy, Ursa Major A, NGC3034, UGC5322,Cigar Galaxy,Ursa Major A',
        ],
        objecttype: 'Galaxy',
        ra: '09h55m53s',
        dec: '+69d40m50s',
        magnitude: 9.2,
        size: '10.5x5.1',
        user_Binocular: 'E',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/M82.htm',
      },

      {
        id: 'M83',
        name: ['Southern Pinwheel Galaxy, NGC5236'],
        objecttype: 'Galaxy',
        ra: '13h37m00s',
        dec: '-29d51m51s',
        magnitude: 8,
        size: '13.1x12.2',
        user_Binocular: 'T',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M83.htm',
      },

      {
        id: 'M84',
        name: ["NGC4374, UGC7494,Markarian's Chain"],
        objecttype: 'Galaxy',
        ra: '12h25m04s',
        dec: '+12d53m12s',
        magnitude: 10.2,
        size: '6.7x6',
        user_Binocular: 'C',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M84.htm',
      },

      {
        id: 'M85',
        name: ['NGC4382, UGC7508'],
        objecttype: 'Galaxy',
        ra: '12h25m24s',
        dec: '+18d11m27s',
        magnitude: 10,
        size: '7.4x5.9',
        user_Binocular: 'C',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M85.htm',
      },

      {
        id: 'M86',
        name: ['NGC4406, UGC7532'],
        objecttype: 'Galaxy',
        ra: '12h26m12s',
        dec: '+12d56m46s',
        magnitude: 9.9,
        size: '9.8x6.3',
        user_Binocular: 'C',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M86.htm',
      },

      {
        id: 'M87',
        name: ['Smoking Gun, Virgo A, NGC4486, UGC7654,Smoking Gun,Virgo A'],
        objecttype: 'Galaxy',
        ra: '12h30m49s',
        dec: '+12d23m27s',
        magnitude: 9.6,
        size: '8.7x6.6',
        user_Binocular: 'T',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M87.htm',
      },

      {
        id: 'M88',
        name: ['NGC4501, UGC7675'],
        objecttype: 'Galaxy',
        ra: '12h31m59s',
        dec: '+14d25m12s',
        magnitude: 10.2,
        size: '6.8x3.7',
        user_Binocular: 'C',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M88.htm',
      },

      {
        id: 'M89',
        name: ['NGC4552, UGC7760'],
        objecttype: 'Galaxy',
        ra: '12h35m40s',
        dec: '+12d33m23s',
        magnitude: 10.9,
        size: '5.3x4.8',
        user_Binocular: 'C',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M89.htm',
      },

      {
        id: 'M90',
        name: ['NGC4569, UGC7786'],
        objecttype: 'Galaxy',
        ra: '12h36m50s',
        dec: '+13d09m48s',
        magnitude: 10.2,
        size: '9.9x4.4',
        user_Binocular: 'C',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M90.htm',
      },

      {
        id: 'M91',
        name: ['Missing Messier Object, NGC4548, UGC7753'],
        objecttype: 'Galaxy',
        ra: '12h35m27s',
        dec: '+14d29m47s',
        magnitude: 10.9,
        size: '5.2x4.2',
        user_Binocular: 'I',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M91.htm',
      },

      {
        id: 'M92',
        name: ['NGC6341'],
        objecttype: 'Globular',
        ra: '17h17m07s',
        dec: '+43d08m11s',
        magnitude: 7.5,
        size: 11.2,
        user_Binocular: 'E',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/M92.htm',
      },

      {
        id: 'M93',
        name: ['NGC2447,Butterfly Cluster'],
        objecttype: 'Open Cluster',
        ra: '07h44m30s',
        dec: '-23d51m24s',
        magnitude: 6.5,
        size: 22,
        user_Binocular: 'E',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/M93.htm',
      },

      {
        id: 'M94',
        name: ["Croc's Eye Galaxy, NGC4736, UGC7996"],
        objecttype: 'Galaxy',
        ra: '12h50m53s',
        dec: '+41d07m12s',
        magnitude: 8.9,
        size: '12.3x10.8',
        user_Binocular: 'E',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M94.htm',
      },

      {
        id: 'M95',
        name: ['NGC3351, UGC5850'],
        objecttype: 'Galaxy',
        ra: '10h43m58s',
        dec: '+11d42m13s',
        magnitude: 10.6,
        size: '7.3x4.4',
        user_Binocular: 'C',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/M95.htm',
      },

      {
        id: 'M96',
        name: ['NGC3368, UGC5882'],
        objecttype: 'Galaxy',
        ra: '10h46m46s',
        dec: '+11d49m12s',
        magnitude: 10.1,
        size: '7.8x5.2',
        user_Binocular: 'C',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/M96.htm',
      },

      {
        id: 'M97',
        name: ['Owl Nebula, NGC3587'],
        objecttype: 'P Neb',
        ra: '11h14m48s',
        dec: '+55d01m08s',
        magnitude: 12,
        size: 3.2,
        user_Binocular: 'T',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/M97.htm',
      },

      {
        id: 'M98',
        name: ['NGC4192, UGC7231'],
        objecttype: 'Galaxy',
        ra: '12h13m48s',
        dec: '+14d54m01s',
        magnitude: 10.9,
        size: '9.4x2.3',
        user_Binocular: 'I',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M98.htm',
      },

      {
        id: 'M99',
        name: [
          "Virgo Cluster Pinwheel/Coma Pinwheel Galaxy, NGC4254, UGC7345,Coma Pinwheel Galaxy,[St.] Katherine's Wheel,Pinwheel Galaxy,St. Katherine's Wheel,Virgo Cluster Pinwheel Gal.",
        ],
        objecttype: 'Galaxy',
        ra: '12h18m50s',
        dec: '+14d25m01s',
        magnitude: 10.4,
        size: '5.3x4.6',
        user_Binocular: 'C',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M99.htm',
      },

      {
        id: 'M100',
        name: ['Mirror of M99, NGC4321, UGC7450'],
        objecttype: 'Galaxy',
        ra: '12h22m55s',
        dec: '+15d49m21s',
        magnitude: 10.1,
        size: '7.5x6.1',
        user_Binocular: 'C',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M100.htm',
      },

      {
        id: 'M101',
        name: ['Pinwheel Galaxy, NGC5457, UGC8981'],
        objecttype: 'Galaxy',
        ra: '14h03m13s',
        dec: '+54d20m56s',
        magnitude: 8.2,
        size: '28.5x28.3',
        user_Binocular: 'T',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/M101.htm',
      },

      {
        id: 'M102',
        name: ['Spindle Galaxy (duplicate of M101?), NGC5866, UGC9723'],
        objecttype: 'Galaxy',
        ra: '15h06m30s',
        dec: '+55d45m47s',
        magnitude: 10.8,
        size: '6.5x3.1',
        user_Binocular: 'T',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/M102.htm',
      },

      {
        id: 'M103',
        name: ['NGC581'],
        objecttype: 'Open Cluster',
        ra: '01h33m23s',
        dec: '+60d39m00s',
        magnitude: 7,
        size: 6,
        user_Binocular: 'E',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/M103.htm',
      },

      {
        id: 'M104',
        name: ['Sombrero Galaxy, NGC4594'],
        objecttype: 'Galaxy',
        ra: '12h39m59s',
        dec: '-11d37m23s',
        magnitude: 9.2,
        size: '8.6x4.2',
        user_Binocular: 'T',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M104.htm',
      },

      {
        id: 'M105',
        name: ['NGC3379, UGC5902'],
        objecttype: 'Galaxy',
        ra: '10h47m50s',
        dec: '+12d34m55s',
        magnitude: 10.5,
        size: '5.3x4.8',
        user_Binocular: 'C',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/M105.htm',
      },

      {
        id: 'M106',
        name: ['NGC4258, UGC7353'],
        objecttype: 'Galaxy',
        ra: '12h18m58s',
        dec: '+47d18m16s',
        magnitude: 9.1,
        size: '17.4x6.6',
        user_Binocular: 'T',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M106.htm',
      },

      {
        id: 'M107',
        name: ['NGC6171'],
        objecttype: 'Globular',
        ra: '16h32m32s',
        dec: '-13d03m11s',
        magnitude: 10,
        size: 10,
        user_Binocular: 'C',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/M107.htm',
      },

      {
        id: 'M108',
        name: ['NGC3556, UGC6225'],
        objecttype: 'Galaxy',
        ra: '11h11m31s',
        dec: '+55d40m26s',
        magnitude: 10.6,
        size: '8.6x2.4',
        user_Binocular: 'C',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/M108.htm',
      },

      {
        id: 'M109',
        name: ['NGC3992, UGC6937'],
        objecttype: 'Galaxy',
        ra: '11h57m36s',
        dec: '+53d22m29s',
        magnitude: 10.6,
        size: '7.5x4.4',
        user_Binocular: 'C',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/M109.htm',
      },

      {
        id: 'M110',
        name: [
          'Satellite Of Andromeda Galaxy, NGC205, UGC426,Satellite of Andromeda 1',
        ],
        objecttype: 'Galaxy',
        ra: '00h40m22s',
        dec: '+41d41m07s',
        magnitude: 8.9,
        size: '19.5x11.5',
        user_Binocular: 'C',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/M110.htm',
      },
    ],
  },
  {
    id: 'binocular',
    title: 'Deep Sky Binocular Observing Program',
    description: (
      <VStack spacing={4}>
        <Text>
          The Deep Sky Binocular Observing Program can be accomplished with
          10x50 binoculars. This program contains 60 objects of which several
          are very beautiful open clusters.
        </Text>
      </VStack>
    ),
    targets: [
      {
        id: 'NGC129',
        name: 'NGC129',
        objecttype: 'Open',
        ra: '00h30m00s',
        dec: '+60d13m06s',
        magnitude: 6.5,
        size: '21',
        user_Month: 11,
        user_ImageURL: '',
      },
      {
        id: 'NGC253',
        name: 'NGC253',
        objecttype: 'Galaxy',
        ra: '00h47m33s',
        dec: '-25d17m18s',
        magnitude: 7.1,
        size: '25.1',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC253.htm',
      },
      {
        id: 'NGC457',
        name: 'NGC457',
        objecttype: 'Open',
        ra: '01h19m35s',
        dec: '+58d17m12s',
        magnitude: 6.4,
        size: '13',
        user_Month: 11,
        user_ImageURL: '',
      },
      {
        id: 'NGC663',
        name: 'NGC663',
        objecttype: 'Open',
        ra: '01h46m09s',
        dec: '+61d14m06s',
        magnitude: 7.1,
        size: '16',
        user_Month: 11,
        user_ImageURL: '',
      },
      {
        id: 'Cr463',
        name: 'Lund57,OCL324',
        objecttype: 'Open',
        ra: '01h48m24s',
        dec: '+71d57m00s',
        magnitude: 5.7,
        size: '36',
        user_Month: 11,
        user_ImageURL: '',
      },
      {
        id: 'NGC752',
        name: 'NGC752',
        objecttype: 'Open',
        ra: '01h57m41s',
        dec: '+37d47m06s',
        magnitude: 5.7,
        size: '50',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC752.htm',
      },
      {
        id: 'St2',
        name: 'Stock 2',
        objecttype: 'Open',
        ra: '02h15m00s',
        dec: '+59d16m00s',
        magnitude: 4.4,
        size: '60',
        user_Month: 12,
        user_ImageURL: '',
      },
      {
        id: 'NGC869',
        name: 'NGC869',
        objecttype: 'Open',
        ra: '02h19m00s',
        dec: '+57d07m42s',
        magnitude: 4,
        size: '30',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC869.htm',
      },
      {
        id: 'NGC884',
        name: 'NGC884',
        objecttype: 'Open',
        ra: '02h22m18s',
        dec: '+57d08m12s',
        magnitude: 4,
        size: '30',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC884.htm',
      },
      {
        id: 'Mark 6',
        name: 'Markarian6',
        objecttype: 'Open',
        ra: '02h29m40s',
        dec: '+60d42m24s',
        magnitude: 0,
        size: '6',
        user_Month: 12,
        user_ImageURL: '',
      },
      {
        id: 'Mel15',
        name: 'Mel15',
        objecttype: 'Open',
        ra: '02h32m42s',
        dec: '+61d27m00s',
        magnitude: 6.5,
        size: '21',
        user_Month: 12,
        user_ImageURL: '',
      },
      {
        id: 'Tr2',
        name: 'Tr2',
        objecttype: 'Open',
        ra: '02h37m18s',
        dec: '+55d59m00s',
        magnitude: 5.9,
        size: '20',
        user_Month: 12,
        user_ImageURL: '',
      },
      {
        id: 'Tr3',
        name: 'Tr3',
        objecttype: 'Open',
        ra: '03h11m48s',
        dec: '+63d15m00s',
        magnitude: 7,
        size: '23',
        user_Month: 12,
        user_ImageURL: '',
      },
      {
        id: 'St23',
        name: 'Stock 23',
        objecttype: 'Open',
        ra: '03h16m18s',
        dec: '+60d02m00s',
        magnitude: 0,
        size: '14',
        user_Month: 12,
        user_ImageURL: '',
      },
      {
        id: 'Mel20',
        name: 'Mel20',
        objecttype: 'Open',
        ra: '03h22m00s',
        dec: '+49d00m00s',
        magnitude: 1.2,
        size: '185',
        user_Month: 12,
        user_ImageURL: '',
      },
      {
        id: 'NGC1342',
        name: 'NGC1342',
        objecttype: 'Open',
        ra: '03h31m38s',
        dec: '+37d22m36s',
        magnitude: 6.7,
        size: '14',
        user_Month: 12,
        user_ImageURL: '',
      },
      {
        id: 'Kemble 1',
        name: 'Kemble 1',
        objecttype: 'Open',
        ra: '03h58m00s',
        dec: '+63d00m00s',
        magnitude: 0,
        size: '210x10',
        user_Month: 12,
        user_ImageURL: '',
      },
      {
        id: 'NGC1528',
        name: 'NGC1528',
        objecttype: 'Open',
        ra: '04h15m23s',
        dec: '+51d12m54s',
        magnitude: 6.4,
        size: '24',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/NGC1528.htm',
      },
      {
        id: 'Mel25',
        name: 'Mel25',
        objecttype: 'Open',
        ra: '04h27m00s',
        dec: '+16d00m00s',
        magnitude: 0.5,
        size: '330',
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'NGC1582',
        name: 'NGC1582',
        objecttype: 'Open',
        ra: '04h31m53s',
        dec: '+43d49m00s',
        magnitude: 7,
        size: '37',
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'NGC1647',
        name: 'NGC1647',
        objecttype: 'Open',
        ra: '04h45m55s',
        dec: '+19d06m54s',
        magnitude: 6.4,
        size: '45',
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'NGC1662',
        name: 'NGC1662',
        objecttype: 'Open',
        ra: '04h48m27s',
        dec: '+10d56m12s',
        magnitude: 6.4,
        size: '20',
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'NGC1746',
        name: 'NGC1746',
        objecttype: 'Open',
        ra: '05h03m50s',
        dec: '+23d46m12s',
        magnitude: 6,
        size: '42',
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'NGC1807',
        name: 'NGC1807',
        objecttype: 'Open',
        ra: '05h10m43s',
        dec: '+16d31m18s',
        magnitude: 7,
        size: '17',
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'NGC1817',
        name: 'NGC1817',
        objecttype: 'Open',
        ra: '05h12m15s',
        dec: '+16d41m24s',
        magnitude: 7.7,
        size: '16',
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'NGC1893',
        name: 'NGC1893',
        objecttype: 'Open',
        ra: '05h22m45s',
        dec: '+33d24m42s',
        magnitude: 7.5,
        size: '11',
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'NGC1907',
        name: 'NGC1907',
        objecttype: 'Open',
        ra: '05h28m05s',
        dec: '+35d19m30s',
        magnitude: 8.2,
        size: '7',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/M38.htm',
      },
      {
        id: 'NGC1981',
        name: 'NGC1981',
        objecttype: 'Open',
        ra: '05h35m09s',
        dec: '-04d25m54s',
        magnitude: 4.6,
        size: '25',
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'NGC2169',
        name: 'NGC2169',
        objecttype: 'Open',
        ra: '06h08m24s',
        dec: '+13d57m54s',
        magnitude: 5.9,
        size: '7',
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'NGC2232',
        name: 'NGC2232',
        objecttype: 'Open',
        ra: '06h28m01s',
        dec: '-04d50m48s',
        magnitude: 3.9,
        size: '30',
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'NGC2244',
        name: 'NGC2244',
        objecttype: 'Open',
        ra: '06h31m56s',
        dec: '+04d56m35s',
        magnitude: 4.8,
        size: '24',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2244.htm',
      },
      {
        id: 'NGC2251',
        name: 'NGC2251',
        objecttype: 'Open',
        ra: '06h34m38s',
        dec: '+08d22m00s',
        magnitude: 7.3,
        size: '10',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2251.htm',
      },
      {
        id: 'NGC2264',
        name: 'NGC2264',
        objecttype: 'Open',
        ra: '06h40m58s',
        dec: '+09d53m42s',
        magnitude: 3.9,
        size: '60',
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'NGC2281',
        name: 'NGC2281',
        objecttype: 'Open',
        ra: '06h48m17s',
        dec: '+41d04m42s',
        magnitude: 5.4,
        size: '15',
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'NGC2301',
        name: 'NGC2301',
        objecttype: 'Open',
        ra: '06h51m45s',
        dec: '+00d27m36s',
        magnitude: 6,
        size: '12',
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'NGC2343',
        name: 'NGC2343',
        objecttype: 'Open',
        ra: '07h08m06s',
        dec: '-10d37m00s',
        magnitude: 6.7,
        size: '7',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2343.htm',
      },
      {
        id: 'NGC2360',
        name: 'NGC2360',
        objecttype: 'Open',
        ra: '07h17m43s',
        dec: '-15d38m30s',
        magnitude: 7.2,
        size: '13',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2360.htm',
      },
      {
        id: 'NGC2403',
        name: 'NGC2403',
        objecttype: 'Galaxy',
        ra: '07h36m51s',
        dec: '+65d36m07s',
        magnitude: 8.4,
        size: '17.8',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2403.htm',
      },
      {
        id: 'NGC2527',
        name: 'NGC2527',
        objecttype: 'Open',
        ra: '08h04m58s',
        dec: '-28d08m48s',
        magnitude: 6.5,
        size: '22',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2527.htm',
      },
      {
        id: 'NGC2539',
        name: 'NGC2539',
        objecttype: 'Open',
        ra: '08h10m37s',
        dec: '-12d49m06s',
        magnitude: 6.5,
        size: '22',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2539.htm',
      },
      {
        id: 'NGC2571',
        name: 'NGC2571',
        objecttype: 'Open',
        ra: '08h18m56s',
        dec: '-29d45m00s',
        magnitude: 7,
        size: '13',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2571.htm',
      },
      {
        id: 'Mel111',
        name: 'Mel111',
        objecttype: 'Open',
        ra: '12h25m00s',
        dec: '+26d00m00s',
        magnitude: 1.8,
        size: '275',
        user_Month: 5,
        user_ImageURL: '',
      },
      {
        id: 'IC4665',
        name: 'IC4665',
        objecttype: 'Open',
        ra: '17h46m30s',
        dec: '+05d39m00s',
        magnitude: 4.2,
        size: '41',
        user_Month: 7,
        user_ImageURL: '',
      },
      {
        id: 'NGC6520',
        name: 'NGC6520',
        objecttype: 'Open',
        ra: '18h03m24s',
        dec: '-27d53m18s',
        magnitude: 8,
        size: '6',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6520.htm',
      },
      {
        id: 'NGC6633',
        name: 'NGC6633',
        objecttype: 'Open',
        ra: '18h27m15s',
        dec: '+06d30m30s',
        magnitude: 4.6,
        size: '27',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6633.htm',
      },
      {
        id: 'IC4756',
        name: 'IC4756',
        objecttype: 'Open',
        ra: '18h38m54s',
        dec: '+05d27m00s',
        magnitude: 5,
        size: '52',
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'NGC6709',
        name: 'NGC6709',
        objecttype: 'Open',
        ra: '18h51m18s',
        dec: '+10d19m06s',
        magnitude: 6.7,
        size: '13',
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'NGC6716',
        name: 'NGC6716',
        objecttype: 'Open',
        ra: '18h54m34s',
        dec: '-19d54m06s',
        magnitude: 6.9,
        size: '7',
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'Cr399',
        name: 'Lund890,OCL113',
        objecttype: 'Open',
        ra: '19h25m24s',
        dec: '+20d11m00s',
        magnitude: 3.6,
        size: '60',
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'NGC6819',
        name: 'NGC6819',
        objecttype: 'Open',
        ra: '19h41m18s',
        dec: '+40d11m12s',
        magnitude: 7.3,
        size: '5',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6819.htm',
      },
      {
        id: 'NGC6823',
        name: 'NGC6823',
        objecttype: 'Open',
        ra: '19h43m09s',
        dec: '+23d18m00s',
        magnitude: 7.1,
        size: '12',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6823.htm',
      },
      {
        id: 'NGC6910',
        name: 'NGC6910',
        objecttype: 'Open',
        ra: '20h23m12s',
        dec: '+40d46m42s',
        magnitude: 7.4,
        size: '8',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6910.htm',
      },
      {
        id: 'NGC6934',
        name: 'NGC6934',
        objecttype: 'Globular',
        ra: '20h34m11s',
        dec: '+07d24m17s',
        magnitude: 8.9,
        size: '5.9',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6934.htm',
      },
      {
        id: 'NGC6940',
        name: 'NGC6940',
        objecttype: 'Open',
        ra: '20h34m26s',
        dec: '+28d17m00s',
        magnitude: 6.3,
        size: '31',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6940.htm',
      },
      {
        id: 'NGC7063',
        name: 'NGC7063',
        objecttype: 'Open',
        ra: '21h24m21s',
        dec: '+36d29m12s',
        magnitude: 7,
        size: '8',
        user_Month: 9,
        user_ImageURL: '',
      },
      {
        id: 'NGC7160',
        name: 'NGC7160',
        objecttype: 'Open',
        ra: '21h53m40s',
        dec: '+62d36m12s',
        magnitude: 6.1,
        size: '7',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC7160.htm',
      },
      {
        id: 'NGC7209',
        name: 'NGC7209',
        objecttype: 'Open',
        ra: '22h05m07s',
        dec: '+46d29m00s',
        magnitude: 6.7,
        size: '25',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7209.htm',
      },
      {
        id: 'NGC7235',
        name: 'NGC7235',
        objecttype: 'Open',
        ra: '22h12m25s',
        dec: '+57d16m16s',
        magnitude: 7.7,
        size: '4',
        user_Month: 10,
        user_ImageURL: '',
      },
      {
        id: 'NGC7243',
        name: 'NGC7243',
        objecttype: 'Open',
        ra: '22h15m08s',
        dec: '+49d53m54s',
        magnitude: 6.4,
        size: '21',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7243.htm',
      },
      {
        id: 'NGC7789',
        name: 'NGC7789',
        objecttype: 'Open',
        ra: '23h57m24s',
        dec: '+56d42m30s',
        magnitude: 6.7,
        size: '16',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7789.htm',
      },
    ],
  },
  {
    id: 'doublestar',
    title: 'Double Star Observing Program',
    description: [
      'The Double Star Observing Program contains 100 of the finest double and multiple star systems. The program can be accomplished with a medium sized telescope (4 to 8 inches). This program does allow GOTO and is one that you can do from a suburban location. It does not require dark skies. The program does have a requirement to accurately sketch the double star with correct orientation with respect to North.',
    ],
    targets: [
      {
        id: 'ALDS1',
        name: 'Eta Cas',
        objecttype: 'Mult',
        ra: '00h49m06s',
        dec: '+57d48m57s',
        magnitude: 3.4,
        magnitude2: 7.4,
        separation: 11.6,
        user_Month: 11,
        user_ImageURL: '',
      },
      {
        id: 'ALDS2',
        name: '65 Psc',
        objecttype: 'Dbl',
        ra: '00h49m53s',
        dec: '+27d42m39s',
        magnitude: 7,
        magnitude2: 7,
        separation: 4.5,
        user_Month: 11,
        user_ImageURL: '',
      },
      {
        id: 'ALDS3',
        name: 'Psi Psc',
        objecttype: 'Triple',
        ra: '01h05m41s',
        dec: '+21d28m24s',
        magnitude: 5.3,
        magnitude2: 5.6,
        separation: 29.9,
        user_Month: 11,
        user_ImageURL: '',
      },
      {
        id: 'ALDS4',
        name: 'Zet Psc',
        objecttype: 'Mult',
        ra: '01h13m44s',
        dec: '+07d34m31s',
        magnitude: 5.2,
        magnitude2: 5.3,
        separation: 0.1,
        user_Month: 11,
        user_ImageURL: '',
      },
      {
        id: 'ALDS5',
        name: 'Gam Ari',
        objecttype: 'Triple',
        ra: '01h53m32s',
        dec: '+19d17m45s',
        magnitude: 4.8,
        magnitude2: 4.9,
        separation: 7.8,
        user_Month: 11,
        user_ImageURL: '',
      },
      {
        id: 'ALDS6',
        name: 'Lam Ari',
        objecttype: 'Mult',
        ra: '01h57m56s',
        dec: '+23d35m46s',
        magnitude: 4.8,
        magnitude2: 7.3,
        separation: 37.4,
        user_Month: 11,
        user_ImageURL: '',
      },
      {
        id: 'ALDS7',
        name: 'Alp Psc',
        objecttype: 'Dbl',
        ra: '02h02m03s',
        dec: '+02d45m49s',
        magnitude: 5.2,
        magnitude2: 6.2,
        separation: 2,
        user_Month: 11,
        user_ImageURL: '',
      },
      {
        id: 'ALDS8',
        name: 'Gam And',
        objecttype: 'Mult',
        ra: '02h03m54s',
        dec: '+42d19m47s',
        magnitude: 2.3,
        magnitude2: 5.1,
        separation: 9.6,
        user_Month: 12,
        user_ImageURL: '',
      },
      {
        id: 'ALDS9',
        name: '6 Tri',
        objecttype: 'Dbl',
        ra: '02h12m22s',
        dec: '+30d18m11s',
        magnitude: 4.9,
        magnitude2: 6.5,
        separation: 3.8,
        user_Month: 12,
        user_ImageURL: '',
      },
      {
        id: 'ALDS10',
        name: 'Alp UMi',
        objecttype: 'Mult',
        ra: '02h31m49s',
        dec: '+89d15m51s',
        magnitude: 2,
        magnitude2: 8.8,
        separation: 18.4,
        user_Month: 12,
        user_ImageURL: '',
      },
      {
        id: 'ALDS11',
        name: 'Gam Cet',
        objecttype: 'Triple',
        ra: '02h43m18s',
        dec: '+03d14m09s',
        magnitude: 3.5,
        magnitude2: 6.2,
        separation: 2.8,
        user_Month: 0,
        user_ImageURL: '',
      },
      {
        id: 'ALDS12',
        name: 'Eta Per',
        objecttype: 'Mult',
        ra: '02h50m42s',
        dec: '+55d53m44s',
        magnitude: 3.8,
        magnitude2: 8.5,
        separation: 28.3,
        user_Month: 12,
        user_ImageURL: '',
      },
      {
        id: 'ALDS13',
        name: 'Struve 331',
        objecttype: 'Dbl',
        ra: '03h00m54s',
        dec: '+52d21m00s',
        magnitude: 5.3,
        magnitude2: 6.7,
        separation: 12.3,
        user_Month: 12,
        user_ImageURL: '',
      },
      {
        id: 'ALDS14',
        name: '32 Eri',
        objecttype: 'Triple',
        ra: '03h54m17s',
        dec: '-02d57m10s',
        magnitude: 6.1,
        magnitude2: 7.6,
        separation: 6.8,
        user_Month: 12,
        user_ImageURL: '',
      },
      {
        id: 'ALDS15',
        name: 'Chi Tau',
        objecttype: 'Triple',
        ra: '04h22m35s',
        dec: '+25d37m45s',
        magnitude: 5.4,
        magnitude2: 8.4,
        separation: 19.4,
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'ALDS16',
        name: '1 Cam',
        objecttype: 'Triple',
        ra: '04h32m02s',
        dec: '+53d54m39s',
        magnitude: 5.8,
        magnitude2: 6.9,
        separation: 10.3,
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'ALDS17',
        name: '55 Eri',
        objecttype: 'Dbl',
        ra: '04h43m35s',
        dec: '-08d47m37s',
        magnitude: 6.8,
        magnitude2: 6.9,
        separation: 9.3,
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'ALDS18',
        name: 'Bet Ori',
        objecttype: 'Mult',
        ra: '05h14m32s',
        dec: '-08d12m06s',
        magnitude: 0.1,
        magnitude2: 6.6,
        separation: 9.5,
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'ALDS19',
        name: '118 Tau',
        objecttype: 'Triple',
        ra: '05h29m16s',
        dec: '+25d09m02s',
        magnitude: 5.5,
        magnitude2: 6.3,
        separation: 4.8,
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'ALDS20',
        name: 'Del Ori',
        objecttype: 'Triple',
        ra: '05h32m00s',
        dec: '-00d17m04s',
        magnitude: 6.8,
        magnitude2: 11.4,
        separation: 51.7,
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'ALDS21',
        name: 'Struve 747',
        objecttype: 'Dbl',
        ra: '05h35m00s',
        dec: '-06d00m00s',
        magnitude: 4.8,
        magnitude2: 5.7,
        separation: 35.8,
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'ALDS22',
        name: 'Lam Ori',
        objecttype: 'Mult',
        ra: '05h35m08s',
        dec: '+09d56m03s',
        magnitude: 3.5,
        magnitude2: 5.5,
        separation: 4.4,
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'ALDS23',
        name: '41 Ori',
        objecttype: 'Mult',
        ra: '05h35m17s',
        dec: '-05d23m23s',
        magnitude: 5.1,
        magnitude2: 5.2,
        separation: 117,
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'ALDS24',
        name: 'Iot Ori',
        objecttype: 'Triple',
        ra: '05h35m26s',
        dec: '-05d54m36s',
        magnitude: 2.8,
        magnitude2: 7.3,
        separation: 11.4,
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'ALDS25',
        name: '43 Ori',
        objecttype: 'Mult',
        ra: '05h35m23s',
        dec: '-05d24m58s',
        magnitude: 5.1,
        magnitude2: 5.2,
        separation: 117,
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'ALDS26',
        name: 'Sig Ori',
        objecttype: 'Mult',
        ra: '05h38m45s',
        dec: '-02d36m00s',
        magnitude: 3.8,
        magnitude2: 4.6,
        separation: 0.2,
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'ALDS27',
        name: 'Zet Ori',
        objecttype: 'Triple',
        ra: '05h40m46s',
        dec: '-01d56m34s',
        magnitude: 2,
        magnitude2: 4.2,
        separation: 2.4,
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'ALDS28',
        name: 'Gam Lep',
        objecttype: 'Triple',
        ra: '05h44m28s',
        dec: '-22d26m54s',
        magnitude: 3.6,
        magnitude2: 6.1,
        separation: 96.5,
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'ALDS29',
        name: 'The Aur',
        objecttype: 'Mult',
        ra: '05h59m43s',
        dec: '+37d12m45s',
        magnitude: 2.6,
        magnitude2: 7.1,
        separation: 3.5,
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'ALDS30',
        name: 'Eps Mon',
        objecttype: 'Triple',
        ra: '06h23m46s',
        dec: '+04d35m34s',
        magnitude: 4.4,
        magnitude2: 6.6,
        separation: 12.9,
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'ALDS31',
        name: 'Bet Mon',
        objecttype: 'Mult',
        ra: '06h28m49s',
        dec: '-07d01m58s',
        magnitude: 4.6,
        magnitude2: 5.1,
        separation: 7.1,
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'ALDS32',
        name: '12 Lyn',
        objecttype: 'Mult',
        ra: '06h46m14s',
        dec: '+59d26m30s',
        magnitude: 4.9,
        magnitude2: 5.5,
        separation: 1.7,
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'ALDS33',
        name: 'Eps CMa',
        objecttype: 'Dbl',
        ra: '06h58m38s',
        dec: '-28d58m20s',
        magnitude: 1.5,
        magnitude2: 7.9,
        separation: 7.5,
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'ALDS34',
        name: 'Del Gem',
        objecttype: 'Triple',
        ra: '07h20m07s',
        dec: '+21d58m56s',
        magnitude: 3.5,
        magnitude2: 5.5,
        separation: 0.2,
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'ALDS35',
        name: '19 Lyn',
        objecttype: 'Mult',
        ra: '07h22m51s',
        dec: '+55d17m04s',
        magnitude: 6.5,
        magnitude2: 7.5,
        separation: 15.1,
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'ALDS36',
        name: 'Alp Gem',
        objecttype: 'Mult',
        ra: '07h34m36s',
        dec: '+31d53m19s',
        magnitude: 2.9,
        magnitude2: 3.8,
        separation: 2,
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'ALDS37',
        name: 'Kap Pup',
        objecttype: 'Dbl',
        ra: '07h38m18s',
        dec: '-25d21m53s',
        magnitude: 4.7,
        magnitude2: 5.1,
        separation: 0.2,
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'ALDS38',
        name: 'Zet Cnc',
        objecttype: 'Mult',
        ra: '08h12m13s',
        dec: '+17d38m52s',
        magnitude: 5.6,
        magnitude2: 6,
        separation: 0.9,
        user_Month: 3,
        user_ImageURL: '',
      },
      {
        id: 'ALDS39',
        name: 'Iot Cnc',
        objecttype: 'Dbl',
        ra: '08h46m40s',
        dec: '+28d45m55s',
        magnitude: 6.6,
        magnitude2: 9.1,
        separation: 30.4,
        user_Month: 3,
        user_ImageURL: '',
      },
      {
        id: 'ALDS40',
        name: '38 Lyn',
        objecttype: 'Mult',
        ra: '09h18m51s',
        dec: '+36d48m09s',
        magnitude: 3.8,
        magnitude2: 5.9,
        separation: 2.7,
        user_Month: 3,
        user_ImageURL: '',
      },
      {
        id: 'ALDS41',
        name: 'Alp Leo',
        objecttype: 'Mult',
        ra: '10h08m22s',
        dec: '+11d58m02s',
        magnitude: 1.4,
        magnitude2: 8.2,
        separation: 177.6,
        user_Month: 4,
        user_ImageURL: '',
      },
      {
        id: 'ALDS42',
        name: 'Gam Leo',
        objecttype: 'Mult',
        ra: '10h19m58s',
        dec: '+19d50m30s',
        magnitude: 2.6,
        magnitude2: 3.8,
        separation: 4.5,
        user_Month: 4,
        user_ImageURL: '',
      },
      {
        id: 'ALDS43',
        name: '54 Leo',
        objecttype: 'Dbl',
        ra: '10h55m37s',
        dec: '+24d44m59s',
        magnitude: 4.5,
        magnitude2: 6.4,
        separation: 6.5,
        user_Month: 4,
        user_ImageURL: '',
      },
      {
        id: 'ALDS44',
        name: 'N Hydrae',
        objecttype: 'Dbl',
        ra: '11h32m16s',
        dec: '-29d15m48s',
        magnitude: 5.8,
        magnitude2: 5.9,
        separation: 9.3,
        user_Month: 4,
        user_ImageURL: '',
      },
      {
        id: 'ALDS45',
        name: 'Del Crv',
        objecttype: 'Dbl',
        ra: '12h29m52s',
        dec: '-16d30m56s',
        magnitude: 3,
        magnitude2: 8.2,
        separation: 24.4,
        user_Month: 5,
        user_ImageURL: '',
      },
      {
        id: 'ALDS46',
        name: '24 Com',
        objecttype: 'Dbl',
        ra: '12h35m06s',
        dec: '+18d22m38s',
        magnitude: 6.6,
        magnitude2: 8.1,
        separation: 20.2,
        user_Month: 5,
        user_ImageURL: '',
      },
      {
        id: 'ALDS47',
        name: 'Gam Vir',
        objecttype: 'Mult',
        ra: '12h41m40s',
        dec: '-01d26m58s',
        magnitude: 3.6,
        magnitude2: 3.8,
        separation: 4.1,
        user_Month: 5,
        user_ImageURL: '',
      },
      {
        id: 'ALDS48',
        name: '32 Cam',
        objecttype: 'Triple',
        ra: '12h49m07s',
        dec: '+83d25m05s',
        magnitude: 5.8,
        magnitude2: 6.3,
        separation: 21.6,
        user_Month: 5,
        user_ImageURL: '',
      },
      {
        id: 'ALDS49',
        name: 'Alp CVn',
        objecttype: 'Dbl',
        ra: '12h56m00s',
        dec: '+38d18m53s',
        magnitude: 5.6,
        magnitude2: 8.3,
        separation: 19.4,
        user_Month: 5,
        user_ImageURL: '',
      },
      {
        id: 'ALDS50',
        name: 'Zet UMa',
        objecttype: 'Mult',
        ra: '13h23m56s',
        dec: '+54d55m31s',
        magnitude: 2.3,
        magnitude2: 4,
        separation: 0,
        user_Month: 5,
        user_ImageURL: '',
      },
      {
        id: 'ALDS51',
        name: 'Kap Boo',
        objecttype: 'Dbl',
        ra: '14h13m28s',
        dec: '+51d47m16s',
        magnitude: 6.7,
        magnitude2: 8.9,
        separation: 13.4,
        user_Month: 6,
        user_ImageURL: '',
      },
      {
        id: 'ALDS52',
        name: 'Iot Boo',
        objecttype: 'Triple',
        ra: '14h16m10s',
        dec: '+51d22m02s',
        magnitude: 4.8,
        magnitude2: 8.2,
        separation: 38.7,
        user_Month: 6,
        user_ImageURL: '',
      },
      {
        id: 'ALDS53',
        name: 'Pi Boo',
        objecttype: 'Triple',
        ra: '14h40m44s',
        dec: '+16d25m06s',
        magnitude: 4.9,
        magnitude2: 5.8,
        separation: 5.7,
        user_Month: 6,
        user_ImageURL: '',
      },
      {
        id: 'ALDS54',
        name: 'Eps Boo',
        objecttype: 'Triple',
        ra: '14h44m59s',
        dec: '+27d04m30s',
        magnitude: 5.1,
        magnitude2: 7.7,
        separation: 2.8,
        user_Month: 6,
        user_ImageURL: '',
      },
      {
        id: 'ALDS55',
        name: 'Alp Lib',
        objecttype: 'Triple',
        ra: '14h50m41s',
        dec: '-15d59m50s',
        magnitude: 5.2,
        magnitude2: 5.5,
        separation: 0,
        user_Month: 6,
        user_ImageURL: '',
      },
      {
        id: 'ALDS56',
        name: 'Xi Boo',
        objecttype: 'Mult',
        ra: '14h51m23s',
        dec: '+19d06m04s',
        magnitude: 4.5,
        magnitude2: 6.7,
        separation: 7.2,
        user_Month: 6,
        user_ImageURL: '',
      },
      {
        id: 'ALDS57',
        name: 'Del Boo',
        objecttype: 'Dbl',
        ra: '15h15m30s',
        dec: '+33d18m53s',
        magnitude: 3.5,
        magnitude2: 7.9,
        separation: 104.9,
        user_Month: 6,
        user_ImageURL: '',
      },
      {
        id: 'ALDS58',
        name: 'Mu Boo',
        objecttype: 'Triple',
        ra: '15h24m29s',
        dec: '+37d22m38s',
        magnitude: 4.3,
        magnitude2: 6.5,
        separation: 108.9,
        user_Month: 6,
        user_ImageURL: '',
      },
      {
        id: 'ALDS59',
        name: 'Del Ser',
        objecttype: 'Mult',
        ra: '15h34m48s',
        dec: '+10d32m15s',
        magnitude: 3.8,
        magnitude2: 4.9,
        separation: 3.9,
        user_Month: 6,
        user_ImageURL: '',
      },
      {
        id: 'ALDS60',
        name: 'Zet CrB',
        objecttype: 'Dbl',
        ra: '15h39m22s',
        dec: '+36d38m12s',
        magnitude: 6,
        magnitude2: 7,
        separation: 6.4,
        user_Month: 6,
        user_ImageURL: '',
      },
      {
        id: 'ALDS61',
        name: 'Xi Sco',
        objecttype: 'Mult',
        ra: '16h04m22s',
        dec: '-11d22m23s',
        magnitude: 5.1,
        magnitude2: 5.3,
        separation: 1.2,
        user_Month: 7,
        user_ImageURL: '',
      },
      {
        id: 'ALDS62',
        name: 'Struve 1999',
        objecttype: 'Dbl',
        ra: '16h04m24s',
        dec: '-11d27m00s',
        magnitude: 7.4,
        magnitude2: 8,
        separation: 10.5,
        user_Month: 7,
        user_ImageURL: '',
      },
      {
        id: 'ALDS63',
        name: 'Bet Sco',
        objecttype: 'Mult',
        ra: '16h05m26s',
        dec: '-19d48m20s',
        magnitude: 2.6,
        magnitude2: 3.9,
        separation: 0,
        user_Month: 7,
        user_ImageURL: '',
      },
      {
        id: 'ALDS64',
        name: 'Kap Her',
        objecttype: 'Triple',
        ra: '16h08m04s',
        dec: '+17d02m49s',
        magnitude: 5,
        magnitude2: 6.2,
        separation: 28.1,
        user_Month: 7,
        user_ImageURL: '',
      },
      {
        id: 'ALDS65',
        name: 'Nu Sco',
        objecttype: 'Mult',
        ra: '16h11m59s',
        dec: '-19d26m59s',
        magnitude: 6.3,
        magnitude2: 8.8,
        separation: 41.1,
        user_Month: 7,
        user_ImageURL: '',
      },
      {
        id: 'ALDS66',
        name: 'Sig CrB',
        objecttype: 'Mult',
        ra: '16h14m41s',
        dec: '+33d51m31s',
        magnitude: 5.6,
        magnitude2: 6.6,
        separation: 6.4,
        user_Month: 7,
        user_ImageURL: '',
      },
      {
        id: 'ALDS67',
        name: '16 Dra',
        objecttype: 'Mult',
        ra: '16h36m12s',
        dec: '+52d54m01s',
        magnitude: 5.5,
        magnitude2: 5.5,
        separation: 90.3,
        user_Month: 7,
        user_ImageURL: '',
      },
      {
        id: 'ALDS68',
        name: 'Mu Dra',
        objecttype: 'Triple',
        ra: '17h05m20s',
        dec: '+54d28m13s',
        magnitude: 5.8,
        magnitude2: 5.9,
        separation: 2,
        user_Month: 7,
        user_ImageURL: '',
      },
      {
        id: 'ALDS69',
        name: 'Alp Her',
        objecttype: 'Mult',
        ra: '17h14m39s',
        dec: '+14d23m25s',
        magnitude: 3.5,
        magnitude2: 5.7,
        separation: 4.9,
        user_Month: 7,
        user_ImageURL: '',
      },
      {
        id: 'ALDS70',
        name: 'Del Her',
        objecttype: 'Mult',
        ra: '17h15m02s',
        dec: '+24d50m21s',
        magnitude: 3.1,
        magnitude2: 8.7,
        separation: 8.5,
        user_Month: 7,
        user_ImageURL: '',
      },
      {
        id: 'ALDS71',
        name: '36 Oph',
        objecttype: 'Mult',
        ra: '17h15m21s',
        dec: '-26d36m10s',
        magnitude: 5.1,
        magnitude2: 5.1,
        separation: 4.6,
        user_Month: 7,
        user_ImageURL: '',
      },
      {
        id: 'ALDS72',
        name: 'Omi Oph',
        objecttype: 'Dbl',
        ra: '17h18m01s',
        dec: '-24d17m13s',
        magnitude: 5.2,
        magnitude2: 6.7,
        separation: 10.2,
        user_Month: 7,
        user_ImageURL: '',
      },
      {
        id: 'ALDS73',
        name: 'Rho Her',
        objecttype: 'Triple',
        ra: '17h23m41s',
        dec: '+37d08m48s',
        magnitude: 5.5,
        magnitude2: 6.5,
        separation: 4.2,
        user_Month: 7,
        user_ImageURL: '',
      },
      {
        id: 'ALDS74',
        name: 'Nu Dra',
        objecttype: 'Dbl',
        ra: '17h32m11s',
        dec: '+55d11m03s',
        magnitude: 4.9,
        magnitude2: 4.9,
        separation: 62.3,
        user_Month: 7,
        user_ImageURL: '',
      },
      {
        id: 'ALDS75',
        name: 'Psi Dra',
        objecttype: 'Mult',
        ra: '17h41m56s',
        dec: '+72d08m56s',
        magnitude: 4.6,
        magnitude2: 5.8,
        separation: 30.2,
        user_Month: 7,
        user_ImageURL: '',
      },
      {
        id: 'ALDS76',
        name: '40 Dra',
        objecttype: 'Triple',
        ra: '18h00m03s',
        dec: '+80d00m03s',
        magnitude: 6,
        magnitude2: 6.4,
        separation: 19.3,
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'ALDS77',
        name: '95 Her',
        objecttype: 'Dbl',
        ra: '18h01m30s',
        dec: '+21d35m43s',
        magnitude: 5.2,
        magnitude2: 5.3,
        separation: 6.3,
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'ALDS78',
        name: '70 Oph',
        objecttype: 'Mult',
        ra: '18h05m27s',
        dec: '+02d29m58s',
        magnitude: 4,
        magnitude2: 5.8,
        separation: 1.9,
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'ALDS79',
        name: 'Eps Lyr',
        objecttype: 'Mult',
        ra: '18h44m20s',
        dec: '+39d40m12s',
        magnitude: 5.1,
        magnitude2: 5.1,
        separation: 209.3,
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'ALDS80',
        name: 'Zet Lyr',
        objecttype: 'Mult',
        ra: '18h44m46s',
        dec: '+37d36m18s',
        magnitude: 4.4,
        magnitude2: 5.8,
        separation: 43.7,
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'ALDS81',
        name: 'Bet Lyr,',
        objecttype: 'Mult',
        ra: '18h50m05s',
        dec: '+33d21m46s',
        magnitude: 3.5,
        magnitude2: 8.7,
        separation: 45.7,
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'ALDS82',
        name: 'Struve 2404',
        objecttype: 'Dbl',
        ra: '18h50m48s',
        dec: '+10d59m00s',
        magnitude: 6.9,
        magnitude2: 8,
        separation: 3.6,
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'ALDS83',
        name: 'Otto Struve 525',
        objecttype: 'Mult',
        ra: '18h54m52s',
        dec: '+33d58m07s',
        magnitude: 6,
        magnitude2: 7,
        separation: 45.4,
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'ALDS84',
        name: 'The Ser',
        objecttype: 'Triple',
        ra: '18h56m13s',
        dec: '+04d12m13s',
        magnitude: 4.6,
        magnitude2: 5,
        separation: 22.2,
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'ALDS85',
        name: 'Bet Cyg',
        objecttype: 'Triple',
        ra: '19h30m43s',
        dec: '+27d57m35s',
        magnitude: 3.1,
        magnitude2: 5.1,
        separation: 34.7,
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'ALDS86',
        name: '57 Aql',
        objecttype: 'Dbl',
        ra: '19h54m38s',
        dec: '-08d13m38s',
        magnitude: 5.7,
        magnitude2: 6.5,
        separation: 35.7,
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'ALDS87',
        name: '31 Cyg',
        objecttype: 'Mult',
        ra: '20h13m38s',
        dec: '+46d44m29s',
        magnitude: 3.8,
        magnitude2: 4.8,
        separation: 337.5,
        user_Month: 9,
        user_ImageURL: '',
      },
      {
        id: 'ALDS88',
        name: 'Alp Cap',
        objecttype: 'Mult',
        ra: '20h18m03s',
        dec: '-12d32m41s',
        magnitude: 3.6,
        magnitude2: 4,
        separation: 377.7,
        user_Month: 9,
        user_ImageURL: '',
      },
      {
        id: 'ALDS89',
        name: 'Bet Cap',
        objecttype: 'Mult',
        ra: '20h21m01s',
        dec: '-14d46m53s',
        magnitude: 3.1,
        magnitude2: 3.4,
        separation: 0,
        user_Month: 9,
        user_ImageURL: '',
      },
      {
        id: 'ALDS90',
        name: 'Gam Del',
        objecttype: 'Dbl',
        ra: '20h46m39s',
        dec: '+16d07m28s',
        magnitude: 5.1,
        magnitude2: 6.1,
        separation: 9.8,
        user_Month: 9,
        user_ImageURL: '',
      },
      {
        id: 'ALDS91',
        name: '61 Cyg',
        objecttype: 'Mult',
        ra: '21h06m55s',
        dec: '+38d44m45s',
        magnitude: 5.2,
        magnitude2: 6,
        separation: 28.7,
        user_Month: 9,
        user_ImageURL: '',
      },
      {
        id: 'ALDS92',
        name: 'Bet Cep',
        objecttype: 'Triple',
        ra: '21h28m40s',
        dec: '+70d33m39s',
        magnitude: 3.2,
        magnitude2: 7.8,
        separation: 13.4,
        user_Month: 9,
        user_ImageURL: '',
      },
      {
        id: 'ALDS93',
        name: 'Struve 2816',
        objecttype: 'Dbl',
        ra: '21h39m00s',
        dec: '+57d29m00s',
        magnitude: 5.7,
        magnitude2: 8.1,
        separation: 11.8,
        user_Month: 9,
        user_ImageURL: '',
      },
      {
        id: 'ALDS94',
        name: 'Eps Peg',
        objecttype: 'Triple',
        ra: '21h44m11s',
        dec: '+09d52m30s',
        magnitude: 2.4,
        magnitude2: 8.5,
        separation: 142.5,
        user_Month: 9,
        user_ImageURL: '',
      },
      {
        id: 'ALDS95',
        name: 'Xi Cep',
        objecttype: 'Triple',
        ra: '22h03m47s',
        dec: '+64d37m40s',
        magnitude: 4.3,
        magnitude2: 6.3,
        separation: 7.7,
        user_Month: 10,
        user_ImageURL: '',
      },
      {
        id: 'ALDS96',
        name: 'Zet Aqr',
        objecttype: 'Triple',
        ra: '22h28m50s',
        dec: '-00d01m13s',
        magnitude: 4.6,
        magnitude2: 4.8,
        separation: 1.8,
        user_Month: 10,
        user_ImageURL: '',
      },
      {
        id: 'ALDS97',
        name: 'Del Cep',
        objecttype: 'Triple',
        ra: '22h29m10s',
        dec: '+58d24m55s',
        magnitude: 3.8,
        magnitude2: 6.2,
        separation: 40.8,
        user_Month: 10,
        user_ImageURL: '',
      },
      {
        id: 'ALDS98',
        name: '8 Lac',
        objecttype: 'Mult',
        ra: '22h35m52s',
        dec: '+39d38m03s',
        magnitude: 5.7,
        magnitude2: 6.4,
        separation: 22.4,
        user_Month: 10,
        user_ImageURL: '',
      },
      {
        id: 'ALDS99',
        name: '94 Aqr',
        objecttype: 'Triple',
        ra: '23h19m07s',
        dec: '-13d27m32s',
        magnitude: 5.1,
        magnitude2: 7.5,
        separation: 0.2,
        user_Month: 10,
        user_ImageURL: '',
      },
      {
        id: 'ALDS100',
        name: 'Sig Cas',
        objecttype: 'Triple',
        ra: '23h59m01s',
        dec: '+55d45m18s',
        magnitude: 4.9,
        magnitude2: 7.1,
        separation: 2.8,
        user_Month: 10,
        user_ImageURL: '',
      },
    ],
  },
  {
    id: 'caldwell',
    title: 'Caldwell Observing Program',
    description: (
      <VStack spacing={4}>
        <Text>
          The Caldwell Observing Program is an intermediate program that has
          several very beautiful objects that are often imaged. However, many of
          these objects are difficult to observe visually. I would suggest that
          you need an 8 inch or larger scope for this program with dark skies
          preferred. This program is broken into two different award categories:
          70 objects and the complete list of 109 objects. To observe the
          complete list, you will need access to the Southern Hemisphere.
          However, 70 objects can be observed from the Northern Hemisphere.
        </Text>
      </VStack>
    ),
    targets: [
      {
        id: 'NGC188',
        name: 'C01',
        objecttype: 'Open',
        ra: '00h44m24s',
        dec: '+85d20m00s',
        magnitude: 8.1,
        size: '14',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC188.htm',
      },
      {
        id: 'NGC40',
        name: 'C02  (H400)',
        objecttype: 'P Neb',
        ra: '00h13m00s',
        dec: '+72d32m00s',
        magnitude: 12.4,
        size: '0.6',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC40.htm',
      },
      {
        id: 'NGC4236',
        name: 'C03',
        objecttype: 'S Gal',
        ra: '12h16m42s',
        dec: '+69d28m00s',
        magnitude: 9.7,
        size: '19x7',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4236.htm',
      },
      {
        id: 'NGC7023',
        name: 'C04',
        objecttype: 'BrNeb',
        ra: '21h01m48s',
        dec: '+68d12m00s',
        magnitude: '',
        size: '18',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC7023.htm',
      },
      {
        id: 'IC342',
        name: 'C05',
        objecttype: 'S Gal',
        ra: '03h46m48s',
        dec: '+68d06m00s',
        magnitude: 9.2,
        size: '18x17',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/IC342.htm',
      },
      {
        id: 'NGC6543',
        name: "C6, (H400) Cat's Eye Nebula",
        objecttype: 'P Neb',
        ra: '17h58m36s',
        dec: '+66d38m00s',
        magnitude: 8.1,
        size: '5.8x0.3',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6543.htm',
      },
      {
        id: 'NGC2403',
        name: 'C07 (H400)',
        objecttype: 'S Gal',
        ra: '07h36m54s',
        dec: '+65d36m00s',
        magnitude: 8.4,
        size: '18x10',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2403.htm',
      },
      {
        id: 'NGC559',
        name: 'C08 (H400)',
        objecttype: 'Open',
        ra: '01h29m30s',
        dec: '+63d18m00s',
        magnitude: 9.5,
        size: '4',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC559.htm',
      },
      {
        id: 'Sh2-155',
        name: 'C09,Sh2-155',
        objecttype: 'BrNeb',
        ra: '22h56m48s',
        dec: '+62d37m00s',
        magnitude: '',
        size: '50x10',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/SH2-155.htm',
      },
      {
        id: 'NGC663',
        name: 'C10 (H400)',
        objecttype: 'Open',
        ra: '01h46m00s',
        dec: '+61d15m00s',
        magnitude: 7.1,
        size: '16',
        user_Month: 11,
        user_ImageURL: '',
      },
      {
        id: 'NGC7635',
        name: 'C11, Bubble Nebula',
        objecttype: 'BrNeb',
        ra: '23h20m42s',
        dec: '+61d12m00s',
        magnitude: '',
        size: '15x8',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7635.htm',
      },
      {
        id: 'NGC6946',
        name: 'C12 (H400)',
        objecttype: 'S Gal',
        ra: '20h34m48s',
        dec: '+60d09m00s',
        magnitude: 8.9,
        size: '11x10',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6946.htm',
      },
      {
        id: 'NGC457',
        name: 'C13 (H400), ET/Owl Cluster',
        objecttype: 'Open',
        ra: '01h19m06s',
        dec: '+58d20m00s',
        magnitude: 6.4,
        size: '13',
        user_Month: 11,
        user_ImageURL: '',
      },
      {
        id: 'NGC884',
        name: 'C14 (H400)Sword of Perseus/Double Cluster',
        objecttype: 'Open',
        ra: '02h20m00s',
        dec: '+57d08m00s',
        magnitude: 4.3,
        size: '30',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC884.htm',
      },
      {
        id: 'NGC869',
        name: 'C14, (H400)Sword of Perseus/Double Cluster',
        objecttype: 'Open',
        ra: '02h20m00s',
        dec: '+57d08m00s',
        magnitude: 4.3,
        size: '30',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC869.htm',
      },
      {
        id: 'NGC6826',
        name: 'C15,(H400) Blinking Planetary',
        objecttype: 'P Neb',
        ra: '19h44m48s',
        dec: '+50d31m00s',
        magnitude: 8.8,
        size: '2.3x0.5',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6826.htm',
      },
      {
        id: 'NGC7243',
        name: 'C16, (H400)',
        objecttype: 'Open',
        ra: '22h15m18s',
        dec: '+49d53m00s',
        magnitude: 6.4,
        size: '21',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7243.htm',
      },
      {
        id: 'NGC147',
        name: 'C17,NGC147',
        objecttype: 'E Gal',
        ra: '00h33m12s',
        dec: '+48d30m00s',
        magnitude: 9.3,
        size: '13x8',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC147.htm',
      },
      {
        id: 'NGC185',
        name: 'C18,(H400)',
        objecttype: 'E Gal',
        ra: '00h39m00s',
        dec: '+48d20m00s',
        magnitude: 9.2,
        size: '12x10',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC185.htm',
      },
      {
        id: 'IC 5146',
        name: 'C19,Cocoon Nebula',
        objecttype: 'BrNeb',
        ra: '21h53m30s',
        dec: '+47d16m00s',
        magnitude: '',
        size: '12',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/IC5146.htm',
      },
      {
        id: 'NGC7000',
        name: 'C20,(H400) North American Nebula',
        objecttype: 'BrNeb',
        ra: '20h58m48s',
        dec: '+44d20m00s',
        magnitude: '',
        size: '120x100',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC7000.htm',
      },
      {
        id: 'NGC4449',
        name: 'C21, (H400)',
        objecttype: 'IrrGal',
        ra: '12h28m12s',
        dec: '+44d06m00s',
        magnitude: 9.4,
        size: '5x4',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4449.htm',
      },
      {
        id: 'NGC7662',
        name: 'C22, Blue Snowball, NGC7662',
        objecttype: 'P Neb',
        ra: '23h25m54s',
        dec: '+42d33m00s',
        magnitude: 8.3,
        size: '2.2x0.3',
        user_Month: 10,
        user_ImageURL: '',
      },
      {
        id: 'NGC891',
        name: 'C23, (H400)',
        objecttype: 'S Gal',
        ra: '02h22m36s',
        dec: '+42d21m00s',
        magnitude: 9.9,
        size: '14x3',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC891.htm',
      },
      {
        id: 'NGC1275',
        name: 'C24,Perseus A',
        objecttype: 'IrrGal',
        ra: '03h19m48s',
        dec: '+41d31m00s',
        magnitude: 11.6,
        size: '2.6x2',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC1275.htm',
      },
      {
        id: 'NGC2419',
        name: 'C25, (H400) Intergalactic Wanderer',
        objecttype: 'Globular',
        ra: '07h38m06s',
        dec: '+38d53m00s',
        magnitude: 10.4,
        size: '4.1',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2419.htm',
      },
      {
        id: 'NGC6888',
        name: 'C27,Crescent Nebula',
        objecttype: 'BrNeb',
        ra: '20h12m00s',
        dec: '+38d21m00s',
        magnitude: '',
        size: '20x10',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6888.htm',
      },
      {
        id: 'NGC4244',
        name: 'C26',
        objecttype: 'S Gal',
        ra: '12h17m30s',
        dec: '+37d49m00s',
        magnitude: 10.2,
        size: '16x2.5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4244.htm',
      },
      {
        id: 'NGC752',
        name: 'C28 (H400)',
        objecttype: 'Open',
        ra: '01h57m48s',
        dec: '+37d41m00s',
        magnitude: 5.7,
        size: '50',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC752.htm',
      },
      {
        id: 'NGC5005',
        name: 'C29 (H400)',
        objecttype: 'S Gal',
        ra: '13h10m54s',
        dec: '+37d03m00s',
        magnitude: 9.8,
        size: '5.4x2',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC5005.htm',
      },
      {
        id: 'NGC7331',
        name: 'C30',
        objecttype: 'S Gal',
        ra: '22h37m06s',
        dec: '+34d25m00s',
        magnitude: 9.5,
        size: '11x4',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7331.htm',
      },
      {
        id: 'IC 405',
        name: 'C31,Flaming Star Nebula,IC 405',
        objecttype: 'BrNeb',
        ra: '05h16m12s',
        dec: '+34d16m00s',
        magnitude: '',
        size: '30x19',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/IC405.htm',
      },
      {
        id: 'NGC4631',
        name: 'C32, (H400)Whale Galaxy',
        objecttype: 'S Gal',
        ra: '12h42m06s',
        dec: '+32d32m00s',
        magnitude: 9.3,
        size: '15x3',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4631.htm',
      },
      {
        id: 'NGC6992',
        name: 'C33,Veil/filamentary/lace-work/cirrus/cygnus Loop Nebula',
        objecttype: 'SNR',
        ra: '20h56m24s',
        dec: '+31d43m00s',
        magnitude: '',
        size: '60x8',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6992.htm',
      },
      {
        id: 'NGC6995',
        name: 'C33,Veil/filamentary/lace-work/cirrus/cygnus Loop Nebula',
        objecttype: 'D Neb',
        ra: '20h57m02s',
        dec: '+31d12m49s',
        magnitude: '',
        size: '12',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6995.htm',
      },
      {
        id: 'NGC6960',
        name: 'C34,Veil/filamentary/lace-work/cirrus/cygnus Loop Nebula',
        objecttype: 'SNR',
        ra: '20h45m42s',
        dec: '+30d43m00s',
        magnitude: '',
        size: '70x6',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6960.htm',
      },
      {
        id: 'NGC4889',
        name: 'C35',
        objecttype: 'E Gal',
        ra: '13h00m06s',
        dec: '+27d59m00s',
        magnitude: 11.4,
        size: '3x2',
        user_Month: 5,
        user_ImageURL: '',
      },
      {
        id: 'NGC4559',
        name: 'C36 (H400)',
        objecttype: 'S Gal',
        ra: '12h36m00s',
        dec: '+27d58m00s',
        magnitude: 9.8,
        size: '10x5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4559.htm',
      },
      {
        id: 'NGC6885',
        name: 'C37 (H400)',
        objecttype: 'Open',
        ra: '20h12m00s',
        dec: '+26d29m00s',
        magnitude: 5.9,
        size: '7',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6885.htm',
      },
      {
        id: 'NGC4565',
        name: 'C38, M40, (H400) Winnecke 4',
        objecttype: 'S Gal',
        ra: '12h36m18s',
        dec: '+25d59m00s',
        magnitude: 9.6,
        size: '16x3',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4565.htm',
      },
      {
        id: 'NGC2392',
        name: 'C39, (H400) Eskimo/Clown Nebula',
        objecttype: 'P Neb',
        ra: '07h29m12s',
        dec: '+20d55m00s',
        magnitude: 9.2,
        size: '0.7x0.2',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2392.htm',
      },
      {
        id: 'NGC3626',
        name: 'C40 (H400)',
        objecttype: 'S Gal',
        ra: '11h20m06s',
        dec: '+18d21m00s',
        magnitude: 10.9,
        size: '3x2',
        user_Month: 4,
        user_ImageURL: '',
      },
      {
        id: 'NGC7006',
        name: 'C42 (H400)',
        objecttype: 'Globular',
        ra: '21h01m30s',
        dec: '+16d11m00s',
        magnitude: 10.6,
        size: '2.8',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC7006.htm',
      },
      {
        id: 'NGC7814',
        name: 'C43',
        objecttype: 'S Gal',
        ra: '00h03m18s',
        dec: '+16d09m00s',
        magnitude: 10.5,
        size: '6x2',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC7814.htm',
      },
      {
        id: 'C41',
        name: 'C41, Hyades',
        objecttype: 'Open',
        ra: '04h27m00s',
        dec: '+16d00m00s',
        magnitude: 0.5,
        size: '330',
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'NGC7479',
        name: 'C44 (H400)',
        objecttype: 'S Gal',
        ra: '23h04m54s',
        dec: '+12d19m00s',
        magnitude: 11,
        size: '4x3',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7479.htm',
      },
      {
        id: 'NGC5248',
        name: 'C45 (H400)',
        objecttype: 'S Gal',
        ra: '13h37m30s',
        dec: '+08d53m00s',
        magnitude: 10.2,
        size: '6x4',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC5248.htm',
      },
      {
        id: 'NGC2261',
        name: "C46, Hubble's Variable Nebula",
        objecttype: 'BrNeb',
        ra: '06h39m12s',
        dec: '+08d44m00s',
        magnitude: '',
        size: '2x1',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2261.htm',
      },
      {
        id: 'NGC6934',
        name: 'C47 (H400)',
        objecttype: 'Globular',
        ra: '20h34m12s',
        dec: '+07d24m00s',
        magnitude: 8.9,
        size: '5.9',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6934.htm',
      },
      {
        id: 'NGC2775',
        name: 'C48 (H400)',
        objecttype: 'S Gal',
        ra: '09h10m18s',
        dec: '+07d02m00s',
        magnitude: 10.3,
        size: '4.5x3',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2775.htm',
      },
      {
        id: 'NGC2237',
        name: 'C49, Rosette Nebula,NGC2237-9',
        objecttype: 'BrNeb',
        ra: '06h32m18s',
        dec: '+05d03m00s',
        magnitude: '',
        size: '80x60',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2237.htm',
      },
      {
        id: 'NGC2238',
        name: 'C49, Rosette Nebula',
        objecttype: 'D Neb',
        ra: '06h30m32s',
        dec: '+05d02m55s',
        magnitude: '',
        size: '0',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2238.htm',
      },
      {
        id: 'NGC2239',
        name: 'C49',
        objecttype: 'Open',
        ra: '06h31m00s',
        dec: '+04d57m00s',
        magnitude: '',
        size: '16',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2239.htm',
      },
      {
        id: 'NGC2244',
        name: 'C50 (H400) in Rosette Nebula',
        objecttype: 'Open',
        ra: '06h32m24s',
        dec: '+04d52m00s',
        magnitude: 4.8,
        size: '24',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2244.htm',
      },
      {
        id: 'IC 1613',
        name: 'C51',
        objecttype: 'IrrGal',
        ra: '01h04m48s',
        dec: '+02d07m00s',
        magnitude: 9.3,
        size: '12x11',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/IC1613.htm',
      },
      {
        id: 'NGC4697',
        name: 'C52 (H400)',
        objecttype: 'E Gal',
        ra: '12h48m36s',
        dec: '-05d48m00s',
        magnitude: 9.3,
        size: '6x4',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4697.htm',
      },
      {
        id: 'NGC3115',
        name: 'C53, (H400) Spindle Galaxy',
        objecttype: 'E Gal',
        ra: '10h05m12s',
        dec: '-07d43m00s',
        magnitude: 9.1,
        size: '8x3',
        user_Month: 4,
        user_ImageURL: '',
      },
      {
        id: 'NGC2506',
        name: 'C54 (H400)',
        objecttype: 'Open',
        ra: '08h00m12s',
        dec: '-10d47m00s',
        magnitude: 7.6,
        size: '7',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2506.htm',
      },
      {
        id: 'NGC7009',
        name: 'C55, (H400) Saturn Nebula',
        objecttype: 'P Neb',
        ra: '21h04m12s',
        dec: '-11d22m00s',
        magnitude: 8,
        size: '1.6x0.4',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC7009.htm',
      },
      {
        id: 'NGC246',
        name: 'C56 (H400)',
        objecttype: 'P Neb',
        ra: '00h47m00s',
        dec: '-11d53m00s',
        magnitude: 10.9,
        size: '3.8',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC246.htm',
      },
      {
        id: 'NGC6822',
        name: "C57, Barnard's Galaxy",
        objecttype: 'IrrGal',
        ra: '19h44m54s',
        dec: '-14d48m00s',
        magnitude: 8.8,
        size: '10x9',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6822.htm',
      },
      {
        id: 'NGC2360',
        name: 'C58 (H400)',
        objecttype: 'Open',
        ra: '07h17m48s',
        dec: '-15d37m00s',
        magnitude: 7.2,
        size: '13',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2360.htm',
      },
      {
        id: 'NGC3242',
        name: 'C59,(H400) Eye Nebula/Ghost of Jupiter',
        objecttype: 'P Neb',
        ra: '10h24m48s',
        dec: '-18d38m00s',
        magnitude: 7.8,
        size: '21x0.3',
        user_Month: 4,
        user_ImageURL: '',
      },
      {
        id: 'NGC4038',
        name: 'C60, (H400) Antennae/Ring Tail Galaxies',
        objecttype: 'S Gal',
        ra: '12h01m54s',
        dec: '-18d52m00s',
        magnitude: 10.7,
        size: '2.6x2',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4038.htm',
      },
      {
        id: 'NGC4039',
        name: 'C61, Antennae/Ring Tail Galaxies',
        objecttype: 'S Gal',
        ra: '12h01m54s',
        dec: '-18d53m00s',
        magnitude: 10.7,
        size: '3x2',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4039.htm',
      },
      {
        id: 'NGC247',
        name: 'C62 (H400)',
        objecttype: 'S Gal',
        ra: '00h47m06s',
        dec: '-20d46m00s',
        magnitude: 9.1,
        size: '20x7',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC247.htm',
      },
      {
        id: 'NGC7293',
        name: 'C63, Helix/Sunflower Nebula',
        objecttype: 'P Neb',
        ra: '22h29m36s',
        dec: '-20d48m00s',
        magnitude: 7.3,
        size: '13',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7293.htm',
      },
      {
        id: 'NGC2362',
        name: 'C64, (H400)Tau Cluster/Mexican Jumping Star',
        objecttype: 'Open',
        ra: '07h18m48s',
        dec: '-24d57m00s',
        magnitude: 4.1,
        size: '8',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2362.htm',
      },
      {
        id: 'NGC253',
        name: 'C65, (H400)Sculptor/Silver Coin Galaxy',
        objecttype: 'S Gal',
        ra: '00h47m36s',
        dec: '-25d17m00s',
        magnitude: 7.1,
        size: '25x7',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC253.htm',
      },
      {
        id: 'NGC5694',
        name: 'C66 (H400)',
        objecttype: 'Globular',
        ra: '14h39m36s',
        dec: '-26d32m00s',
        magnitude: 10.2,
        size: '3.6',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/NGC5694.htm',
      },
      {
        id: 'NGC1097',
        name: 'C67',
        objecttype: 'S Gal',
        ra: '02h46m18s',
        dec: '-30d17m00s',
        magnitude: 9.2,
        size: '9x7',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC1097.htm',
      },
      {
        id: 'NGC6729',
        name: 'C68',
        objecttype: 'BrNeb',
        ra: '19h01m54s',
        dec: '-36d57m00s',
        magnitude: 9.7,
        size: '1',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6729.htm',
      },
      {
        id: 'NGC6302',
        name: 'C69, Bug Nebula',
        objecttype: 'P Neb',
        ra: '17h13m42s',
        dec: '-37d06m00s',
        magnitude: 9.6,
        size: '0.8',
        user_Month: 7,
        user_ImageURL: '',
      },
      {
        id: 'NGC300',
        name: 'C70',
        objecttype: 'S Gal',
        ra: '00h54m54s',
        dec: '-37d41m00s',
        magnitude: 8.7,
        size: '20x13',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC300.htm',
      },
      {
        id: 'NGC2477',
        name: 'C71',
        objecttype: 'Open',
        ra: '07h52m18s',
        dec: '-38d33m00s',
        magnitude: 5.8,
        size: '27',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2477.htm',
      },
      {
        id: 'NGC55',
        name: 'C72',
        objecttype: 'S Gal',
        ra: '00h14m54s',
        dec: '-39d11m00s',
        magnitude: 7.9,
        size: '32x6',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC55.htm',
      },
      {
        id: 'NGC1851',
        name: 'C73',
        objecttype: 'Globular',
        ra: '05h14m06s',
        dec: '-40d03m00s',
        magnitude: 7.3,
        size: '11',
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'NGC3132',
        name: 'C74, Southern Ring/Eight-Burst Planetary Nebula',
        objecttype: 'P Neb',
        ra: '10h07m42s',
        dec: '-40d26m00s',
        magnitude: 9.4,
        size: '0.8',
        user_Month: 4,
        user_ImageURL: '',
      },
      {
        id: 'NGC6124',
        name: 'C75',
        objecttype: 'Open',
        ra: '16h25m36s',
        dec: '-40d40m00s',
        magnitude: 5.8,
        size: '29',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6124.htm',
      },
      {
        id: 'NGC6231',
        name: 'C76, Table of Scorpius',
        objecttype: 'Open',
        ra: '16h54m00s',
        dec: '-41d48m00s',
        magnitude: 2.6,
        size: '15',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6231.htm',
      },
      {
        id: 'NGC5128',
        name: 'C77, Hamburger Galaxy/Centaurus A',
        objecttype: 'E Gal',
        ra: '13h25m30s',
        dec: '-43d01m00s',
        magnitude: 7,
        size: '18x14',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC5128.htm',
      },
      {
        id: 'NGC6541',
        name: 'C78',
        objecttype: 'Globular',
        ra: '18h08m00s',
        dec: '-43d42m00s',
        magnitude: 6.6,
        size: '13',
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'NGC3201',
        name: 'C79',
        objecttype: 'Globular',
        ra: '10h17m36s',
        dec: '-46d25m00s',
        magnitude: 6.7,
        size: '18',
        user_Month: '',
        user_ImageURL: 'http://bixhomaastro.com/NGC3201.htm',
      },
      {
        id: 'NGC5139',
        name: 'C80, Omega Centauri',
        objecttype: 'Globular',
        ra: '13h26m48s',
        dec: '-47d29m00s',
        magnitude: 3.6,
        size: '36',
        user_Month: '',
        user_ImageURL: 'http://bixhomaastro.com/NGC5139.htm',
      },
      {
        id: 'NGC6352',
        name: 'C81',
        objecttype: 'Globular',
        ra: '17h25m30s',
        dec: '-48d25m00s',
        magnitude: 8.1,
        size: '7',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC6193',
        name: 'C82',
        objecttype: 'Open',
        ra: '16h41m18s',
        dec: '-48d46m00s',
        magnitude: 5.2,
        size: '15',
        user_Month: '',
        user_ImageURL: 'http://bixhomaastro.com/NGC6193.htm',
      },
      {
        id: 'NGC4945',
        name: 'C83',
        objecttype: 'S Gal',
        ra: '13h05m24s',
        dec: '-49d28m00s',
        magnitude: 8.7,
        size: '20x4',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC5286',
        name: 'C84',
        objecttype: 'Globular',
        ra: '13h46m24s',
        dec: '-51d22m00s',
        magnitude: 7.6,
        size: '9',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'IC 2391',
        name: 'C85, Omicron Velorum Cluster',
        objecttype: 'Open',
        ra: '08h40m12s',
        dec: '-53d04m00s',
        magnitude: 2.5,
        size: '50',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC6397',
        name: 'C86',
        objecttype: 'Globular',
        ra: '17h40m42s',
        dec: '-53d40m00s',
        magnitude: 5.6,
        size: '26',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC1261',
        name: 'C87',
        objecttype: 'Globular',
        ra: '03h12m18s',
        dec: '-55d13m00s',
        magnitude: 8.4,
        size: '7',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC5823',
        name: 'C88',
        objecttype: 'Open',
        ra: '15h05m42s',
        dec: '-55d36m00s',
        magnitude: 7.9,
        size: '10',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC6087',
        name: 'C89',
        objecttype: 'Open',
        ra: '16h18m54s',
        dec: '-57d54m00s',
        magnitude: 5.4,
        size: '12',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC2867',
        name: 'C90',
        objecttype: 'P Neb',
        ra: '09h21m24s',
        dec: '-58d19m00s',
        magnitude: 99,
        size: '0.2',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC3532',
        name: 'C91',
        objecttype: 'Open',
        ra: '11h06m24s',
        dec: '-58d40m00s',
        magnitude: 3,
        size: '55',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC3372',
        name: 'C92, Carina/eta Carinae Nebula',
        objecttype: 'BrNeb',
        ra: '10h43m48s',
        dec: '-59d52m00s',
        magnitude: 99,
        size: '120',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC6752',
        name: 'C93, Pavo Globular',
        objecttype: 'Globular',
        ra: '19h10m54s',
        dec: '-59d59m00s',
        magnitude: 5.4,
        size: '20',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC4755',
        name: 'C94, Kappa Crucis Cluster/Jewel Box',
        objecttype: 'Open',
        ra: '12h53m36s',
        dec: '-60d20m00s',
        magnitude: 4.2,
        size: '10',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC6025',
        name: 'C95',
        objecttype: 'Open',
        ra: '16h03m42s',
        dec: '-60d30m00s',
        magnitude: 5.1,
        size: '12',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC2516',
        name: 'C96',
        objecttype: 'Open',
        ra: '07h58m18s',
        dec: '-60d52m00s',
        magnitude: 3.8,
        size: '30',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC3766',
        name: 'C97',
        objecttype: 'Open',
        ra: '11h36m06s',
        dec: '-61d37m00s',
        magnitude: 5.3,
        size: '12',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC4609',
        name: 'C98',
        objecttype: 'Open',
        ra: '12h42m18s',
        dec: '-62d58m00s',
        magnitude: 6.9,
        size: '5',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'C99',
        name: 'C99',
        objecttype: 'DkNeb',
        ra: '12h53m00s',
        dec: '-63d00m00s',
        magnitude: 99,
        size: '400x300',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'IC 2944',
        name: 'C100, Running Chicken/Lambda Centauri Nebula',
        objecttype: 'Open',
        ra: '11h36m36s',
        dec: '-63d02m00s',
        magnitude: 4.5,
        size: '15',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC6744',
        name: 'C101',
        objecttype: 'S Gal',
        ra: '19h09m48s',
        dec: '-63d51m00s',
        magnitude: 8.3,
        size: '16x10',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'IC 2602',
        name: 'C102, Southern Pleiades/Theta Carinae',
        objecttype: 'Open',
        ra: '10h43m12s',
        dec: '-64d24m00s',
        magnitude: 1.9,
        size: '50',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC2070',
        name: 'C103, Tarantula Nebula/30 Doradus',
        objecttype: 'BrNeb',
        ra: '05h38m42s',
        dec: '-69d06m00s',
        magnitude: '',
        size: '40x25',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC362',
        name: 'C104',
        objecttype: 'Globular',
        ra: '01h03m12s',
        dec: '-70d51m00s',
        magnitude: 6.6,
        size: '13',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC4833',
        name: 'C105',
        objecttype: 'Globular',
        ra: '12h59m36s',
        dec: '-70d53m00s',
        magnitude: 7.3,
        size: '14',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC104',
        name: 'C106, 47 Tucanae',
        objecttype: 'Globular',
        ra: '00h24m06s',
        dec: '-72d05m00s',
        magnitude: 4,
        size: '31',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC6101',
        name: 'C107',
        objecttype: 'Globular',
        ra: '16h25m48s',
        dec: '-72d12m00s',
        magnitude: 9.3,
        size: '11',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC4372',
        name: 'C108',
        objecttype: 'Globular',
        ra: '12h25m48s',
        dec: '-72d40m00s',
        magnitude: 7.8,
        size: '19',
        user_Month: '',
        user_ImageURL: '',
      },
      {
        id: 'NGC3195',
        name: 'C109, Gamma Leonis Group',
        objecttype: 'P Neb',
        ra: '10h09m30s',
        dec: '-80d52m00s',
        magnitude: 99,
        size: '0.6',
        user_Month: '',
        user_ImageURL: '',
      },
    ],
  },
  {
    id: 'herschel',
    title: 'Herschel 400 Observing Program',
    description: (
      <VStack spacing={4}>
        <Text>
          The Herschel 400 Observing Program is for those who really want a
          challenge. To complete this program you really need a 12 inch or
          larger scope and dark skies. Even with a 12" scope, you will not see
          much detail with many of these objects. It is also a program with 400
          objects that will take more than a year to complete. For most of the
          deep sky objects, there is also included a link to an object photo.
          Most of the photos for the Herschel objects are very short exposures
          which will most appropriately indicate how these objects would appear
          visually under dark sky conditions.
        </Text>
      </VStack>
    ),

    targets: [
      {
        id: 'NGC7814',
        name: 'II-240',
        objecttype: 'Galaxy',
        ra: '00h03m15s',
        dec: '+16d08m44s',
        magnitude: 10.5,
        size: '6.7x2.6',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC7814.htm',
      },
      {
        id: 'NGC40',
        name: 'IV-58',
        objecttype: 'P Neb',
        ra: '00h13m01s',
        dec: '+72d31m19s',
        magnitude: 12.4,
        size: '0.6x0.6',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC40.htm',
      },
      {
        id: 'NGC129',
        name: 'VIII-79',
        objecttype: 'Open',
        ra: '00h29m54s',
        dec: '+60d14m00s',
        magnitude: 6.5,
        size: '21',
        user_Month: 11,
        user_ImageURL: '',
      },
      {
        id: 'NGC136',
        name: 'VI-35',
        objecttype: 'Open',
        ra: '00h31m30s',
        dec: '+61d32m00s',
        magnitude: 11.3,
        size: '1.2',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC136.htm',
      },
      {
        id: 'NGC157',
        name: 'II-3',
        objecttype: 'Galaxy',
        ra: '00h34m46s',
        dec: '-08d23m48s',
        magnitude: 10.4,
        size: '4.3x2.9',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC157.htm',
      },
      {
        id: 'NGC185',
        name: 'II-707',
        objecttype: 'Galaxy',
        ra: '00h38m57s',
        dec: '+48d20m14s',
        magnitude: 9.2,
        size: '11x9.8',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC185.htm',
      },
      {
        id: 'NGC205',
        name: 'V-18, M110',
        objecttype: 'Galaxy',
        ra: '00h40m22s',
        dec: '+41d41m08s',
        magnitude: 8,
        size: '19.5x11.5',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/M110.htm',
      },
      {
        id: 'NGC225',
        name: 'VIII-78',
        objecttype: 'Open',
        ra: '00h43m24s',
        dec: '+61d47m00s',
        magnitude: 7,
        size: '12',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC225.htm',
      },
      {
        id: 'NGC246',
        name: 'V-25',
        objecttype: 'P Neb',
        ra: '00h47m04s',
        dec: '-11d52m22s',
        magnitude: 10.9,
        size: '4.6x4.1',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC246.htm',
      },
      {
        id: 'NGC247',
        name: 'V-20',
        objecttype: 'Galaxy',
        ra: '00h47m08s',
        dec: '-20d45m38s',
        magnitude: 8.8,
        size: '21x5.6',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC247.htm',
      },
      {
        id: 'NGC253',
        name: 'V-1, Sculptor Galaxy',
        objecttype: 'Galaxy',
        ra: '00h47m34s',
        dec: '-25d17m32s',
        magnitude: 7.1,
        size: '26.4x6',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC253.htm',
      },
      {
        id: 'NGC278',
        name: 'I-159',
        objecttype: 'Galaxy',
        ra: '00h52m04s',
        dec: '+47d33m01s',
        magnitude: 11.3,
        size: '2.2x2.1',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC278.htm',
      },
      {
        id: 'NGC288',
        name: 'VI-20',
        objecttype: 'Globular',
        ra: '00h52m47s',
        dec: '-26d35m24s',
        magnitude: 8.1,
        size: '13.8',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC288.htm',
      },
      {
        id: 'NGC381',
        name: 'VIII-64',
        objecttype: 'Open',
        ra: '01h08m18s',
        dec: '+61d35m00s',
        magnitude: 9.3,
        size: '6',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC381.htm',
      },
      {
        id: 'NGC404',
        name: 'II-224',
        objecttype: 'Galaxy',
        ra: '01h09m27s',
        dec: '+35d43m05s',
        magnitude: 11.7,
        size: '4.4x4.2',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC404.htm',
      },
      {
        id: 'NGC436',
        name: 'VII-45',
        objecttype: 'Open',
        ra: '01h15m36s',
        dec: '+58d49m00s',
        magnitude: 8.8,
        size: '5',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC436.htm',
      },
      {
        id: 'NGC457',
        name: 'VII-42, ET Cluster',
        objecttype: 'Open',
        ra: '01h19m06s',
        dec: '+58d20m00s',
        magnitude: 6.4,
        size: '13',
        user_Month: 11,
        user_ImageURL: '',
      },
      {
        id: 'NGC488',
        name: 'III-252',
        objecttype: 'Galaxy',
        ra: '01h21m47s',
        dec: '+05d15m23s',
        magnitude: 10.3,
        size: '5.2x4.1',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC488.htm',
      },
      {
        id: 'NGC524',
        name: 'I-151',
        objecttype: 'Galaxy',
        ra: '01h24m48s',
        dec: '+09d32m20s',
        magnitude: 10.6,
        size: '3.2',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC524.htm',
      },
      {
        id: 'NGC559',
        name: 'VII-48',
        objecttype: 'Open',
        ra: '01h29m30s',
        dec: '+63d18m00s',
        magnitude: 9.5,
        size: '4.4',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC559.htm',
      },
      {
        id: 'NGC584',
        name: 'I-100',
        objecttype: 'Galaxy',
        ra: '01h31m21s',
        dec: '-06d52m06s',
        magnitude: 10.4,
        size: '3.8x2.4',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC584.htm',
      },
      {
        id: 'NGC596',
        name: 'II-4',
        objecttype: 'Galaxy',
        ra: '01h32m52s',
        dec: '-07d01m57s',
        magnitude: 10.9,
        size: '3.5x2.2',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC596.htm',
      },
      {
        id: 'NGC598',
        name: 'V-17, Pinwheel, Triangulum Galaxy',
        objecttype: 'Galaxy',
        ra: '01h33m51s',
        dec: '+30d39m37s',
        magnitude: 5.7,
        size: '68.7x41.6',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/M33.htm',
      },
      {
        id: 'NGC613',
        name: 'I-281',
        objecttype: 'Galaxy',
        ra: '01h34m18s',
        dec: '-29d25m09s',
        magnitude: 10,
        size: '5.8x4.6',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC613.htm',
      },
      {
        id: 'NGC615',
        name: 'II-282',
        objecttype: 'Galaxy',
        ra: '01h35m06s',
        dec: '-07d20m27s',
        magnitude: 11.5,
        size: '4x1.7',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC615.htm',
      },
      {
        id: 'NGC651',
        name: 'I-193, Little Dumbbell',
        objecttype: 'P Neb',
        ra: '01h42m21s',
        dec: '+51d34m07s',
        magnitude: 12.2,
        size: '2.7x1.8',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/M76.htm',
      },
      {
        id: 'NGC637',
        name: 'VII-49',
        objecttype: 'Open',
        ra: '01h42m54s',
        dec: '+64d00m00s',
        magnitude: 8.2,
        size: '3.5',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC637.htm',
      },
      {
        id: 'NGC654',
        name: 'VII-46',
        objecttype: 'Open',
        ra: '01h44m06s',
        dec: '+61d53m00s',
        magnitude: 6.5,
        size: '5',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC654.htm',
      },
      {
        id: 'NGC659',
        name: 'VIII-65',
        objecttype: 'Open',
        ra: '01h44m12s',
        dec: '+60d42m00s',
        magnitude: 7.9,
        size: '5',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC659.htm',
      },
      {
        id: 'NGC663',
        name: 'VI-31',
        objecttype: 'Open',
        ra: '01h46m00s',
        dec: '+61d15m00s',
        magnitude: 7.1,
        size: '16',
        user_Month: 11,
        user_ImageURL: '',
      },
      {
        id: 'NGC720',
        name: 'I-105',
        objecttype: 'Galaxy',
        ra: '01h53m00s',
        dec: '-13d44m21s',
        magnitude: 10.2,
        size: '4.4x2.8',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC720.htm',
      },
      {
        id: 'NGC752',
        name: 'VII-32',
        objecttype: 'Open',
        ra: '01h57m48s',
        dec: '+37d41m00s',
        magnitude: 5.7,
        size: '50',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC752.htm',
      },
      {
        id: 'NGC772',
        name: 'I-112',
        objecttype: 'Galaxy',
        ra: '01h59m20s',
        dec: '+19d00m28s',
        magnitude: 10.3,
        size: '7.5x4.3',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC772.htm',
      },
      {
        id: 'NGC779',
        name: 'I-101',
        objecttype: 'Galaxy',
        ra: '01h59m43s',
        dec: '-05d57m53s',
        magnitude: 11,
        size: '4.1x1.4',
        user_Month: 11,
        user_ImageURL: 'http://bixhomaastro.com/NGC779.htm',
      },
      {
        id: 'NGC869',
        name: 'VI-33Sword of Perseus/Double Cluster',
        objecttype: 'Open',
        ra: '02h19m00s',
        dec: '+57d09m00s',
        magnitude: 5.3,
        size: '29',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC869.htm',
      },
      {
        id: 'NGC884',
        name: 'VI-34, Sword of Perseus/Double Cluster',
        objecttype: 'Open',
        ra: '02h22m24s',
        dec: '+57d07m00s',
        magnitude: 6.1,
        size: '29',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC884.htm',
      },
      {
        id: 'NGC891',
        name: 'V-19',
        objecttype: 'Galaxy',
        ra: '02h22m33s',
        dec: '+42d20m46s',
        magnitude: 10,
        size: '13x2.8',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC891.htm',
      },
      {
        id: 'NGC908',
        name: 'I-153',
        objecttype: 'Galaxy',
        ra: '02h23m05s',
        dec: '-21d14m02s',
        magnitude: 10.2,
        size: '5.5x2.8',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC908.htm',
      },
      {
        id: 'NGC936',
        name: 'IV-23',
        objecttype: 'Galaxy',
        ra: '02h27m37s',
        dec: '-01d09m23s',
        magnitude: 10.1,
        size: '5.2x4.4',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC936.htm',
      },
      {
        id: 'NGC1022',
        name: 'I-102',
        objecttype: 'Galaxy',
        ra: '02h38m32s',
        dec: '-06d40m41s',
        magnitude: 11.4,
        size: '2.5x2.1',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC1022.htm',
      },
      {
        id: 'NGC1023',
        name: 'I-156',
        objecttype: 'Galaxy',
        ra: '02h40m24s',
        dec: '+39d03m46s',
        magnitude: 9.2,
        size: '8.7x3.3',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC1023.htm',
      },
      {
        id: 'NGC1052',
        name: 'I-63',
        objecttype: 'Galaxy',
        ra: '02h41m05s',
        dec: '-08d15m19s',
        magnitude: 10.5,
        size: '2.9x2',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC1052.htm',
      },
      {
        id: 'NGC1055',
        name: 'II-6,I-1, Sombrero???',
        objecttype: 'Galaxy',
        ra: '02h41m45s',
        dec: '+00d26m32s',
        magnitude: 10.6,
        size: '7.6x3',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC1055.htm',
      },
      {
        id: 'NGC1027',
        name: 'VIII-66',
        objecttype: 'Open',
        ra: '02h42m42s',
        dec: '+61d33m00s',
        magnitude: 6.7,
        size: '20',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC1027.htm',
      },
      {
        id: 'NGC1084',
        name: 'I-64',
        objecttype: 'Galaxy',
        ra: '02h46m00s',
        dec: '-07d34m42s',
        magnitude: 10.6,
        size: '3x1.5',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC1084.htm',
      },
      {
        id: 'NGC1245',
        name: 'VI-25',
        objecttype: 'Open',
        ra: '03h14m42s',
        dec: '+47d15m00s',
        magnitude: 8.4,
        size: '10',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC1245.htm',
      },
      {
        id: 'NGC1342',
        name: 'VIII-88',
        objecttype: 'Open',
        ra: '03h31m36s',
        dec: '+37d20m00s',
        magnitude: 6.7,
        size: '14',
        user_Month: 12,
        user_ImageURL: '',
      },
      {
        id: 'NGC1407',
        name: 'I-107',
        objecttype: 'Galaxy',
        ra: '03h40m12s',
        dec: '-18d34m51s',
        magnitude: 9.8,
        size: '2.5',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC1407.htm',
      },
      {
        id: 'NGC1444',
        name: 'VIII-80',
        objecttype: 'Open',
        ra: '03h49m24s',
        dec: '+52d40m00s',
        magnitude: 6.6,
        size: '4',
        user_Month: 12,
        user_ImageURL: 'http://bixhomaastro.com/NGC1444.htm',
      },
      {
        id: 'NGC1501',
        name: 'IV-53',
        objecttype: 'P Neb',
        ra: '04h06m59s',
        dec: '+60d55m15s',
        magnitude: 11.5,
        size: '0.9x0.8',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/NGC1501.htm',
      },
      {
        id: 'NGC1502',
        name: 'VII-47',
        objecttype: 'Open',
        ra: '04h07m42s',
        dec: '+62d20m00s',
        magnitude: 6.9,
        size: '7',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/NGC1502.htm',
      },
      {
        id: 'NGC1513',
        name: 'VII-60',
        objecttype: 'Open',
        ra: '04h10m00s',
        dec: '+49d31m00s',
        magnitude: 8.4,
        size: '9',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/NGC1513.htm',
      },
      {
        id: 'NGC1535',
        name: 'IV-26',
        objecttype: 'P Neb',
        ra: '04h14m16s',
        dec: '-12d44m22s',
        magnitude: 9.6,
        size: '0.8x0.7',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/NGC1535.htm',
      },
      {
        id: 'NGC1528',
        name: 'VII-61',
        objecttype: 'Open',
        ra: '04h15m24s',
        dec: '+51d14m00s',
        magnitude: 6.4,
        size: '23',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/NGC1528.htm',
      },
      {
        id: 'NGC1545',
        name: 'VIII-85',
        objecttype: 'Open',
        ra: '04h20m54s',
        dec: '+50d15m00s',
        magnitude: 6.2,
        size: '18',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/NGC1545.htm',
      },
      {
        id: 'NGC1647',
        name: 'VIII-8',
        objecttype: 'Open',
        ra: '04h46m00s',
        dec: '+19d04m00s',
        magnitude: 6.4,
        size: '45',
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'NGC1664',
        name: 'VIII-59',
        objecttype: 'Open',
        ra: '04h51m06s',
        dec: '+43d42m00s',
        magnitude: 7.6,
        size: '18',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/NGC1664.htm',
      },
      {
        id: 'NGC1788',
        name: 'V-32',
        objecttype: 'Neb',
        ra: '05h06m29s',
        dec: '-03d21m00s',
        magnitude: 9,
        size: '8x5',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/NGC1788.htm',
      },
      {
        id: 'NGC1817',
        name: 'VII-4',
        objecttype: 'Open',
        ra: '05h12m06s',
        dec: '+16d42m00s',
        magnitude: 7.7,
        size: '15',
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'NGC1857',
        name: 'VII-33',
        objecttype: 'Open',
        ra: '05h20m12s',
        dec: '+39d21m00s',
        magnitude: 7,
        size: '5',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/NGC1857.htm',
      },
      {
        id: 'NGC1907',
        name: 'VII-39',
        objecttype: 'Open',
        ra: '05h28m00s',
        dec: '+35d19m00s',
        magnitude: 8.2,
        size: '6',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/NGC1097.htm',
      },
      {
        id: 'NGC1931',
        name: 'I-261',
        objecttype: 'Open',
        ra: '05h31m21s',
        dec: '+34d13m43s',
        magnitude: 10.1,
        size: '1',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/NGC1931.htm',
      },
      {
        id: 'NGC1964',
        name: 'IV-21',
        objecttype: 'Galaxy',
        ra: '05h33m22s',
        dec: '-21d56m45s',
        magnitude: 10.7,
        size: '6.2x2.5',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/NGC1964.htm',
      },
      {
        id: 'NGC1980',
        name: 'V-31',
        objecttype: 'Open',
        ra: '05h35m24s',
        dec: '-05d54m00s',
        magnitude: 2.5,
        size: '13',
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'NGC1999',
        name: 'IV-33',
        objecttype: 'Neb',
        ra: '05h36m25s',
        dec: '-06d42m49s',
        magnitude: 9,
        size: '2',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/NGC1999.htm',
      },
      {
        id: 'NGC2024',
        name: 'V-28, Tank Track/Flame Nebula',
        objecttype: 'Neb',
        ra: '05h41m54s',
        dec: '-01d51m00s',
        magnitude: 10.7,
        size: '30',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/NGC2024.htm',
      },
      {
        id: 'NGC1961',
        name: 'III-747',
        objecttype: 'Galaxy',
        ra: '05h42m05s',
        dec: '+69d22m42s',
        magnitude: 11.2,
        size: '4.3x3',
        user_Month: 1,
        user_ImageURL: '',
      },
      {
        id: 'NGC2022',
        name: 'IV-34',
        objecttype: 'P Neb',
        ra: '05h42m06s',
        dec: '+09d05m10s',
        magnitude: 11.9,
        size: '0.5x0.5',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/NGC2022.htm',
      },
      {
        id: 'NGC2129',
        name: 'VIII-26',
        objecttype: 'Open',
        ra: '06h01m00s',
        dec: '+23d18m00s',
        magnitude: 6.7,
        size: '6',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/NGC2129.htm',
      },
      {
        id: 'NGC2126',
        name: 'VIII-68',
        objecttype: 'Open',
        ra: '06h03m00s',
        dec: '+49d54m00s',
        magnitude: 10.2,
        size: '6',
        user_Month: 1,
        user_ImageURL: 'http://bixhomaastro.com/NGC2126.htm',
      },
      {
        id: 'NGC2158',
        name: 'VI-17',
        objecttype: 'Open',
        ra: '06h07m30s',
        dec: '+24d06m00s',
        magnitude: 8.6,
        size: '5',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/M35.htm',
      },
      {
        id: 'NGC2169',
        name: 'VIII-24',
        objecttype: 'Open',
        ra: '06h08m24s',
        dec: '+13d57m00s',
        magnitude: 5.9,
        size: '6',
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'NGC2185',
        name: 'IV-20',
        objecttype: 'Neb',
        ra: '06h11m03s',
        dec: '-06d13m46s',
        magnitude: 12,
        size: '5x3',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2185.htm',
      },
      {
        id: 'NGC2186',
        name: 'VII-25',
        objecttype: 'Open',
        ra: '06h12m12s',
        dec: '+05d27m00s',
        magnitude: 8.7,
        size: '4',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2186.htm',
      },
      {
        id: 'NGC2194',
        name: 'VI-5',
        objecttype: 'Open',
        ra: '06h13m48s',
        dec: '+12d48m00s',
        magnitude: 8.5,
        size: '10',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2194.htm',
      },
      {
        id: 'NGC2204',
        name: 'VII-13',
        objecttype: 'Open',
        ra: '06h15m42s',
        dec: '-18d39m00s',
        magnitude: 8.6,
        size: '12',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2204.htm',
      },
      {
        id: 'NGC2215',
        name: 'VII-20',
        objecttype: 'Open',
        ra: '06h21m00s',
        dec: '-07d17m00s',
        magnitude: 8.4,
        size: '11',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2215.htm',
      },
      {
        id: 'NGC2232',
        name: 'VIII-25',
        objecttype: 'Open',
        ra: '06h26m36s',
        dec: '-04d45m00s',
        magnitude: 3.9,
        size: '29',
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'NGC2244',
        name: 'VII-2, in Rosette Nebula',
        objecttype: 'Open',
        ra: '06h32m24s',
        dec: '+04d52m00s',
        magnitude: 4.8,
        size: '23',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2244.htm',
      },
      {
        id: 'NGC2251',
        name: 'VIII-3',
        objecttype: 'Open',
        ra: '06h34m42s',
        dec: '+08d22m00s',
        magnitude: 7.3,
        size: '10',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2251.htm',
      },
      {
        id: 'NGC2264',
        name: 'V-27,VIII, Xmas Tree Nebula/Cone Cluster',
        objecttype: 'Open',
        ra: '06h41m06s',
        dec: '+09d53m00s',
        magnitude: 3.9,
        size: '20',
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'NGC2266',
        name: 'VI-21',
        objecttype: 'Open',
        ra: '06h43m12s',
        dec: '+26d58m00s',
        magnitude: 9.5,
        size: '6',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2266.htm',
      },
      {
        id: 'NGC2286',
        name: 'VIII-31',
        objecttype: 'Open',
        ra: '06h47m36s',
        dec: '-03d10m00s',
        magnitude: 7.5,
        size: '14',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2286.htm',
      },
      {
        id: 'NGC2281',
        name: 'VIII-71',
        objecttype: 'Open',
        ra: '06h49m18s',
        dec: '+41d04m00s',
        magnitude: 5.4,
        size: '14',
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'NGC2301',
        name: 'VI-27',
        objecttype: 'Open',
        ra: '06h51m48s',
        dec: '+00d28m00s',
        magnitude: 6,
        size: '12',
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'NGC2304',
        name: 'VI-2',
        objecttype: 'Open',
        ra: '06h55m00s',
        dec: '+18d01m00s',
        magnitude: 10,
        size: '5',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2304.htm',
      },
      {
        id: 'NGC2311',
        name: 'VIII-60',
        objecttype: 'Open',
        ra: '06h57m48s',
        dec: '-04d35m00s',
        magnitude: 9.6,
        size: '6',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2311.htm',
      },
      {
        id: 'NGC2324',
        name: 'VII-38',
        objecttype: 'Open',
        ra: '07h04m12s',
        dec: '+01d03m00s',
        magnitude: 8.4,
        size: '7',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2324.htm',
      },
      {
        id: 'NGC2335',
        name: 'VIII-32',
        objecttype: 'Open',
        ra: '07h06m36s',
        dec: '-10d05m00s',
        magnitude: 7.2,
        size: '12',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2335.htm',
      },
      {
        id: 'NGC2343',
        name: 'VIII-33',
        objecttype: 'Open',
        ra: '07h08m18s',
        dec: '-10d39m00s',
        magnitude: 6.7,
        size: '6',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2343.htm',
      },
      {
        id: 'NGC2354',
        name: 'VII-16',
        objecttype: 'Open',
        ra: '07h14m18s',
        dec: '-25d44m00s',
        magnitude: 6.5,
        size: '20',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2354.htm',
      },
      {
        id: 'NGC2353',
        name: 'VIII-34',
        objecttype: 'Open',
        ra: '07h14m36s',
        dec: '-10d18m00s',
        magnitude: 7.1,
        size: '20',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2353.htm',
      },
      {
        id: 'NGC2355',
        name: 'VI-6',
        objecttype: 'Open',
        ra: '07h16m54s',
        dec: '+13d47m00s',
        magnitude: 9.7,
        size: '9',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2355.htm',
      },
      {
        id: 'NGC2360',
        name: 'VII-12',
        objecttype: 'Open',
        ra: '07h17m48s',
        dec: '-15d37m00s',
        magnitude: 7.2,
        size: '12',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2360.htm',
      },
      {
        id: 'NGC2362',
        name: 'VII-17',
        objecttype: 'Open',
        ra: '07h18m48s',
        dec: '-24d57m00s',
        magnitude: 4.1,
        size: '8',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2362.htm',
      },
      {
        id: 'NGC2371',
        name: 'II-316',
        objecttype: 'P Neb',
        ra: '07h25m34s',
        dec: '+29d29m16s',
        magnitude: 13,
        size: '1.2x0.9',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2371.htm',
      },
      {
        id: 'NGC2372',
        name: 'II-317',
        objecttype: 'P Neb',
        ra: '07h25m35s',
        dec: '+29d29m36s',
        magnitude: 13,
        size: '1.2x0.9',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2372.htm',
      },
      {
        id: 'NGC2395',
        name: 'VIII-11',
        objecttype: 'Open',
        ra: '07h27m06s',
        dec: '+13d35m00s',
        magnitude: 8,
        size: '12',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2395.htm',
      },
      {
        id: 'NGC2392',
        name: 'IV-45, Eskimo/Clown Nebula',
        objecttype: 'P Neb',
        ra: '07h29m11s',
        dec: '+20d54m43s',
        magnitude: 9.2,
        size: '0.8x0.7',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2392.htm',
      },
      {
        id: 'NGC2421',
        name: 'VII-67',
        objecttype: 'Open',
        ra: '07h36m18s',
        dec: '-20d37m00s',
        magnitude: 8.3,
        size: '10',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2421.htm',
      },
      {
        id: 'NGC2422',
        name: 'VIII-38, M47\r',
        objecttype: 'Open',
        ra: '07h36m36s',
        dec: '-14d30m00s',
        magnitude: 4.4,
        size: '29',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/M47.htm',
      },
      {
        id: 'NGC2403',
        name: 'V-44',
        objecttype: 'Galaxy',
        ra: '07h36m51s',
        dec: '+65d36m10s',
        magnitude: 8.4,
        size: '18x11',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2403.htm',
      },
      {
        id: 'NGC2423',
        name: 'VII-28',
        objecttype: 'Open',
        ra: '07h37m06s',
        dec: '-13d52m00s',
        magnitude: 6.7,
        size: '19',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2423.htm',
      },
      {
        id: 'NGC2419',
        name: 'I-218, Intergalactic Wanderer',
        objecttype: 'Globular',
        ra: '07h38m08s',
        dec: '+38d52m55s',
        magnitude: 10.4,
        size: '4.1',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2419.htm',
      },
      {
        id: 'NGC2420',
        name: 'VI-1',
        objecttype: 'Open',
        ra: '07h38m30s',
        dec: '+21d34m00s',
        magnitude: 8.3,
        size: '10',
        user_Month: 2,
        user_ImageURL: '',
      },
      {
        id: 'NGC2438',
        name: 'IV-39',
        objecttype: 'P Neb',
        ra: '07h41m51s',
        dec: '-14d44m07s',
        magnitude: 11,
        size: '1.2x1.1',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2438.htm',
      },
      {
        id: 'NGC2440',
        name: 'IV-64',
        objecttype: 'P Neb',
        ra: '07h41m55s',
        dec: '-18d12m33s',
        magnitude: 9.4,
        size: '1.2x0.7',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2440.htm',
      },
      {
        id: 'NGC2482',
        name: 'VII-10',
        objecttype: 'Open',
        ra: '07h54m54s',
        dec: '-24d18m00s',
        magnitude: 7.3,
        size: '12',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2482.htm',
      },
      {
        id: 'NGC2479',
        name: 'VII-58',
        objecttype: 'Open',
        ra: '07h55m06s',
        dec: '-17d43m00s',
        magnitude: 9.6,
        size: '7',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2479.htm',
      },
      {
        id: 'NGC2489',
        name: 'VII-23',
        objecttype: 'Open',
        ra: '07h56m12s',
        dec: '-30d04m00s',
        magnitude: 7.9,
        size: '8',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2489.htm',
      },
      {
        id: 'NGC2506',
        name: 'VI-37',
        objecttype: 'Open',
        ra: '08h00m12s',
        dec: '-10d47m00s',
        magnitude: 7.6,
        size: '6',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2506.htm',
      },
      {
        id: 'NGC2509',
        name: 'VIII-1',
        objecttype: 'Open',
        ra: '08h00m42s',
        dec: '-19d04m00s',
        magnitude: 9.3,
        size: '8',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2509.htm',
      },
      {
        id: 'NGC2527',
        name: 'NGC2527',
        objecttype: 'Open',
        ra: '08h05m18s',
        dec: '-28d10m00s',
        magnitude: 6.5,
        size: '22',
        user_Month: 2,
        user_ImageURL: 'http://bixhomaastro.com/NGC2527.htm',
      },
      {
        id: 'NGC2539',
        name: 'VII-11',
        objecttype: 'Open',
        ra: '08h10m42s',
        dec: '-12d50m00s',
        magnitude: 6.5,
        size: '21',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2539.htm',
      },
      {
        id: 'NGC2548',
        name: 'VI-22',
        objecttype: 'Open',
        ra: '08h13m48s',
        dec: '-05d48m00s',
        magnitude: 5.8,
        size: '54',
        user_Month: 3,
        user_ImageURL: '',
      },
      {
        id: 'NGC2567',
        name: 'VII-64',
        objecttype: 'Open',
        ra: '08h18m36s',
        dec: '-30d38m00s',
        magnitude: 7.4,
        size: '10',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2567.htm',
      },
      {
        id: 'NGC2571',
        name: 'VI-39',
        objecttype: 'Open',
        ra: '08h18m54s',
        dec: '-29d44m00s',
        magnitude: 7,
        size: '13',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2571.htm',
      },
      {
        id: 'NGC2613',
        name: 'II-266',
        objecttype: 'Galaxy',
        ra: '08h33m23s',
        dec: '-22d58m24s',
        magnitude: 10.4,
        size: '7.2x2.1',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2613.htm',
      },
      {
        id: 'NGC2627',
        name: 'VII-63',
        objecttype: 'Open',
        ra: '08h37m18s',
        dec: '-29d57m00s',
        magnitude: 8.4,
        size: '11',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2627.htm',
      },
      {
        id: 'NGC2683',
        name: 'I-200',
        objecttype: 'Galaxy',
        ra: '08h52m42s',
        dec: '+33d25m10s',
        magnitude: 9.7,
        size: '9.3x2.5',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2683.htm',
      },
      {
        id: 'NGC2681',
        name: 'I-242',
        objecttype: 'Galaxy',
        ra: '08h53m33s',
        dec: '+51d18m48s',
        magnitude: 11.3,
        size: '3.8x3.5',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2681.htm',
      },
      {
        id: 'NGC2655',
        name: 'I-288',
        objecttype: 'Galaxy',
        ra: '08h55m39s',
        dec: '+78d13m25s',
        magnitude: 10.1,
        size: '5.1x4.4',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2655.htm',
      },
      {
        id: 'NGC2742',
        name: 'I-249',
        objecttype: 'Galaxy',
        ra: '09h07m34s',
        dec: '+60d28m47s',
        magnitude: 11.7,
        size: '3.1x1.7',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2742.htm',
      },
      {
        id: 'NGC2775',
        name: 'I-2',
        objecttype: 'Galaxy',
        ra: '09h10m20s',
        dec: '+07d02m18s',
        magnitude: 10.3,
        size: '4.5x3.5',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2775.htm',
      },
      {
        id: 'NGC2768',
        name: 'I-250',
        objecttype: 'Galaxy',
        ra: '09h11m37s',
        dec: '+60d02m14s',
        magnitude: 10,
        size: '6.3x2.8',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2768.htm',
      },
      {
        id: 'NGC2782',
        name: 'I-167',
        objecttype: 'Galaxy',
        ra: '09h14m05s',
        dec: '+40d06m47s',
        magnitude: 11.5,
        size: '3.8x2.9',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2782.htm',
      },
      {
        id: 'NGC2811',
        name: 'II-505',
        objecttype: 'Galaxy',
        ra: '09h16m11s',
        dec: '-16d18m47s',
        magnitude: 11.3,
        size: '2.7x1',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2811.htm',
      },
      {
        id: 'NGC2787',
        name: 'I-216',
        objecttype: 'Galaxy',
        ra: '09h19m19s',
        dec: '+69d12m12s',
        magnitude: 10.8,
        size: '3.4x2.3',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2787.htm',
      },
      {
        id: 'NGC2841',
        name: 'I-205',
        objecttype: 'Galaxy',
        ra: '09h22m02s',
        dec: '+50d58m40s',
        magnitude: 9.3,
        size: '8.1x3.8',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2841.htm',
      },
      {
        id: 'NGC2859',
        name: 'I-137',
        objecttype: 'Galaxy',
        ra: '09h24m19s',
        dec: '+34d30m49s',
        magnitude: 10.7,
        size: '4.8x4.2',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2859.htm',
      },
      {
        id: 'NGC2903',
        name: 'I-56',
        objecttype: 'Galaxy',
        ra: '09h32m10s',
        dec: '+21d30m03s',
        magnitude: 8.9,
        size: '13x6.6',
        user_Month: 3,
        user_ImageURL: '',
      },
      {
        id: 'NGC2974',
        name: 'I-61',
        objecttype: 'Galaxy',
        ra: '09h42m33s',
        dec: '-03d41m59s',
        magnitude: 10.8,
        size: '3.4x2.1',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2974.htm',
      },
      {
        id: 'NGC2950',
        name: 'IV-68',
        objecttype: 'Galaxy',
        ra: '09h42m35s',
        dec: '+58d51m04s',
        magnitude: 11,
        size: '3.2x2.1',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2950.htm',
      },
      {
        id: 'NGC2964',
        name: 'I-114',
        objecttype: 'Galaxy',
        ra: '09h42m54s',
        dec: '+31d50m48s',
        magnitude: 11.3,
        size: '3x1.7',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2964.htm',
      },
      {
        id: 'NGC2976',
        name: 'I-285',
        objecttype: 'Galaxy',
        ra: '09h47m15s',
        dec: '+67d55m03s',
        magnitude: 10.2,
        size: '4.9x2.5',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2976.htm',
      },
      {
        id: 'NGC2985',
        name: 'I-78',
        objecttype: 'Galaxy',
        ra: '09h50m22s',
        dec: '+72d16m44s',
        magnitude: 10.6,
        size: '4.3x3.4',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/NGC2985.htm',
      },
      {
        id: 'NGC3034',
        name: 'IV-79, M82',
        objecttype: 'Galaxy',
        ra: '09h55m52s',
        dec: '+69d40m49s',
        magnitude: 8.4,
        size: '11.9x3.7',
        user_Month: 3,
        user_ImageURL: 'http://bixhomaastro.com/M82.htm',
      },
      {
        id: 'NGC3079',
        name: 'V-47',
        objecttype: 'Galaxy',
        ra: '10h01m59s',
        dec: '+55d40m50s',
        magnitude: 10.6,
        size: '7.6x1.7',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3079.htm',
      },
      {
        id: 'NGC3077',
        name: 'I-286',
        objecttype: 'Galaxy',
        ra: '10h03m20s',
        dec: '+68d44m02s',
        magnitude: 9.9,
        size: '4.6x3.6',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3077.htm',
      },
      {
        id: 'NGC3115',
        name: 'I-163, Spindle Galaxy',
        objecttype: 'Galaxy',
        ra: '10h05m14s',
        dec: '-07d43m07s',
        magnitude: 9.1,
        size: '8.3x3.2',
        user_Month: 4,
        user_ImageURL: '',
      },
      {
        id: 'NGC3166',
        name: 'I-3',
        objecttype: 'Galaxy',
        ra: '10h13m46s',
        dec: '+03d25m28s',
        magnitude: 10.6,
        size: '5.2x2.7',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3166.htm',
      },
      {
        id: 'NGC3169',
        name: 'I-4',
        objecttype: 'Galaxy',
        ra: '10h14m15s',
        dec: '+03d27m59s',
        magnitude: 10.5,
        size: '4.8x3.2',
        user_Month: 4,
        user_ImageURL: '',
      },
      {
        id: 'NGC3147',
        name: 'I-79',
        objecttype: 'Galaxy',
        ra: '10h16m53s',
        dec: '+73d24m02s',
        magnitude: 10.7,
        size: '4x3.5',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3147.htm',
      },
      {
        id: 'NGC3190',
        name: 'II-44',
        objecttype: 'Galaxy',
        ra: '10h18m06s',
        dec: '+21d49m52s',
        magnitude: 11,
        size: '4.6x1.8',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3190.htm',
      },
      {
        id: 'NGC3184',
        name: 'I-168',
        objecttype: 'Galaxy',
        ra: '10h18m17s',
        dec: '+41d25m26s',
        magnitude: 9.8,
        size: '7.8x7.2',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3184.htm',
      },
      {
        id: 'NGC3193',
        name: 'II-45',
        objecttype: 'Galaxy',
        ra: '10h18m25s',
        dec: '+21d53m37s',
        magnitude: 10.9,
        size: '2.8x2.6',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3193.htm',
      },
      {
        id: 'NGC3198',
        name: 'I-199',
        objecttype: 'Galaxy',
        ra: '10h19m55s',
        dec: '+45d33m00s',
        magnitude: 10.4,
        size: '8.3x3.7',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3198.htm',
      },
      {
        id: 'NGC3226',
        name: 'II-28',
        objecttype: 'Galaxy',
        ra: '10h23m27s',
        dec: '+19d53m54s',
        magnitude: 11.4,
        size: '2.8x2.5',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3226.htm',
      },
      {
        id: 'NGC3227',
        name: 'II-29',
        objecttype: 'Galaxy',
        ra: '10h23m31s',
        dec: '+19d51m54s',
        magnitude: 10.8,
        size: '5.6x4',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3227.htm',
      },
      {
        id: 'NGC3242',
        name: 'IV-27, Ghost of Jupiter',
        objecttype: 'P Neb',
        ra: '10h24m46s',
        dec: '-18d38m33s',
        magnitude: 7.8,
        size: '0.8x0.6',
        user_Month: 4,
        user_ImageURL: '',
      },
      {
        id: 'NGC3245',
        name: 'I-86',
        objecttype: 'Galaxy',
        ra: '10h27m19s',
        dec: '+28d30m25s',
        magnitude: 10.8,
        size: '3.2x1.9',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3245.htm',
      },
      {
        id: 'NGC3277',
        name: 'II-359',
        objecttype: 'Galaxy',
        ra: '10h32m56s',
        dec: '+28d30m41s',
        magnitude: 11.7,
        size: '2x1.9',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3277.htm',
      },
      {
        id: 'NGC3294',
        name: 'I-164',
        objecttype: 'Galaxy',
        ra: '10h36m16s',
        dec: '+37d19m27s',
        magnitude: 11.5,
        size: '3.6x1.8',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3294.htm',
      },
      {
        id: 'NGC3310',
        name: 'IV-60',
        objecttype: 'Galaxy',
        ra: '10h38m46s',
        dec: '+53d30m12s',
        magnitude: 11.1,
        size: '3.6x3',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3310.htm',
      },
      {
        id: 'NGC3344',
        name: 'I-81',
        objecttype: 'Galaxy',
        ra: '10h43m31s',
        dec: '+24d55m20s',
        magnitude: 10,
        size: '6.9x6.5',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3344.htm',
      },
      {
        id: 'NGC3377',
        name: 'II-99',
        objecttype: 'Galaxy',
        ra: '10h47m42s',
        dec: '+13d59m09s',
        magnitude: 10.2,
        size: '5.1x3.3',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3377.htm',
      },
      {
        id: 'NGC3379',
        name: 'I-17, M105',
        objecttype: 'Galaxy',
        ra: '10h47m50s',
        dec: '+12d34m55s',
        magnitude: 9.3,
        size: '4.5x4',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/M105.htm',
      },
      {
        id: 'NGC3384',
        name: 'I-18',
        objecttype: 'Galaxy',
        ra: '10h48m17s',
        dec: '+12d37m43s',
        magnitude: 10,
        size: '5.9x2.6',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/M105.htm',
      },
      {
        id: 'NGC3395',
        name: 'I-116',
        objecttype: 'Galaxy',
        ra: '10h49m50s',
        dec: '+32d58m58s',
        magnitude: 12.1,
        size: '1.9x1.2',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3395.htm',
      },
      {
        id: 'NGC3412',
        name: 'I-27',
        objecttype: 'Galaxy',
        ra: '10h50m53s',
        dec: '+13d24m43s',
        magnitude: 10.6,
        size: '4x2.2',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3412.htm',
      },
      {
        id: 'NGC3414',
        name: 'II-362',
        objecttype: 'Galaxy',
        ra: '10h51m16s',
        dec: '+27d58m30s',
        magnitude: 10.8,
        size: '3.6x2.7',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3414.htm',
      },
      {
        id: 'NGC3432',
        name: 'I-172',
        objecttype: 'Galaxy',
        ra: '10h52m32s',
        dec: '+36d37m16s',
        magnitude: 11.3,
        size: '6.8x1.5',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3432.htm',
      },
      {
        id: 'NGC3489',
        name: 'II-101',
        objecttype: 'Galaxy',
        ra: '11h00m19s',
        dec: '+13d54m04s',
        magnitude: 10.3,
        size: '3.7x2.1',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3489.htm',
      },
      {
        id: 'NGC3486',
        name: 'I-87',
        objecttype: 'Galaxy',
        ra: '11h00m24s',
        dec: '+28d58m32s',
        magnitude: 10.3,
        size: '6.9x5.4',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3486.htm',
      },
      {
        id: 'NGC3504',
        name: 'I-88',
        objecttype: 'Galaxy',
        ra: '11h03m11s',
        dec: '+27d58m22s',
        magnitude: 11.1,
        size: '2.7x2.2',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3504.htm',
      },
      {
        id: 'NGC3521',
        name: 'I-13',
        objecttype: 'Galaxy',
        ra: '11h05m49s',
        dec: '-00d02m06s',
        magnitude: 8.9,
        size: '9.5x5',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3521.htm',
      },
      {
        id: 'NGC3556',
        name: 'V-46, M108',
        objecttype: 'Galaxy',
        ra: '11h11m31s',
        dec: '+55d40m31s',
        magnitude: 10,
        size: '8.3x2.5',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/M108.htm',
      },
      {
        id: 'NGC3593',
        name: 'I-29',
        objecttype: 'Galaxy',
        ra: '11h14m37s',
        dec: '+12d49m03s',
        magnitude: 11,
        size: '5.8x2.5',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3593.htm',
      },
      {
        id: 'NGC3607',
        name: 'II-50',
        objecttype: 'Galaxy',
        ra: '11h16m55s',
        dec: '+18d03m06s',
        magnitude: 10,
        size: '4.8x4',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3607.htm',
      },
      {
        id: 'NGC3608',
        name: 'II-51',
        objecttype: 'Galaxy',
        ra: '11h16m59s',
        dec: '+18d08m55s',
        magnitude: 11,
        size: '3x2.5',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3608.htm',
      },
      {
        id: 'NGC3621',
        name: 'I-241',
        objecttype: 'Galaxy',
        ra: '11h18m16s',
        dec: '-32d48m42s',
        magnitude: 9.7,
        size: '10x6.5',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3621.htm',
      },
      {
        id: 'NGC3610',
        name: 'I-270',
        objecttype: 'Galaxy',
        ra: '11h18m25s',
        dec: '+58d47m11s',
        magnitude: 10.8,
        size: '3.2x2.5',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3610.htm',
      },
      {
        id: 'NGC3613',
        name: 'I-271',
        objecttype: 'Galaxy',
        ra: '11h18m36s',
        dec: '+58d00m00s',
        magnitude: 11.6,
        size: '3.6x2',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3613.htm',
      },
      {
        id: 'NGC3619',
        name: 'I-244',
        objecttype: 'Galaxy',
        ra: '11h19m22s',
        dec: '+57d45m29s',
        magnitude: 12.6,
        size: '3.1x2.6',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3619.htm',
      },
      {
        id: 'NGC3626',
        name: 'II-52,II-',
        objecttype: 'Galaxy',
        ra: '11h20m04s',
        dec: '+18d21m25s',
        magnitude: 10.9,
        size: '3.1x2.2',
        user_Month: 4,
        user_ImageURL: '',
      },
      {
        id: 'NGC3628',
        name: 'V-8',
        objecttype: 'Galaxy',
        ra: '11h20m17s',
        dec: '+13d35m14s',
        magnitude: 9.4,
        size: '15x3.6',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/M65.htm',
      },
      {
        id: 'NGC3631',
        name: 'I-226',
        objecttype: 'Galaxy',
        ra: '11h21m03s',
        dec: '+53d10m09s',
        magnitude: 10.4,
        size: '4.6x4.1',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3631.htm',
      },
      {
        id: 'NGC3640',
        name: 'II-33',
        objecttype: 'Galaxy',
        ra: '11h21m07s',
        dec: '+03d14m06s',
        magnitude: 10.3,
        size: '4.1x3.4',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3640.htm',
      },
      {
        id: 'NGC3655',
        name: 'I-5',
        objecttype: 'Galaxy',
        ra: '11h22m55s',
        dec: '+16d35m22s',
        magnitude: 11.6,
        size: '1.6x1.1',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3655.htm',
      },
      {
        id: 'NGC3665',
        name: 'I-219',
        objecttype: 'Galaxy',
        ra: '11h24m44s',
        dec: '+38d45m45s',
        magnitude: 11.6,
        size: '3.2x2.6',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3665.htm',
      },
      {
        id: 'NGC3675',
        name: 'I-194',
        objecttype: 'Galaxy',
        ra: '11h26m08s',
        dec: '+43d35m16s',
        magnitude: 10.4,
        size: '5.9x3.2',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3675.htm',
      },
      {
        id: 'NGC3686',
        name: 'III-28,II',
        objecttype: 'Galaxy',
        ra: '11h27m44s',
        dec: '+17d13m25s',
        magnitude: 11.4,
        size: '3.3x2.6',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3686.htm',
      },
      {
        id: 'NGC3726',
        name: 'II-730',
        objecttype: 'Galaxy',
        ra: '11h33m21s',
        dec: '+47d01m44s',
        magnitude: 10.4,
        size: '6x4.5',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3726.htm',
      },
      {
        id: 'NGC3729',
        name: 'I-222',
        objecttype: 'Galaxy',
        ra: '11h33m50s',
        dec: '+53d07m29s',
        magnitude: 11.4,
        size: '3.2x2.5',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3729.htm',
      },
      {
        id: 'NGC3810',
        name: 'I-21',
        objecttype: 'Galaxy',
        ra: '11h40m59s',
        dec: '+11d28m17s',
        magnitude: 10.7,
        size: '4.3x3.1',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3810.htm',
      },
      {
        id: 'NGC3813',
        name: 'I-94',
        objecttype: 'Galaxy',
        ra: '11h41m18s',
        dec: '+36d32m47s',
        magnitude: 11.7,
        size: '2.3x1.2',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3813.htm',
      },
      {
        id: 'NGC3877',
        name: 'I-201',
        objecttype: 'Galaxy',
        ra: '11h46m08s',
        dec: '+47d29m41s',
        magnitude: 11.8,
        size: '5.4x1.5',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3877.htm',
      },
      {
        id: 'NGC3893',
        name: 'II-738',
        objecttype: 'Galaxy',
        ra: '11h48m38s',
        dec: '+48d42m35s',
        magnitude: 10.6,
        size: '4.4x2.8',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3893.htm',
      },
      {
        id: 'NGC3900',
        name: 'I-82',
        objecttype: 'Galaxy',
        ra: '11h49m10s',
        dec: '+27d01m20s',
        magnitude: 11.4,
        size: '3.5x1.9',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3900.htm',
      },
      {
        id: 'NGC3898',
        name: 'I-228',
        objecttype: 'Galaxy',
        ra: '11h49m16s',
        dec: '+56d05m03s',
        magnitude: 10.8,
        size: '4.4x2.6',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3898.htm',
      },
      {
        id: 'NGC3912',
        name: 'II-342',
        objecttype: 'Galaxy',
        ra: '11h50m05s',
        dec: '+26d28m48s',
        magnitude: 12.4,
        size: '1.6x0.9',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3912.htm',
      },
      {
        id: 'NGC3938',
        name: 'I-203',
        objecttype: 'Galaxy',
        ra: '11h52m49s',
        dec: '+44d07m13s',
        magnitude: 10.4,
        size: '5.4x4.9',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3938.htm',
      },
      {
        id: 'NGC3941',
        name: 'I-173',
        objecttype: 'Galaxy',
        ra: '11h52m55s',
        dec: '+36d59m10s',
        magnitude: 11.3,
        size: '3.8x2.5',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3941.htm',
      },
      {
        id: 'NGC3945',
        name: 'I-251',
        objecttype: 'Galaxy',
        ra: '11h53m14s',
        dec: '+60d40m32s',
        magnitude: 10.6,
        size: '5.5x3.6',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3945.htm',
      },
      {
        id: 'NGC3949',
        name: 'I-202',
        objecttype: 'Galaxy',
        ra: '11h53m42s',
        dec: '+47d51m29s',
        magnitude: 11,
        size: '3x1.8',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3949.htm',
      },
      {
        id: 'NGC3953',
        name: 'V-45',
        objecttype: 'Galaxy',
        ra: '11h53m49s',
        dec: '+52d19m36s',
        magnitude: 10.1,
        size: '6.6x3.6',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3953.htm',
      },
      {
        id: 'NGC3962',
        name: 'I-67',
        objecttype: 'Galaxy',
        ra: '11h54m40s',
        dec: '-13d58m31s',
        magnitude: 10.6,
        size: '2.9x2.6',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3962.htm',
      },
      {
        id: 'NGC3982',
        name: 'IV-62',
        objecttype: 'Galaxy',
        ra: '11h56m28s',
        dec: '+55d07m29s',
        magnitude: 11.7,
        size: '2.5x2.2',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/NGC3982.htm',
      },
      {
        id: 'NGC3992',
        name: 'IV-61, M109',
        objecttype: 'Galaxy',
        ra: '11h57m36s',
        dec: '+53d22m27s',
        magnitude: 9.8,
        size: '7.6x4.9',
        user_Month: 4,
        user_ImageURL: 'http://bixhomaastro.com/M109.htm',
      },
      {
        id: 'NGC3998',
        name: 'I-229',
        objecttype: 'Galaxy',
        ra: '11h57m56s',
        dec: '+55d27m11s',
        magnitude: 10.6,
        size: '3.1x2.5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC3998.htm',
      },
      {
        id: 'NGC4026',
        name: 'I-223',
        objecttype: 'Galaxy',
        ra: '11h59m25s',
        dec: '+50d57m41s',
        magnitude: 11.5,
        size: '5.1x1.4',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4026.htm',
      },
      {
        id: 'NGC4027',
        name: 'II-296',
        objecttype: 'Galaxy',
        ra: '11h59m31s',
        dec: '-19d15m59s',
        magnitude: 11.1,
        size: '3x2.3',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4027.htm',
      },
      {
        id: 'NGC4030',
        name: 'I-121',
        objecttype: 'Galaxy',
        ra: '12h00m24s',
        dec: '-01d05m59s',
        magnitude: 12.4,
        size: '4.3x3.2',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4030.htm',
      },
      {
        id: 'NGC4036',
        name: 'I-253',
        objecttype: 'Galaxy',
        ra: '12h01m27s',
        dec: '+61d53m45s',
        magnitude: 11.2,
        size: '4.5x2',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4036.htm',
      },
      {
        id: 'NGC4038',
        name: 'IV-28.1, Ringtail/Antennae Galaxy',
        objecttype: 'Galaxy',
        ra: '12h01m53s',
        dec: '-18d51m54s',
        magnitude: 10.7,
        size: '3.4x2',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4038.htm',
      },
      {
        id: 'NGC4041',
        name: 'I-252',
        objecttype: 'Galaxy',
        ra: '12h02m12s',
        dec: '+62d08m15s',
        magnitude: 11.2,
        size: '2.8x2.7',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4041.htm',
      },
      {
        id: 'NGC4051',
        name: 'IV-56',
        objecttype: 'Galaxy',
        ra: '12h03m10s',
        dec: '+44d31m53s',
        magnitude: 10.3,
        size: '5x4',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4051.htm',
      },
      {
        id: 'NGC4085',
        name: 'I-224',
        objecttype: 'Galaxy',
        ra: '12h05m23s',
        dec: '+50d21m12s',
        magnitude: 12.3,
        size: '2.8x0.9',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4085.htm',
      },
      {
        id: 'NGC4088',
        name: 'I-206',
        objecttype: 'Galaxy',
        ra: '12h05m34s',
        dec: '+50d32m23s',
        magnitude: 10.5,
        size: '5.8x2.5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4088.htm',
      },
      {
        id: 'NGC4102',
        name: 'I-225',
        objecttype: 'Galaxy',
        ra: '12h06m23s',
        dec: '+52d42m39s',
        magnitude: 11.8,
        size: '3.2x1.9',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4102.htm',
      },
      {
        id: 'NGC4111',
        name: 'I-195',
        objecttype: 'Galaxy',
        ra: '12h07m03s',
        dec: '+43d03m56s',
        magnitude: 10.6,
        size: '4.8x1.1',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4111.htm',
      },
      {
        id: 'NGC4143',
        name: 'IV-54',
        objecttype: 'Galaxy',
        ra: '12h09m36s',
        dec: '+42d32m02s',
        magnitude: 12.1,
        size: '2.9x1.8',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4143.htm',
      },
      {
        id: 'NGC4147',
        name: 'I-19',
        objecttype: 'Globular',
        ra: '12h10m06s',
        dec: '+18d32m31s',
        magnitude: 9.9,
        size: '4',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4147.htm',
      },
      {
        id: 'NGC4151',
        name: 'I-165, The Seyfert Galaxy',
        objecttype: 'Galaxy',
        ra: '12h10m33s',
        dec: '+39d24m20s',
        magnitude: 11.8,
        size: '7.6x5.9',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4151.htm',
      },
      {
        id: 'NGC4150',
        name: 'I-73',
        objecttype: 'Galaxy',
        ra: '12h10m34s',
        dec: '+30d24m04s',
        magnitude: 11.7,
        size: '2.5x1.8',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4150.htm',
      },
      {
        id: 'NGC4179',
        name: 'I-9',
        objecttype: 'Galaxy',
        ra: '12h12m52s',
        dec: '+01d18m01s',
        magnitude: 10.9,
        size: '4.2x1.2',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4179.htm',
      },
      {
        id: 'NGC4203',
        name: 'I-175',
        objecttype: 'Galaxy',
        ra: '12h15m05s',
        dec: '+33d11m50s',
        magnitude: 10.7,
        size: '3.6x3.3',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4203.htm',
      },
      {
        id: 'NGC4214',
        name: 'I-95',
        objecttype: 'Galaxy',
        ra: '12h15m39s',
        dec: '+36d19m41s',
        magnitude: 9.7,
        size: '9x6.3',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4214.htm',
      },
      {
        id: 'NGC4216',
        name: 'I-35',
        objecttype: 'Galaxy',
        ra: '12h15m54s',
        dec: '+13d09m00s',
        magnitude: 9.9,
        size: '8.3x2.2',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4216.htm',
      },
      {
        id: 'NGC4245',
        name: 'I-74',
        objecttype: 'Galaxy',
        ra: '12h17m37s',
        dec: '+29d36m29s',
        magnitude: 11.4,
        size: '3.3x2.6',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4245.htm',
      },
      {
        id: 'NGC4251',
        name: 'I-89',
        objecttype: 'Galaxy',
        ra: '12h18m09s',
        dec: '+28d10m30s',
        magnitude: 11.5,
        size: '4.2x1.9',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4251.htm',
      },
      {
        id: 'NGC4258',
        name: 'V-43, M106',
        objecttype: 'Galaxy',
        ra: '12h18m58s',
        dec: '+47d18m14s',
        magnitude: 8.3,
        size: '18x7.9',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M106.htm',
      },
      {
        id: 'NGC4261',
        name: 'II-139',
        objecttype: 'Galaxy',
        ra: '12h19m23s',
        dec: '+05d49m32s',
        magnitude: 10.4,
        size: '3.9x3.2',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4261.htm',
      },
      {
        id: 'NGC4274',
        name: 'I-75',
        objecttype: 'Galaxy',
        ra: '12h19m50s',
        dec: '+29d36m51s',
        magnitude: 10.3,
        size: '6.9x2.8',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4274.htm',
      },
      {
        id: 'NGC4273',
        name: 'II-569',
        objecttype: 'Galaxy',
        ra: '12h19m56s',
        dec: '+05d20m38s',
        magnitude: 11.9,
        size: '2.3x1.5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4273.htm',
      },
      {
        id: 'NGC4278',
        name: 'II-322,I-',
        objecttype: 'Galaxy',
        ra: '12h20m07s',
        dec: '+29d16m50s',
        magnitude: 10.9,
        size: '3.6x3.5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4278.htm',
      },
      {
        id: 'NGC4281',
        name: 'II-573,II',
        objecttype: 'Galaxy',
        ra: '12h20m22s',
        dec: '+05d23m12s',
        magnitude: 11.3,
        size: '3.1x1.5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4281.htm',
      },
      {
        id: 'NGC4293',
        name: 'V-5',
        objecttype: 'Galaxy',
        ra: '12h21m12s',
        dec: '+18d22m56s',
        magnitude: 11.6,
        size: '6x3',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4293.htm',
      },
      {
        id: 'NGC4303',
        name: 'I-139,M61',
        objecttype: 'Galaxy',
        ra: '12h21m55s',
        dec: '+04d28m29s',
        magnitude: 9.7,
        size: '6x5.5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M61.htm',
      },
      {
        id: 'NGC4314',
        name: 'I-76',
        objecttype: 'Galaxy',
        ra: '12h22m32s',
        dec: '+29d53m42s',
        magnitude: 11.5,
        size: '3.9x1.7',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4314.htm',
      },
      {
        id: 'NGC4346',
        name: 'I-210',
        objecttype: 'Galaxy',
        ra: '12h23m28s',
        dec: '+46d59m37s',
        magnitude: 12.3,
        size: '3.5x1.4',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4346.htm',
      },
      {
        id: 'NGC4350',
        name: 'II-86',
        objecttype: 'Galaxy',
        ra: '12h23m58s',
        dec: '+16d41m33s',
        magnitude: 11,
        size: '3.2x1.1',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4350.htm',
      },
      {
        id: 'NGC4365',
        name: 'I-30',
        objecttype: 'Galaxy',
        ra: '12h24m28s',
        dec: '+07d19m04s',
        magnitude: 11.5,
        size: '6.2x4.6',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4365.htm',
      },
      {
        id: 'NGC4361',
        name: 'I-65',
        objecttype: 'P Neb',
        ra: '12h24m31s',
        dec: '-18d47m05s',
        magnitude: 10.9,
        size: '1.9',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4361.htm',
      },
      {
        id: 'NGC4371',
        name: 'I-22',
        objecttype: 'Galaxy',
        ra: '12h24m56s',
        dec: '+11d42m14s',
        magnitude: 10.9,
        size: '3.9x2.5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4371.htm',
      },
      {
        id: 'NGC4394',
        name: 'II-55',
        objecttype: 'Galaxy',
        ra: '12h25m56s',
        dec: '+18d12m50s',
        magnitude: 10.9,
        size: '3.9x3.5',
        user_Month: 5,
        user_ImageURL: '',
      },
      {
        id: 'NGC4414',
        name: 'I-77',
        objecttype: 'Galaxy',
        ra: '12h26m27s',
        dec: '+31d13m21s',
        magnitude: 10.3,
        size: '3.6x2.2',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4414.htm',
      },
      {
        id: 'NGC4419',
        name: 'II-113',
        objecttype: 'Galaxy',
        ra: '12h26m57s',
        dec: '+15d02m50s',
        magnitude: 11,
        size: '3.4x1.3',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4419.htm',
      },
      {
        id: 'NGC4429',
        name: 'II-65',
        objecttype: 'Galaxy',
        ra: '12h27m27s',
        dec: '+11d06m27s',
        magnitude: 10.2,
        size: '5.5x2.6',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4429.htm',
      },
      {
        id: 'NGC4435',
        name: "I-28.1, Markarian's Chain, The Eyes",
        objecttype: 'Galaxy',
        ra: '12h27m41s',
        dec: '+13d04m44s',
        magnitude: 10.8,
        size: '3x1.9',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4435.htm',
      },
      {
        id: 'NGC4438',
        name: "I-28.2, Markarian's Chain, The Eyes",
        objecttype: 'Galaxy',
        ra: '12h27m46s',
        dec: '+13d00m32s',
        magnitude: 10,
        size: '9.3x3.9',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4438.htm',
      },
      {
        id: 'NGC4442',
        name: 'II-156',
        objecttype: 'Galaxy',
        ra: '12h28m04s',
        dec: '+09d48m13s',
        magnitude: 10.4,
        size: '4.6x1.9',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4442.htm',
      },
      {
        id: 'NGC4449',
        name: 'I-213',
        objecttype: 'Galaxy',
        ra: '12h28m11s',
        dec: '+44d05m33s',
        magnitude: 9.4,
        size: '5.1x3.7',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4449.htm',
      },
      {
        id: 'NGC4448',
        name: 'I-91',
        objecttype: 'Galaxy',
        ra: '12h28m16s',
        dec: '+28d37m14s',
        magnitude: 11.1,
        size: '4x1.6',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4448.htm',
      },
      {
        id: 'NGC4450',
        name: 'II-90,II-',
        objecttype: 'Galaxy',
        ra: '12h28m30s',
        dec: '+17d05m05s',
        magnitude: 10.1,
        size: '4.8x3.5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4450.htm',
      },
      {
        id: 'NGC4459',
        name: 'I-161',
        objecttype: 'Galaxy',
        ra: '12h29m00s',
        dec: '+13d58m42s',
        magnitude: 10.4,
        size: '3.8x2.8',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4459.htm',
      },
      {
        id: 'NGC4473',
        name: "II-114, Part of Makarian's Chain",
        objecttype: 'Galaxy',
        ra: '12h29m49s',
        dec: '+13d25m46s',
        magnitude: 10.2,
        size: '4.5x2.6',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M86.htm',
      },
      {
        id: 'NGC4477',
        name: "II-115, Part of Markarian's Chain",
        objecttype: 'Galaxy',
        ra: '12h30m02s',
        dec: '+13d38m11s',
        magnitude: 10.4,
        size: '4x3.5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4477.htm',
      },
      {
        id: 'NGC4478',
        name: 'II-124',
        objecttype: 'Galaxy',
        ra: '12h30m17s',
        dec: '+12d19m40s',
        magnitude: 11.2,
        size: '2x1.8',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4478.htm',
      },
      {
        id: 'NGC4485',
        name: 'I-197',
        objecttype: 'Galaxy',
        ra: '12h30m32s',
        dec: '+41d41m58s',
        magnitude: 12,
        size: '2.7x1.8',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4485.htm',
      },
      {
        id: 'NGC4490',
        name: 'I-198, Cocoon Galaxy',
        objecttype: 'Galaxy',
        ra: '12h30m37s',
        dec: '+41d38m27s',
        magnitude: 9.8,
        size: '6.2x3.1',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4490.htm',
      },
      {
        id: 'NGC4494',
        name: 'I-83',
        objecttype: 'Galaxy',
        ra: '12h31m24s',
        dec: '+25d46m29s',
        magnitude: 9.9,
        size: '4.8x3.8',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4494.htm',
      },
      {
        id: 'NGC4526',
        name: 'I-31,I-38',
        objecttype: 'Galaxy',
        ra: '12h34m03s',
        dec: '+07d41m58s',
        magnitude: 9.6,
        size: '7.2x2.3',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4526.htm',
      },
      {
        id: 'NGC4527',
        name: 'II-37',
        objecttype: 'Galaxy',
        ra: '12h34m09s',
        dec: '+02d39m11s',
        magnitude: 10.4,
        size: '6.3x2.3',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4527.htm',
      },
      {
        id: 'NGC4535',
        name: 'II-500',
        objecttype: 'Galaxy',
        ra: '12h34m20s',
        dec: '+08d11m51s',
        magnitude: 10,
        size: '6.8x5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4535.htm',
      },
      {
        id: 'NGC4536',
        name: 'V-2',
        objecttype: 'Galaxy',
        ra: '12h34m27s',
        dec: '+02d11m15s',
        magnitude: 10.4,
        size: '7.4x3.5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4536.htm',
      },
      {
        id: 'NGC4548',
        name: 'II-120, M91',
        objecttype: 'Galaxy',
        ra: '12h35m27s',
        dec: '+14d29m45s',
        magnitude: 10.2,
        size: '5.4x4.4',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M91.htm',
      },
      {
        id: 'NGC4546',
        name: 'I-160',
        objecttype: 'Galaxy',
        ra: '12h35m30s',
        dec: '-03d47m37s',
        magnitude: 10.3,
        size: '3.5x1.7',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4546.htm',
      },
      {
        id: 'NGC4550',
        name: 'I-36',
        objecttype: 'Galaxy',
        ra: '12h35m31s',
        dec: '+12d13m13s',
        magnitude: 11.5,
        size: '3.5x1.1',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4550.htm',
      },
      {
        id: 'NGC4559',
        name: 'I-92',
        objecttype: 'Galaxy',
        ra: '12h35m58s',
        dec: '+27d57m35s',
        magnitude: 9.9,
        size: '10x4.9',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4559.htm',
      },
      {
        id: 'NGC4565',
        name: 'V-24',
        objecttype: 'Galaxy',
        ra: '12h36m21s',
        dec: '+25d59m14s',
        magnitude: 9.5,
        size: '15.9x2',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4565.htm',
      },
      {
        id: 'NGC4570',
        name: 'I-32',
        objecttype: 'Galaxy',
        ra: '12h36m54s',
        dec: '+07d14m46s',
        magnitude: 10.8,
        size: '4.1x1.3',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4570.htm',
      },
      {
        id: 'NGC4596',
        name: 'I-24',
        objecttype: 'Galaxy',
        ra: '12h39m56s',
        dec: '+10d10m32s',
        magnitude: 10.5,
        size: '3.9x2.8',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4596.htm',
      },
      {
        id: 'NGC4594',
        name: 'I-43, M104',
        objecttype: 'Galaxy',
        ra: '12h39m59s',
        dec: '-11d37m23s',
        magnitude: 8.3,
        size: '8.9x4.1',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M104.htm',
      },
      {
        id: 'NGC4618',
        name: 'I-178',
        objecttype: 'Galaxy',
        ra: '12h41m32s',
        dec: '+41d09m00s',
        magnitude: 11.5,
        size: '4.7x0.7',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4618.htm',
      },
      {
        id: 'NGC4631',
        name: 'V-42',
        objecttype: 'Galaxy',
        ra: '12h42m08s',
        dec: '+32d32m27s',
        magnitude: 9.2,
        size: '15x3.3',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4631.htm',
      },
      {
        id: 'NGC4636',
        name: 'II-38',
        objecttype: 'Galaxy',
        ra: '12h42m50s',
        dec: '+02d41m16s',
        magnitude: 9.6,
        size: '6.2x5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4636.htm',
      },
      {
        id: 'NGC4643',
        name: 'I-10',
        objecttype: 'Galaxy',
        ra: '12h43m20s',
        dec: '+01d58m39s',
        magnitude: 10.6,
        size: '3.4x2.7',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4643.htm',
      },
      {
        id: 'NGC4654',
        name: 'II-126',
        objecttype: 'Galaxy',
        ra: '12h43m57s',
        dec: '+13d07m35s',
        magnitude: 10.5,
        size: '4.7x3',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4654.htm',
      },
      {
        id: 'NGC4656',
        name: 'I-176',
        objecttype: 'Galaxy',
        ra: '12h43m58s',
        dec: '+32d10m09s',
        magnitude: 10.3,
        size: '11x1.9',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4656.htm',
      },
      {
        id: 'NGC4660',
        name: 'II-71',
        objecttype: 'Galaxy',
        ra: '12h44m32s',
        dec: '+11d11m25s',
        magnitude: 10.9,
        size: '2.8x1.9',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4660.htm',
      },
      {
        id: 'NGC4665',
        name: 'I-142',
        objecttype: 'Galaxy',
        ra: '12h45m06s',
        dec: '+03d03m20s',
        magnitude: 12.4,
        size: '4.2x3.5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4665.htm',
      },
      {
        id: 'NGC4666',
        name: 'I-15',
        objecttype: 'Galaxy',
        ra: '12h45m08s',
        dec: '-00d27m49s',
        magnitude: 10.8,
        size: '4.5x1.5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4666.htm',
      },
      {
        id: 'NGC4689',
        name: 'II-128',
        objecttype: 'Galaxy',
        ra: '12h47m46s',
        dec: '+13d45m46s',
        magnitude: 10.9,
        size: '4x3.5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4689.htm',
      },
      {
        id: 'NGC4698',
        name: 'III-6,I-8',
        objecttype: 'Galaxy',
        ra: '12h48m23s',
        dec: '+08d29m15s',
        magnitude: 10.5,
        size: '4.3x2.5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4698.htm',
      },
      {
        id: 'NGC4697',
        name: 'I-39',
        objecttype: 'Galaxy',
        ra: '12h48m36s',
        dec: '-05d48m02s',
        magnitude: 9.3,
        size: '6x3.8',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4697.htm',
      },
      {
        id: 'NGC4699',
        name: 'I-129',
        objecttype: 'Galaxy',
        ra: '12h49m02s',
        dec: '-08d39m52s',
        magnitude: 9.6,
        size: '3.5x2.7',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4699.htm',
      },
      {
        id: 'NGC4725',
        name: 'I-84',
        objecttype: 'Galaxy',
        ra: '12h50m27s',
        dec: '+25d30m02s',
        magnitude: 9.2,
        size: '11x7.9',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4725.htm',
      },
      {
        id: 'NGC4754',
        name: 'II-74,I-2',
        objecttype: 'Galaxy',
        ra: '12h52m18s',
        dec: '+11d18m50s',
        magnitude: 10.5,
        size: '4.8x2.6',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4754.htm',
      },
      {
        id: 'NGC4753',
        name: 'I-16',
        objecttype: 'Galaxy',
        ra: '12h52m22s',
        dec: '-01d11m58s',
        magnitude: 9.9,
        size: '5.4x2.9',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4753.htm',
      },
      {
        id: 'NGC4762',
        name: 'II-75',
        objecttype: 'Galaxy',
        ra: '12h52m56s',
        dec: '+11d13m49s',
        magnitude: 10.2,
        size: '8.7x1.6',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4762.htm',
      },
      {
        id: 'NGC4781',
        name: 'I-134',
        objecttype: 'Galaxy',
        ra: '12h54m24s',
        dec: '-10d32m12s',
        magnitude: 12.8,
        size: '3.5x1.8',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4781.htm',
      },
      {
        id: 'NGC4800',
        name: 'I-211',
        objecttype: 'Galaxy',
        ra: '12h54m38s',
        dec: '+46d31m52s',
        magnitude: 12.4,
        size: '1.8x1.4',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4800.htm',
      },
      {
        id: 'NGC4845',
        name: 'II-536',
        objecttype: 'Galaxy',
        ra: '12h58m01s',
        dec: '+01d34m34s',
        magnitude: 12.9,
        size: '5x1.6',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4845.htm',
      },
      {
        id: 'NGC4856',
        name: 'I-68',
        objecttype: 'Galaxy',
        ra: '12h59m21s',
        dec: '-15d02m34s',
        magnitude: 10.4,
        size: '4.6x1.6',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4856.htm',
      },
      {
        id: 'NGC4866',
        name: 'I-162',
        objecttype: 'Galaxy',
        ra: '12h59m27s',
        dec: '+14d10m14s',
        magnitude: 11.2,
        size: '6.5x1.5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4866.htm',
      },
      {
        id: 'NGC4900',
        name: 'I-143',
        objecttype: 'Galaxy',
        ra: '13h00m39s',
        dec: '+02d30m06s',
        magnitude: 11.5,
        size: '2.3x2.2',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4900.htm',
      },
      {
        id: 'NGC4958',
        name: 'I-130',
        objecttype: 'Galaxy',
        ra: '13h05m49s',
        dec: '-08d01m14s',
        magnitude: 10.5,
        size: '4.1x1.4',
        user_Month: 5,
        user_ImageURL: '',
      },
      {
        id: 'NGC4995',
        name: 'I-42',
        objecttype: 'Galaxy',
        ra: '13h09m41s',
        dec: '-07d50m01s',
        magnitude: 11,
        size: '2.5x1.7',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC4995.htm',
      },
      {
        id: 'NGC5005',
        name: 'I-96',
        objecttype: 'Galaxy',
        ra: '13h10m56s',
        dec: '+37d03m30s',
        magnitude: 9.8,
        size: '5.4x2.7',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC5005.htm',
      },
      {
        id: 'NGC5033',
        name: 'I-97',
        objecttype: 'Galaxy',
        ra: '13h13m28s',
        dec: '+36d35m37s',
        magnitude: 10.1,
        size: '10x5.6',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC5033.htm',
      },
      {
        id: 'NGC5054',
        name: 'II-513',
        objecttype: 'Galaxy',
        ra: '13h16m58s',
        dec: '-16d38m07s',
        magnitude: 11.7,
        size: '5x3.1',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC5054.htm',
      },
      {
        id: 'NGC5195',
        name: 'I-186, companion to M51',
        objecttype: 'Galaxy',
        ra: '13h29m59s',
        dec: '+47d16m05s',
        magnitude: 9.6,
        size: '5.4x4.3',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/M51.htm',
      },
      {
        id: 'NGC5248',
        name: 'I-34',
        objecttype: 'Galaxy',
        ra: '13h37m32s',
        dec: '+08d53m06s',
        magnitude: 10.2,
        size: '6.5x4.9',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC5248.htm',
      },
      {
        id: 'NGC5273',
        name: 'I-98',
        objecttype: 'Galaxy',
        ra: '13h42m08s',
        dec: '+35d39m16s',
        magnitude: 11.6,
        size: '3.2x2.9',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC5273.htm',
      },
      {
        id: 'NGC5322',
        name: 'I-256',
        objecttype: 'Galaxy',
        ra: '13h49m15s',
        dec: '+60d11m26s',
        magnitude: 10,
        size: '5.5x3.9',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC5322.htm',
      },
      {
        id: 'NGC5363',
        name: 'I-6',
        objecttype: 'Galaxy',
        ra: '13h56m07s',
        dec: '+05d15m17s',
        magnitude: 10.2,
        size: '4.2x2.7',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC5363.htm',
      },
      {
        id: 'NGC5364',
        name: 'II-534',
        objecttype: 'Galaxy',
        ra: '13h56m12s',
        dec: '+05d00m53s',
        magnitude: 10.4,
        size: '7.1x5',
        user_Month: 5,
        user_ImageURL: 'http://bixhomaastro.com/NGC5364.htm',
      },
      {
        id: 'NGC5473',
        name: 'I-231',
        objecttype: 'Galaxy',
        ra: '14h04m43s',
        dec: '+54d53m33s',
        magnitude: 11.4,
        size: '2.6x1.8',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/NGC5473.htm',
      },
      {
        id: 'NGC5474',
        name: 'I-214',
        objecttype: 'Galaxy',
        ra: '14h05m01s',
        dec: '+53d39m45s',
        magnitude: 10.9,
        size: '4.5x4.2',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/NGC5474.htm',
      },
      {
        id: 'NGC5466',
        name: 'VI-9',
        objecttype: 'Globular',
        ra: '14h05m27s',
        dec: '+28d32m04s',
        magnitude: 9,
        size: '11',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/NGC5466.htm',
      },
      {
        id: 'NGC5557',
        name: 'I-99',
        objecttype: 'Galaxy',
        ra: '14h18m26s',
        dec: '+36d29m36s',
        magnitude: 11.1,
        size: '2.4x2.2',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/NGC5557.htm',
      },
      {
        id: 'NGC5566',
        name: 'I-144',
        objecttype: 'Galaxy',
        ra: '14h20m20s',
        dec: '+03d56m00s',
        magnitude: 10.5,
        size: '6.5x2.4',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/NGC5566.htm',
      },
      {
        id: 'NGC5576',
        name: 'I-146',
        objecttype: 'Galaxy',
        ra: '14h21m04s',
        dec: '+03d16m14s',
        magnitude: 10.9,
        size: '3.2x2.2',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/NGC5576.htm',
      },
      {
        id: 'NGC5631',
        name: 'I-236',
        objecttype: 'Galaxy',
        ra: '14h26m33s',
        dec: '+56d34m58s',
        magnitude: 12.4,
        size: '2.2x2.1',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/NGC5631.htm',
      },
      {
        id: 'NGC5634',
        name: 'I-70',
        objecttype: 'Globular',
        ra: '14h29m37s',
        dec: '-05d58m35s',
        magnitude: 9.4,
        size: '4.9',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/NGC5634.htm',
      },
      {
        id: 'NGC5676',
        name: 'I-189',
        objecttype: 'Galaxy',
        ra: '14h32m47s',
        dec: '+49d27m29s',
        magnitude: 10.9,
        size: '3.9x2',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/NGC5676.htm',
      },
      {
        id: 'NGC5689',
        name: 'I-188',
        objecttype: 'Galaxy',
        ra: '14h35m30s',
        dec: '+48d44m30s',
        magnitude: 11.9,
        size: '3.7x1.2',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/NGC5689.htm',
      },
      {
        id: 'NGC5694',
        name: 'II-196',
        objecttype: 'Globular',
        ra: '14h39m37s',
        dec: '-26d32m18s',
        magnitude: 9.2,
        size: '3.6',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/NGC5694.htm',
      },
      {
        id: 'NGC5746',
        name: 'I-126',
        objecttype: 'Galaxy',
        ra: '14h44m56s',
        dec: '+01d57m19s',
        magnitude: 10.5,
        size: '7.9x1.7',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/NGC5746.htm',
      },
      {
        id: 'NGC5846',
        name: 'I-128',
        objecttype: 'Galaxy',
        ra: '15h06m29s',
        dec: '+01d36m19s',
        magnitude: 10.2,
        size: '3.4x3.2',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/NGC5846.htm',
      },
      {
        id: 'NGC5866',
        name: 'I-215, M102',
        objecttype: 'Galaxy',
        ra: '15h06m30s',
        dec: '+55d45m47s',
        magnitude: 11.1,
        size: '6.5x3',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/M102.htm',
      },
      {
        id: 'NGC5907',
        name: 'II-759',
        objecttype: 'Galaxy',
        ra: '15h15m54s',
        dec: '+56d19m43s',
        magnitude: 11.4,
        size: '12.1x1.3',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/NGC5907.htm',
      },
      {
        id: 'NGC5897',
        name: 'VI-19,VI-',
        objecttype: 'Globular',
        ra: '15h17m25s',
        dec: '-21d00m37s',
        magnitude: 8.6,
        size: '12.6',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/NGC5897.htm',
      },
      {
        id: 'NGC5982',
        name: 'II-764',
        objecttype: 'Galaxy',
        ra: '15h38m40s',
        dec: '+59d21m21s',
        magnitude: 11.1,
        size: '2.9x2.2',
        user_Month: 6,
        user_ImageURL: 'http://bixhomaastro.com/NGC5982.htm',
      },
      {
        id: 'NGC6118',
        name: 'II-402',
        objecttype: 'Galaxy',
        ra: '16h21m49s',
        dec: '-02d17m03s',
        magnitude: 13.2,
        size: '4.7x2.3',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6118.htm',
      },
      {
        id: 'NGC6144',
        name: 'VI-10',
        objecttype: 'Globular',
        ra: '16h27m14s',
        dec: '-26d01m29s',
        magnitude: 9,
        size: '9.3',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6144.htm',
      },
      {
        id: 'NGC6171',
        name: 'VI-40, M107',
        objecttype: 'Globular',
        ra: '16h32m32s',
        dec: '-13d03m13s',
        magnitude: 8.2,
        size: '10',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/M107.htm',
      },
      {
        id: 'NGC6217',
        name: 'I-280',
        objecttype: 'Galaxy',
        ra: '16h32m39s',
        dec: '+78d11m54s',
        magnitude: 11.2,
        size: '3.1x2.7',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6217.htm',
      },
      {
        id: 'NGC6207',
        name: 'II-701',
        objecttype: 'Galaxy',
        ra: '16h43m04s',
        dec: '+36d49m58s',
        magnitude: 11.6,
        size: '3x1.4',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/M13.htm',
      },
      {
        id: 'NGC6229',
        name: 'IV-50',
        objecttype: 'Globular',
        ra: '16h46m59s',
        dec: '+47d31m40s',
        magnitude: 9.4,
        size: '4.5',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6229.htm',
      },
      {
        id: 'NGC6235',
        name: 'II-584',
        objecttype: 'Globular',
        ra: '16h53m25s',
        dec: '-22d10m38s',
        magnitude: 10,
        size: '5',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6235.htm',
      },
      {
        id: 'NGC6284',
        name: 'VI-11',
        objecttype: 'Globular',
        ra: '17h04m29s',
        dec: '-24d45m53s',
        magnitude: 8.9,
        size: '5.6',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6284.htm',
      },
      {
        id: 'NGC6287',
        name: 'II-195',
        objecttype: 'Globular',
        ra: '17h05m09s',
        dec: '-22d42m29s',
        magnitude: 9.3,
        size: '5.1',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6287.htm',
      },
      {
        id: 'NGC6293',
        name: 'VI-12',
        objecttype: 'Globular',
        ra: '17h10m10s',
        dec: '-26d34m54s',
        magnitude: 8.2,
        size: '7.9',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6293.htm',
      },
      {
        id: 'NGC6304',
        name: 'I-147',
        objecttype: 'Globular',
        ra: '17h14m33s',
        dec: '-29d27m44s',
        magnitude: 8.4,
        size: '6.8',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6304.htm',
      },
      {
        id: 'NGC6316',
        name: 'I-45',
        objecttype: 'Globular',
        ra: '17h16m37s',
        dec: '-28d08m24s',
        magnitude: 8.8,
        size: '4.9',
        user_Month: 7,
        user_ImageURL: '',
      },
      {
        id: 'NGC6342',
        name: 'I-149',
        objecttype: 'Globular',
        ra: '17h21m10s',
        dec: '-19d35m14s',
        magnitude: 9.8,
        size: '3',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6342.htm',
      },
      {
        id: 'NGC6356',
        name: 'I-48',
        objecttype: 'Globular',
        ra: '17h23m35s',
        dec: '-17d48m47s',
        magnitude: 8.4,
        size: '7.2',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6356.htm',
      },
      {
        id: 'NGC6355',
        name: 'I-46',
        objecttype: 'Globular',
        ra: '17h23m59s',
        dec: '-26d21m13s',
        magnitude: 9.7,
        size: '5',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6355.htm',
      },
      {
        id: 'NGC6369',
        name: 'IV-11, Little Ghost Nebula',
        objecttype: 'P Neb',
        ra: '17h29m20s',
        dec: '-23d45m35s',
        magnitude: 11.4,
        size: '1x0.6',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6369.htm',
      },
      {
        id: 'NGC6401',
        name: 'I-44',
        objecttype: 'Globular',
        ra: '17h38m37s',
        dec: '-23d54m32s',
        magnitude: 9.5,
        size: '5.6',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6401.htm',
      },
      {
        id: 'NGC6426',
        name: 'II-587',
        objecttype: 'Globular',
        ra: '17h44m55s',
        dec: '+03d10m13s',
        magnitude: 11.1,
        size: '3.2',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6426.htm',
      },
      {
        id: 'NGC6440',
        name: 'I-150',
        objecttype: 'Globular',
        ra: '17h48m53s',
        dec: '-20d21m34s',
        magnitude: 9.1,
        size: '5.4',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6440.htm',
      },
      {
        id: 'NGC6445',
        name: 'II-586, Little Gem/Crescent Nebula',
        objecttype: 'P Neb',
        ra: '17h49m15s',
        dec: '-20d00m34s',
        magnitude: 11.2,
        size: '3.1x0.9',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6445.htm',
      },
      {
        id: 'NGC6451',
        name: 'VI-13',
        objecttype: 'Open',
        ra: '17h50m42s',
        dec: '-30d13m00s',
        magnitude: 8.2,
        size: '7',
        user_Month: 7,
        user_ImageURL: 'http://bixhomaastro.com/NGC6451.htm',
      },
      {
        id: 'NGC6543',
        name: "IV-37, Cat's Eye Nebula",
        objecttype: 'P Neb',
        ra: '17h58m33s',
        dec: '+66d37m59s',
        magnitude: 8.3,
        size: '0.4x0.3',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6543.htm',
      },
      {
        id: 'NGC6517',
        name: 'II-199',
        objecttype: 'Globular',
        ra: '18h01m51s',
        dec: '-08d57m32s',
        magnitude: 10.3,
        size: '4.3',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6517.htm',
      },
      {
        id: 'NGC6514',
        name: 'IV-41,V-1, M20, Trifid Nebula',
        objecttype: 'Open',
        ra: '18h02m18s',
        dec: '-23d02m00s',
        magnitude: 6.3,
        size: '13',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/M20.htm',
      },
      {
        id: 'NGC6522',
        name: 'I-49',
        objecttype: 'Globular',
        ra: '18h03m34s',
        dec: '-30d02m02s',
        magnitude: 8.4,
        size: '5.6',
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'NGC6520',
        name: 'VII-7',
        objecttype: 'Open',
        ra: '18h03m40s',
        dec: '-27d54m00s',
        magnitude: 7.6,
        size: '6',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6520.htm',
      },
      {
        id: 'NGC6528',
        name: 'II-200',
        objecttype: 'Globular',
        ra: '18h04m50s',
        dec: '-30d03m21s',
        magnitude: 9.5,
        size: '3.7',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6528.htm',
      },
      {
        id: 'NGC6540',
        name: 'II-198',
        objecttype: 'Globular',
        ra: '18h06m09s',
        dec: '-27d45m55s',
        magnitude: 9.3,
        size: '1.5',
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'NGC6544',
        name: 'II-197',
        objecttype: 'Globular',
        ra: '18h07m21s',
        dec: '-24d59m51s',
        magnitude: 8.1,
        size: '8.9',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6544.htm',
      },
      {
        id: 'NGC6553',
        name: 'IV-12',
        objecttype: 'Globular',
        ra: '18h09m16s',
        dec: '-25d54m28s',
        magnitude: 8.1,
        size: '8.1',
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'NGC6568',
        name: 'VII-30',
        objecttype: 'Open',
        ra: '18h12m48s',
        dec: '-21d36m00s',
        magnitude: 8.6,
        size: '12',
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'NGC6569',
        name: 'II-201',
        objecttype: 'Globular',
        ra: '18h13m39s',
        dec: '-31d49m35s',
        magnitude: 8.7,
        size: '5.8',
        user_Month: 8,
        user_ImageURL: '',
      },
      {
        id: 'NGC6583',
        name: 'VII-31',
        objecttype: 'Open',
        ra: '18h15m48s',
        dec: '-22d08m00s',
        magnitude: 10,
        size: '5',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6583.htm',
      },
      {
        id: 'NGC6624',
        name: 'I-50',
        objecttype: 'Globular',
        ra: '18h23m40s',
        dec: '-30d21m40s',
        magnitude: 8,
        size: '5.9',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6624.htm',
      },
      {
        id: 'NGC6629',
        name: 'II-204',
        objecttype: 'P Neb',
        ra: '18h25m42s',
        dec: '-23d12m10s',
        magnitude: 11.3,
        size: '0.3x0.2',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6629.htm',
      },
      {
        id: 'NGC6633',
        name: 'VIII-72',
        objecttype: 'Open',
        ra: '18h27m42s',
        dec: '+06d34m00s',
        magnitude: 4.6,
        size: '20',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6633.htm',
      },
      {
        id: 'NGC6638',
        name: 'I-51',
        objecttype: 'Globular',
        ra: '18h30m56s',
        dec: '-25d29m47s',
        magnitude: 9.1,
        size: '5',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6638.htm',
      },
      {
        id: 'NGC6642',
        name: 'II-205',
        objecttype: 'Globular',
        ra: '18h31m54s',
        dec: '-23d28m35s',
        magnitude: 9.4,
        size: '4.5',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6642.htm',
      },
      {
        id: 'NGC6645',
        name: 'VI-23',
        objecttype: 'Open',
        ra: '18h32m36s',
        dec: '-16d54m00s',
        magnitude: 8.5,
        size: '10',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6645.htm',
      },
      {
        id: 'NGC6664',
        name: 'VIII-12',
        objecttype: 'Open',
        ra: '18h36m42s',
        dec: '-08d13m00s',
        magnitude: 7.8,
        size: '16',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6664.htm',
      },
      {
        id: 'NGC6712',
        name: 'I-47',
        objecttype: 'Globular',
        ra: '18h53m04s',
        dec: '-08d42m22s',
        magnitude: 8.8,
        size: '7.2',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6712.htm',
      },
      {
        id: 'NGC6755',
        name: 'VII-19',
        objecttype: 'Open',
        ra: '19h07m48s',
        dec: '+04d14m00s',
        magnitude: 7.5,
        size: '14',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6755.htm',
      },
      {
        id: 'NGC6756',
        name: 'VII-62',
        objecttype: 'Open',
        ra: '19h08m42s',
        dec: '+04d41m00s',
        magnitude: 10.6,
        size: '4',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6756.htm',
      },
      {
        id: 'NGC6781',
        name: 'III-743',
        objecttype: 'P Neb',
        ra: '19h18m28s',
        dec: '+06d32m23s',
        magnitude: 11.4,
        size: '1.9x1.8',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6781.htm',
      },
      {
        id: 'NGC6802',
        name: 'VI-14',
        objecttype: 'Open',
        ra: '19h30m36s',
        dec: '+20d16m00s',
        magnitude: 8.8,
        size: '3.2',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6802.htm',
      },
      {
        id: 'NGC6823',
        name: 'VII-18',
        objecttype: 'Open',
        ra: '19h43m06s',
        dec: '+23d18m00s',
        magnitude: 7.1,
        size: '12',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6823.htm',
      },
      {
        id: 'NGC6818',
        name: 'IV-51, Little Gem Nebula',
        objecttype: 'P Neb',
        ra: '19h43m58s',
        dec: '-14d09m11s',
        magnitude: 9.3,
        size: '0.4x0.3',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6818.htm',
      },
      {
        id: 'NGC6826',
        name: 'IV-73, Blinking Planetary',
        objecttype: 'P Neb',
        ra: '19h44m48s',
        dec: '+50d31m30s',
        magnitude: 8.8,
        size: '0.5x0.4',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6826.htm',
      },
      {
        id: 'NGC6830',
        name: 'VII-9',
        objecttype: 'Open',
        ra: '19h51m00s',
        dec: '+23d04m00s',
        magnitude: 7.9,
        size: '12',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6830.htm',
      },
      {
        id: 'NGC6834',
        name: 'VIII-16',
        objecttype: 'Open',
        ra: '19h52m12s',
        dec: '+29d25m00s',
        magnitude: 7.8,
        size: '5',
        user_Month: 8,
        user_ImageURL: 'http://bixhomaastro.com/NGC6834.htm',
      },
      {
        id: 'NGC6866',
        name: 'VII-59',
        objecttype: 'Open',
        ra: '20h03m42s',
        dec: '+44d00m00s',
        magnitude: 7.6,
        size: '6',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6866.htm',
      },
      {
        id: 'NGC6882',
        name: 'VIII-22',
        objecttype: 'Open',
        ra: '20h11m42s',
        dec: '+26d33m00s',
        magnitude: 8.1,
        size: '10',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6882.htm',
      },
      {
        id: 'NGC6885',
        name: 'VIII-20',
        objecttype: 'Open',
        ra: '20h12m00s',
        dec: '+26d29m00s',
        magnitude: 8.1,
        size: '7',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6885.htm',
      },
      {
        id: 'NGC6905',
        name: 'IV-16,VII, Blue Flash Nebula',
        objecttype: 'P Neb',
        ra: '20h22m23s',
        dec: '+20d06m16s',
        magnitude: 11.1,
        size: '0.7x0.6',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6905.htm',
      },
      {
        id: 'NGC6910',
        name: 'VIII-56',
        objecttype: 'Open',
        ra: '20h23m06s',
        dec: '+40d47m00s',
        magnitude: 7.4,
        size: '7',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6910.htm',
      },
      {
        id: 'NGC6939',
        name: 'VI-42',
        objecttype: 'Open',
        ra: '20h31m24s',
        dec: '+60d38m00s',
        magnitude: 7.8,
        size: '7',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6939.htm',
      },
      {
        id: 'NGC6934',
        name: 'I-103',
        objecttype: 'Globular',
        ra: '20h34m12s',
        dec: '+07d24m15s',
        magnitude: 8.7,
        size: '5.9',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6934.htm',
      },
      {
        id: 'NGC6940',
        name: 'VII-8',
        objecttype: 'Open',
        ra: '20h34m36s',
        dec: '+28d18m00s',
        magnitude: 6.3,
        size: '31',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6940.htm',
      },
      {
        id: 'NGC6946',
        name: 'IV-76',
        objecttype: 'Galaxy',
        ra: '20h34m53s',
        dec: '+60d09m14s',
        magnitude: 8.8,
        size: '11x9.8',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC6946.htm',
      },
      {
        id: 'NGC7008',
        name: 'I-192',
        objecttype: 'P Neb',
        ra: '21h00m33s',
        dec: '+54d32m36s',
        magnitude: 10.7,
        size: '1.6x1.3',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC7008.htm',
      },
      {
        id: 'NGC7006',
        name: 'I-52',
        objecttype: 'Globular',
        ra: '21h01m29s',
        dec: '+16d11m15s',
        magnitude: 10.4,
        size: '2.8',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC7006.htm',
      },
      {
        id: 'NGC7000',
        name: 'V-37, North American Nebula',
        objecttype: 'Neb',
        ra: '21h01m48s',
        dec: '+44d12m00s',
        magnitude: 4.5,
        size: '120x100',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC7000.htm',
      },
      {
        id: 'NGC7009',
        name: 'IV-1, Saturn Nebula',
        objecttype: 'P Neb',
        ra: '21h04m11s',
        dec: '-11d21m49s',
        magnitude: 7.8,
        size: '0.7x0.4',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC7009.htm',
      },
      {
        id: 'NGC7044',
        name: 'VI-24',
        objecttype: 'Open',
        ra: '21h12m54s',
        dec: '+42d29m00s',
        magnitude: 12,
        size: '3.5',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC7044.htm',
      },
      {
        id: 'NGC7062',
        name: 'VII-51',
        objecttype: 'Open',
        ra: '21h23m12s',
        dec: '+46d23m00s',
        magnitude: 8.3,
        size: '6',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC7062.htm',
      },
      {
        id: 'NGC7086',
        name: 'VI-32',
        objecttype: 'Open',
        ra: '21h30m30s',
        dec: '+51d35m00s',
        magnitude: 8.4,
        size: '9',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC7086.htm',
      },
      {
        id: 'NGC7128',
        name: 'VII-40',
        objecttype: 'Open',
        ra: '21h44m00s',
        dec: '+53d43m00s',
        magnitude: 9.7,
        size: '3.1',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC7128.htm',
      },
      {
        id: 'NGC7142',
        name: 'VII-66',
        objecttype: 'Open',
        ra: '21h45m54s',
        dec: '+65d48m00s',
        magnitude: 9.3,
        size: '4.3',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC7142.htm',
      },
      {
        id: 'NGC7160',
        name: 'VIII-67',
        objecttype: 'Open',
        ra: '21h53m42s',
        dec: '+62d36m00s',
        magnitude: 6.1,
        size: '7',
        user_Month: 9,
        user_ImageURL: 'http://bixhomaastro.com/NGC7160.htm',
      },
      {
        id: 'NGC7209',
        name: 'VII-53',
        objecttype: 'Open',
        ra: '22h05m12s',
        dec: '+46d30m00s',
        magnitude: 7.7,
        size: '25',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7209.htm',
      },
      {
        id: 'NGC7217',
        name: 'II-207',
        objecttype: 'Galaxy',
        ra: '22h07m53s',
        dec: '+31d21m33s',
        magnitude: 10.2,
        size: '3.7x3.2',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7217.htm',
      },
      {
        id: 'NGC7243',
        name: 'VIII-75',
        objecttype: 'Open',
        ra: '22h15m18s',
        dec: '+49d53m00s',
        magnitude: 6.4,
        size: '21',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7243.htm',
      },
      {
        id: 'NGC7296',
        name: 'VII-41',
        objecttype: 'Open',
        ra: '22h28m12s',
        dec: '+52d17m00s',
        magnitude: 9.7,
        size: '4',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7296.htm',
      },
      {
        id: 'NGC7331',
        name: 'I-53',
        objecttype: 'Galaxy',
        ra: '22h37m04s',
        dec: '+34d24m58s',
        magnitude: 9.5,
        size: '11x4',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7331.htm',
      },
      {
        id: 'NGC7380',
        name: 'VIII-77, Wizard Nebula',
        objecttype: 'Open',
        ra: '22h47m00s',
        dec: '+58d06m00s',
        magnitude: 7.2,
        size: '12',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7380.htm',
      },
      {
        id: 'NGC7448',
        name: 'II-251',
        objecttype: 'Galaxy',
        ra: '23h00m04s',
        dec: '+15d58m48s',
        magnitude: 11.7,
        size: '2.7x1.3',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7448.htm',
      },
      {
        id: 'NGC7479',
        name: 'I-55',
        objecttype: 'Galaxy',
        ra: '23h04m57s',
        dec: '+12d19m23s',
        magnitude: 11.7,
        size: '4.5x3.4',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7479.htm',
      },
      {
        id: 'NGC7510',
        name: 'VII-44',
        objecttype: 'Open',
        ra: '23h11m30s',
        dec: '+60d34m00s',
        magnitude: 7.9,
        size: '4',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7510.htm',
      },
      {
        id: 'NGC7606',
        name: 'I-104',
        objecttype: 'Galaxy',
        ra: '23h19m05s',
        dec: '-08d29m13s',
        magnitude: 10.8,
        size: '5.8x2.6',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7606.htm',
      },
      {
        id: 'NGC7662',
        name: 'IV-18, Blue Snowball Nebula',
        objecttype: 'P Neb',
        ra: '23h25m54s',
        dec: '+42d32m06s',
        magnitude: 8.3,
        size: '0.5x0.5',
        user_Month: 10,
        user_ImageURL: '',
      },
      {
        id: 'NGC7686',
        name: 'VIII-69',
        objecttype: 'Open',
        ra: '23h30m12s',
        dec: '+49d08m00s',
        magnitude: 5.6,
        size: '14',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7686.htm',
      },
      {
        id: 'NGC7723',
        name: 'I-110',
        objecttype: 'Galaxy',
        ra: '23h38m57s',
        dec: '-12d57m42s',
        magnitude: 11.1,
        size: '3.6x2.6',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7723.htm',
      },
      {
        id: 'NGC7727',
        name: 'I-111',
        objecttype: 'Galaxy',
        ra: '23h39m54s',
        dec: '-12d17m36s',
        magnitude: 10.7,
        size: '4.2x3.4',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7727.htm',
      },
      {
        id: 'NGC7789',
        name: 'VI-30',
        objecttype: 'Open',
        ra: '23h57m00s',
        dec: '+56d44m00s',
        magnitude: 6.7,
        size: '15',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7789.htm',
      },
      {
        id: 'NGC7790',
        name: 'VII-56',
        objecttype: 'Open',
        ra: '23h58m24s',
        dec: '+61d13m00s',
        magnitude: 8.5,
        size: '17',
        user_Month: 10,
        user_ImageURL: 'http://bixhomaastro.com/NGC7790.htm',
      },
    ],
  },
];
