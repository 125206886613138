import { Select } from '@chakra-ui/react';
import { monthOptions } from '../data/targets';

export const ObservingMonthSelect = props => {
  const { handleMonth } = props;
  return (
    <Select
      placeholder="Change Month"
      w="200px"
      variant="outline"
      onChange={handleMonth}
    >
      {monthOptions.map((m, index) => (
        <option key={index} value={m.value} id={m.month}>
          {m.month}
        </option>
      ))}
    </Select>
  );
};
