import React from 'react';
import { Page } from '../../../UI/Page';
import { Hero } from '../../../UI/Hero';
import { Box } from '@chakra-ui/react';
import { ObservingNav } from './ObservingNav';
import { ObservingText } from './ObservingText';
import { ObservingTables } from './ObservingTables';

export const ObservingPage = props => {
  const { month, setMonth, handleMonth } = props;
  return (
    <Page
      id="observing-targets"
      background={`linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)),
    url("../M33-rod-gallagher.jpg")`}
      backgroundAttachment="fixed"
      backgroundSize="cover"
      backgroundPosition="center right fixed"
      backgroundRepeat="no-repeat"
      pb={18}
    >
      <Hero heading={`${month.month} 2023 Observing Targets`}>
        <Box mx="auto" w={{ base: '90%', md: '75%' }} textAlign="left">
          <ObservingNav setMonth={setMonth} handleMonth={handleMonth} />
          <ObservingText />
        </Box>
      </Hero>
      <ObservingTables month={month} />
    </Page>
  );
};
