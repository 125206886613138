import { HStack, Link } from '@chakra-ui/react';
import { ObservingMonthSelect } from './ObservingMonthSelect';

export const ObservingNav = props => {
  const { setMonth, handleMonth } = props;
  const options = [
    'Messier',
    'Binocular',
    'DoubleStar',
    'Caldwell',
    'Herschel',
  ];

  return (
    <HStack
      spacing={6}
      my={4}
      textTransform="uppercase"
      fontSize="small"
      color="gray.500"
      fontWeight="bold"
      justify="flex-end"
      textAlign="center"
    >
      {options.map((option, index) => (
        <Link href={`#${option.toLowerCase()}`} key={index}>
          {option}
        </Link>
      ))}
      <ObservingMonthSelect setMonth={setMonth} handleMonth={handleMonth} />
    </HStack>
  );
};
