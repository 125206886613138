import {
  Heading,
  Link,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { LinkIcon } from '@chakra-ui/icons';

export const TargetTable = props => {
  const { id, targetList, title, filter, description } = props;

  return (
    <TableContainer
      color="white"
      p={4}
      id={id}
      overflow="initial"
      whiteSpace="normal"
    >
      <Heading as="h2" py={2}>
        {title}
      </Heading>
      {description}
      <Table>
        <TableCaption />
        <Thead fontSize="90%">
          <Tr>
            <Th>id</Th>
            {title === 'Double Star Observing Program' && <Th>Name</Th>}
            <Th>Object Type</Th>
            <Th>Right Ascension</Th>
            <Th>Declination</Th>
            <Th>Magnitude</Th>
            {title === 'Messier Observing Program' && <Th>Binocular</Th>}

            {title !== 'Double Star Observing Program' && <Th>Image</Th>}
            {title === 'Double Star Observing Program' && (
              <>
                <Th>Magnitude 2</Th>
                <Th>Separation</Th>
              </>
            )}
          </Tr>
        </Thead>
        <Tbody fontSize="80%">
          {targetList
            .filter(target => target.user_Month === filter)
            .map((target, index) => (
              <Tr key={index}>
                <Td>{target.id}</Td>
                {title === 'Double Star Observing Program' && (
                  <Td>{target.name}</Td>
                )}
                <Td>{target.objecttype}</Td>
                <Td>{target.ra}</Td>
                <Td>{target.dec}</Td>
                <Td>{target.magnitude}</Td>
                {title === 'Messier Observing Program' && (
                  <Td>{target.user_Binocular}</Td>
                )}
                {title !== 'Double Star Observing Program' && (
                  <Td>
                    {target.user_ImageURL && (
                      <Link href={target.user_ImageURL} target="_blank">
                        <LinkIcon />
                      </Link>
                    )}
                  </Td>
                )}
                {title === 'Double Star Observing Program' && (
                  <>
                    <Td>{target.magnitude2}</Td>
                    <Td>{target.separation}</Td>
                  </>
                )}
              </Tr>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
