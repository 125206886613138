import { Link, Text, VStack } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

export const ObservingText = () => {
  return (
    <VStack spacing={4}>
      <Text>
        How many times have you shown up at an observing site, set up your
        equipment and said: “Now, what am I going to look at tonight?” Then,
        after a quick review of the current sky, you just look at the same old
        things — Jupiter, Saturn, M13, M51, M57, M31, ... and... well... now
        what? Not that you will ever tire of some of the most spectacular
        objects but there are many beautiful objects that are often overlooked.
        This page provides an organized list of objects to observe. These lists
        and targets are objects from six different Astronomical League Observing
        Programs.
      </Text>

      <Text>
        While the total number of objects from all of these lists contains too
        many objects to observe in one evening, there are a variety of objects
        to give the observer a selection of objects to observe based on
        experience level and type of equipment. Observations of these objects
        can also be used for Astronomical League Observing Programs. By
        recording the observations and following the rules of the particular
        Observing Program, you can earn an Observing Certificate and Observing
        Pin. For more information on the Astronomical League and the observing
        programs, check them out on the web at:{' '}
        <Link
          href="https://www.astroleague.org/observing.html"
          isExternal
          fontWeight="bold"
        >
          https://www.astroleague.org
          <ExternalLinkIcon mx="2px" />
        </Link>
      </Text>

      <Text>
        Please review each program's rules before starting any of them. By using
        this information each month and observing each of the objects from a
        particular program you will be able to complete any of these observing
        programs in only one year. Of course, the Herschel 400 Observing Program
        will probably take longer.
      </Text>
    </VStack>
  );
};
