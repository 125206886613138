import { Box, Link } from '@chakra-ui/react';
import { TargetTable } from './TargetTable';
import { tables } from '../data/targets';

export const ObservingTables = props => {
  const { month } = props;

  return tables.map((table, index) => (
    <Box
      my={3}
      w={{ base: '90%', md: '73%' }}
      mx="auto"
      textAlign="left"
      key={index}
    >
      <TargetTable
        filter={month.value}
        id={table.id}
        targetList={table.targets}
        title={table.title}
        description={table.description}
      />
      <Box
        textAlign="right"
        textTransform="uppercase"
        color="gray.600"
        fontSize="90%"
        fontWeight="bold"
        pr={3}
      >
        <Link href="#observing-targets">Back to Top</Link>
      </Box>
    </Box>
  ));
};
